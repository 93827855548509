.artists {
    &__hero {
        background-image: var(--hero-img-artists);
        overflow-x: hidden;
    }

    &__svg-text {
        // right: -15px;
        bottom: 5px;
    }

    &__img--1 {
        background-image: var(--img-artists-1);
    }

    &__img--2 {
        background-image: var(--img-artists-2);
    }

    &__list {
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: var(--bone);

        &-wrapper {
            position: relative;
            z-index: 1;

            margin-top: 26px;
            margin-left: 15px;
            margin-right: 10px;
        }

        &-card {
            display: flex;
            flex-wrap: wrap;
            padding-top: 20px;
        }

        &-card-item {
            width: calc(50% - 5px);
            margin-right: 5px;
            margin-bottom: 5px;

            .main-artists__card {
                padding: 15px;
                width: 100%;
                height: 235px;
            }

            .main-artists__card-text {
                margin-bottom: 5px;
            }

            .card__wrapper {
                background-position: center;
            }

            h3 {
                font-size: 18px !important;
                text-align: center;
            }

            h4 {
                font-size: 12px;
            }
        }
    }

    &__list-filter-wrap {}

    &__list-filters {
        display: flex;
        flex-wrap: wrap;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        label {
            display: block;
            padding: 10px 15px;
            margin-right: 7px;
            margin-bottom: 7px;

            font-size: 14px;
            color: var(--brown-light);
            background-color: var(--white);

            border-radius: 25px;
            text-transform: capitalize;
            text-decoration: none;

            appearance: none;
            cursor: pointer;

            &:hover {
                background-color: var(--brown-light);
                color: var(--white);
            }
        }

        // input[type=radio]+label {
        //     background-color: var(--white);
        //     color: var(--brown-light);
        // }

        input[type=radio]:checked+label {
            background-color: var(--brown-light);
            color: var(--white);
        }
    }
}

@include rmin($desktop) {
    .artists {
        &__svg-text {
            bottom: 10px !important;
            left: 40px !important;

            width: 300px !important;
            height: 115px !important;
        }

        &__info {
            margin-top: 50px;
            padding-right: 25px;
            padding-bottom: 50px;
            padding-left: 25px;
        }

        &__list {
            padding-bottom: 40px;
            margin-bottom: 100px;

            &-wrapper {
                margin-left: 40px;
                margin-right: unset;
            }

            &-card-item {
                width: unset;
                margin-right: 10px;
                margin-bottom: 10px;

                .main-artists__card {
                    padding: 25px;
                    width: 235px;
                    height: 340px;
                }

                .main-artists__card-text {
                    margin-bottom: 15px;
                }

                h3 {
                    font-size: 24px !important;
                }

                h4 {
                    font-size: 18px;
                }

                .main-artists__btn-text {
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }

        &__content {
            display: flex;

            .main-artists__card-list-item {
                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &__slider-wrapper {
            left: unset;
            right: unset;

            order: 1;

            margin-top: -50px;
            margin-left: 50px;
            margin-right: -25px;
            width: 550px !important;
            height: 100%;
        }

        &__text-wrapper {
            &--first {
                width: calc(100% - 575px);
            }
        }

        &__img--1 {
            height: 325px;
        }

        &__img--2 {
            height: 400px;
        }

        &__img-wrapper {
            min-width: 400px;
            height: 100%;
            margin-top: 25px;

            &--1 {
                margin-right: 35px;
            }

            &--2 {
                margin-left: 35px;
            }
        }

        &__list {
            &-card {
                // flex-wrap: nowrap;
            }
        }

        &__list-filters {
            a {
                padding: 11.5px 25px;
                font-size: 16px;
            }
        }
    }
}


@include rmin($desktop-sm) {
    .artists {
        &__svg-text {
            bottom: 40px;
        }

        &__slider-wrapper {
            width: 650px !important;
        }

        &__text-wrapper {
            &--first {
                width: calc(100% - 615px);
            }
        }

        &__img-wrapper {
            min-width: 500px;
        }
    }
}


@include rmin($desktop-md) {
    .artists {
        &__slider-wrapper {
            margin-top: -131px;
            width: 800px !important;
        }
    }
}
