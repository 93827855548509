.main-library {
    &__wrapper {
        position: relative;
        margin-top: 50px;
        padding-bottom: 450px;
        height: 100%;
        min-height: 750px;

        overflow: hidden;
    }

    &__info {
        h2 {
            &::before {
                left: 10px;
                top: -150px;

                width: 60px;
                height: 205px;
            }
        }
    }

    &__img-wrapper {
        position: absolute;
        // bottom: 50px;
        bottom: 0;
        z-index: -1;
        width: 100%;
        height: 50%;

        // position: relative;

        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     left: 0;
        //     height: 350px;
        //     background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        // }
    }

    &__img {
        background-image: url(../img/main-library.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 400px;
    }

    &__cards-wrapper {
        margin-left: auto;
        margin-top: 35px;
        // position: absolute;
        // left: 22px;
        // bottom: 100px;
        width: calc(100% - 22px);
    }

    &__new-card-wrapper {
        height: 100%;
    }

    &__new-card-list-item {
        margin-right: 10px !important;
        margin-bottom: 10px !important;
    }

    &__new-card {
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        // padding: 15px;
        padding: 10px;

        min-width: 150px;
        width: 150px;

        background-color: var(--bone) !important;
        background: unset;
        border-radius: 5px;

        h3 {
            font-size: 16px;
            color: var(--brown-light);
        }

        &:hover {
            background: unset;
        }

        &::after {
            content: unset;
        }
    }

    &__new-card-ico {
        position: absolute;
        top: 50%;
        right: -10px;

        width: 50px;
        height: 50px;

        transform: translateY(-50%);
        fill: var(--brown-lighter);
        opacity: .5;
    }

    &__card-list-item {
        scroll-snap-align: start;

        &:not(:last-child) {
            margin-right: 10px;
        }

        &:first-child,
        &:last-child {
            display: flex;
        }

        &:last-child {
            &::after {
                content: '';
                display: block;
                width: 20px;
            }
        }
    }

    &__card-list {
        display: flex;
        flex-wrap: nowrap;

        overflow-x: auto;
        scroll-snap-type: x mandatory;
    }

    &__card {
        align-items: flex-end;
        flex-direction: unset;
        justify-content: space-between;

        padding: 20px;
        width: 215px;
        height: 285px;

        text-decoration: none;
    }

    &__card-info {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__card-title {
        margin-top: auto;
        font-size: 22px;
    }
}

@include rmin($tablet) {
    .main-library {
        &__wrapper {
            margin-top: 150px;
        }

        &__cards-wrapper {
            bottom: 25px;
        }
    }
}

@include rmin($desktop) {
    .main-library {
        &__wrapper {
            margin-top: 50px;
            padding-bottom: unset;
        }

        &__info-wrapper {
            position: relative;

            display: flex;
            flex-direction: column;
            padding-left: 25px;

            width: 100%;
            height: 100%;
        }

        &__info {
            position: absolute;
            margin-top: 5%;
            padding-left: 25px;
            max-width: 425px;

            h2 {
                &::before {
                    top: -85px;
                    left: -10px;
                    width: 95px;
                    height: 155px;
                }
            }
        }

        &__img-wrapper {
            position: absolute;
            order: -1;
            right: 25px;
            bottom: unset;
            padding: 10px;
            margin-top: 10px;
            height: unset;

            width: 425px;

            &::before {
                content: none;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;
                border: 1px solid var(--brown-dark);
            }
        }

        &__img {
            height: 425px;
            background-position: bottom;
        }

        &__cards-wrapper {
            position: absolute;
            left: 50px;
            // bottom: 50px;

            // max-width: 750px;
            // margin-bottom: -25px;
            margin-bottom: 0;

            // &::before {
            //     content: '';
            //     position: absolute;
            //     z-index: -1;
            //     bottom: -50px;
            //     right: -75px;

            //     width: 625px;
            //     height: 125px;
            //     background-color: var(--bone);
            // }
        }

        &__card {
            width: 145px;
            height: 200px;
        }

        &__card:hover {
            background: linear-gradient(180deg, rgba(0, 0, 0, .13) 0%, rgba(0, 0, 0, .7) 100%);
        }

        &__card-list {
            flex-wrap: wrap;
            justify-content: flex-start;
            overflow: unset;
        }

        &__card-list-item {
            margin-bottom: 10px;

            &:not(:last-child) {
                margin-right: 10px;
            }

            &:first-child {
                // margin-left: 215px;
            }

            &:nth-child(2) {
                margin-right: unset;
            }

            &:first-child,
            &:last-child {
                display: unset;
            }

            &:last-child {
                &::after {
                    content: none;
                }
            }
        }

        &__card-title {
            font-size: 18px;
        }

        &__svg-text {
            z-index: -1;
            top: 50px;
            right: 250px;

            width: 350px !important;
        }
    }
}

@include rmin($desktop-xs) {
    .main-library {
        &__info {
            max-width: 600px;
        }
    }
}

@include rmin($desktop-ss) {
    .main-library {
        &__cards-wrapper {
            bottom: 15%;
        }
    }
}

@include rmin($desktop-s) {
    .main-library {
        max-height: $max-height;
        height: calc(var(--vh, 1vh) * 100);

        margin-top: unset;

        &__img {
            height: 475px;
            background-position: center;
        }

        &__info {
            margin-top: 12%;
        }

        &__cards-wrapper {
            bottom: 10%;
        }
    }
}

@include rmin($desktop-sm) {
    .main-library {

        &__info {
            margin-top: 210px;
            max-width: 750px;
        }

        &__img-wrapper {
            right: 75px;
            margin-top: 100px;
            width: 475px;
        }

        &__img {
            height: 700px;
        }

        &__cards-wrapper {
            bottom: 150px;
            max-width: 850px;

            &::before {
                width: 875px;
                height: 160px;
            }
        }

        &__card {}

        &__card-list-item {
            margin-bottom: 25px;

            & {
                margin-right: 25px;
            }

            &:nth-child(2) {
                margin-right: 10px;
            }
        }

        &__card-title {
            font-size: 22px;
        }

        &__svg-text {
            top: 175px;
            right: -480px;
            width: 500px !important;
        }
    }
}

@include rmin($desktop-md) {
    .main-library {

        &__img {
            height: 850px;
        }

        &__img-wrapper {
            width: 650px;
        }

        &__info {
            h2 {
                &::before {
                    height: 170px;
                }
            }
        }
    }
}
