.privacy {
    &__section {
        margin-left: 1rem;
    }

    &-item {
        font-size: 16px;
        line-height: 22px;
        color: var(--brown);
    }

    &-list__inner {
        padding-left: 15px;
    }
}

@include rmin($desktop) {
    .privacy {
        &-item {
            font-size: 22px;
            line-height: 32px;
        }

        &-list__inner {
            padding-left: 25px;
        }
    }
}