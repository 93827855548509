.nav {
    position: relative;
    z-index: 1;

    &__list {
        margin: auto auto auto 60px;
        padding-top: 50px;

        width: 100%;
    }

    &__list-item {
        position: relative;

        color: var(--bone);
        font-family: $font-serif;
        font-weight: 700;
        font-size: 22px;
        text-align: left;

        overflow: hidden;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        // &:last-child {
        //     position: absolute;
        //     right: 25px;
        //     bottom: 25px;

        //     font-family: $font-text;
        //     font-weight: 400;
        //     font-size: 14px;
        // }

        &:hover,
        &:focus {
            color: var(--brown-light);
        }
    }

    &__list-item--active {
        color: var(--brown-light);
    }

    &__list-link {
        display: block;
        // padding-right: 40px;

        color: currentColor;
        text-decoration: none;
        transition: color 0.5s;

        transform: translate3d(0, 100%, 0);
        opacity: 0;
        visibility: hidden;

        &:last-child {
            display: flex;
            align-items: center;
            padding-right: unset;
        }
    }

    &__list-item:hover &__list-link::before {
        transition: transform 0.3s;
        transform: scaleX(1);
        transform-origin: right center;
    }

    &__list-item--active &__list-link::before {
        transform: scaleX(1);
    }

    &__svg-mail {
        margin-left: 5px;
        width: 25px;
        height: 25px;
        fill: currentColor;
    }
}

@include rmin($tablet) {
    .nav {
        &__list {
            margin-left: 75px;
        }

        &__list-item {
            font-size: 35px;

            &:not(:last-child) {
                margin-bottom: 35px;
            }
        }
    }
}

@include rmin($desktop) {
    .nav {
        &__list {
            display: block;
            margin: unset;
            margin-top: 25px;
            padding-top: unset;
        }

        &__list-item {
            margin-bottom: 15px;

            font-size: 20px;
            text-align: right;
            color: var(--brown-dark);

            // &:last-child {
            //     display: none;
            // }

            &:not(:last-child) {
                margin-bottom: 7px;
            }
        }

        &__list-item--active {
            color: var(--brown);
        }

        &__list-link {
            padding-top: 7px;
            padding-bottom: 7px;

            &:last-child {
                display: block;
                padding-right: 30px;
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 18px;
                z-index: -1;

                width: 150px;
                // height: 35px;

                transform: scaleX(0);
                border-radius: 5px;
                background-color: var(--bone);
            }
        }

        &__list-item--privacy {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: var(--gray);
            margin-top: 10vh;
        }
    }
}

@include rmin($desktop-s) {
    .nav {
        &__list {
            margin-top: 60px;
        }
    }
}

@include rmin($desktop-md) {
    .nav {
        &__list-item {
            font-size: 24px;
        }

        &__list-link {
            &:last-child {
                padding-right: 40px;
            }

            &::before {
                right: 25px;
            }
        }

        &__list-item--privacy {
            font-size: 16px;
        }
    }
}
