.main-collections {
    &__wrapper {
        position: relative;
        min-height: 635px;
        margin-top: 75px;
        padding-bottom: 600px;
    }

    &__img-wrapper {
        position: absolute;
        bottom: 250px;
        z-index: -1;
        height: 40%;
        width: 100%;
    }

    &__img {
        background-image: url(../img/main-collections.jpg);
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: contain;
        height: 100%;

        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     left: 0;
        //     height: 450px;
        //     background: linear-gradient(180deg, #FFFFFF 35%, rgba(255, 255, 255, 0) 100%);
        // }
    }

    &__info-text {
        padding-bottom: 100px;
    }

    &__info-img {
        position: absolute;
        bottom: 125px;
        width: calc(100% - 50px);
        // position: relative;
        // margin-top: 35px;
    }

    &__img-list {
        position: absolute;
        top: 70px;
        left: -25px;

        width: calc(100% + 50px);
        overflow: hidden;
    }

    &__img-item {
        display: flex;
        justify-content: center;

        &:first-child {
            // position: relative;
            // right: -25px;

            margin-bottom: 5px;
        }

        &:last-child {
            // position: relative;
            // left: -25px;
        }

        :nth-child(3n+3) {
            display: none;
        }
    }

    &__card-wrapper {
        margin-right: 5px;
        background-size: cover;
    }

    &__card {
        width: 150px;
        height: 100px;
    }

    &__link {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        margin-left: auto;

        width: 125px;
        text-decoration: none;

        &::after {
            content: '';
            position: absolute;
            top: 13px;
            right: -5px;
            z-index: -1;
            width: 145px;
            height: 40px;
            background-color: var(--bone);
        }

        &--second {
            bottom: -240px;
            margin-left: unset;
            margin-right: auto;
        }
    }

    &__link-title {
        display: block;
        font-size: 24px;
    }

    &__link-btn {
        display: flex;
        align-items: center;
        margin-top: -3px;

        font-size: 14px;
        color: var(--blue-title);
    }

    &__arrow-ico {
        margin-left: 3px;

        width: 25px;
        height: 13px;

        stroke: var(--blue-title);
        stroke-width: 2;
        fill: none;

    }
}

@include rmin($tablet) {
    .main-collections {

        &__wrapper {
            min-height: 735px;
        }

        &__img-list {
            position: unset;
            // min-height: 220px;
            min-height: 200px;
            margin-top: 15px;
            width: 100%;
        }

        &__img-item {
            justify-content: unset;

            &:first-child {
                position: unset;
                justify-content: center;
            }

            :nth-child(3n+3) {
                display: unset;
            }

            &:last-child {
                left: unset;
                justify-content: center;
            }
        }

        &__img {
            background-size: cover;
        }

        &__img-wrapper {
            height: 100%;
        }

        &__card {
            width: 200px;
            height: 130px;
        }

        &__link {
            &--second {
                bottom: unset;
            }
        }
    }
}


@include rmin($desktop) {
    .main-collections {
        // min-height: $max-height;
        height: calc(var(--vh, 1vh) * 100);

        &__wrapper {
            height: 100%;
            margin-top: unset;
            padding-bottom: unset;
            overflow: hidden;
        }

        &__img-wrapper {
            bottom: 0;

            width: 55%;
            height: 100%;
        }

        &__info-img {
            position: relative;
            bottom: -15px;
            width: 100%;
        }

        &__info-wrapper {
            z-index: 1;

            position: relative;
            top: 35px;

            width: 45%;
            height: 100%;
            margin-left: auto;

            h2 {
                &::before {
                    top: -15px;
                    left: -15px;
                }
            }
        }

        &__info-text {
            position: relative;
            z-index: -1;

            // margin-left: -100px;
            padding: 35px 0 35px 35px;

            background: rgba(255, 255, 255, 0.95);

            h2 {
                font-size: 42px;
            }
        }

        &__img-item {
            &:first-child {
                // margin-right: -25px;
            }

            &:last-child {
                // left: -35px;
            }
        }

        &__info-img {
            // margin-top: -20px;
        }

        &__img {
            &::before {
                content: none;
            }

            // &::before {
            //     right: unset;
            //     bottom: 0;

            //     width: 75%;
            //     height: unset;

            //     background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.52) 100%);
            // }
        }

        &__link {
            position: relative;
            margin-right: 45px;

            &--second {
                margin-left: 50px;
            }
        }

        &__link {
            &::after {
                width: 145px;
                height: 40px;
            }
        }

        &__img-list {
            min-height: 200px;
        }

        &__card {
            width: 105px;
            height: 85px;
        }

        &__svg-text {
            z-index: -1;
            bottom: 0;
            right: 0;

            width: 280px !important;
        }

        .text-ellipsis {
            -webkit-line-clamp: 5;
        }
    }
}

@include rmin($desktop-xs) {
    .main-collections {
        &__card {
            width: 130px;
        }
    }
}

@include rmin($desktop-s) {
    .main-collections {

        &__info-wrapper {
            top: 10px;
        }

        &__info-text {
            padding: 35px 0 35px 35px;

            h2 {
                font-size: 60px;
            }
        }

        &__info-img {
            margin-top: -20px;
        }

        &__card {
            width: 145px;
            height: 100px;
        }
    }
}

@include rmin($desktop-sm) {
    .main-collections {
        &__info-wrapper {
            top: 25px;
        }

        &__info-text {
            padding: 40px 80px 40px 40px;
        }

        &__img-list {
            min-height: 285px;
        }

        &__info-img {
            margin-top: -35px;
        }

        &__card {
            width: 185px;
            height: 125px;
        }

        &__link {
            &::after {
                width: 155px;
                height: 45px;
            }
        }

        &__link-title {
            font-size: 28px;
        }
    }
}

@include rmin($desktop-md) {
    .main-collections {
        &__info-wrapper {
            top: 50px;
        }

        &__info-text {
            padding: 50px 0 50px 50px;

            h2 {
                font-size: 75px;
            }
        }

        &__info-img {
            margin-top: 0;
        }

        &__card {
            width: 210px;
            height: 145px;
        }
    }
}
