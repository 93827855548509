.gallery {
    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        margin-bottom: 10px;
        width: calc((100% - 10px) / 2);
        font-family: $font-serif;

        &:nth-child(odd) {
            margin-right: 10px;
        }
    }

    &__item,
    &__item-collection:hover &__item-title {
        color: var(--brown-light);
    }

    &__item-title,
    &__item-additional,
    &__item-place {
        display: block;
    }

    &__item-title {
        color: var(--gray-dark);
        margin-top: 9px;
        font-weight: 500;
        font-size: 14px;
    }

    &__item-place,
    &__item-descript,
    &__item-additional span {
        font-size: 14px;
        color: var(--gray);
    }

    &__item-additional {
        margin-top: 2px;

        &>span {
            &:not(:first-child) {
                &::before {
                    content: '•';
                    margin-left: 5px;
                    margin-right: 5px;

                    color: var(--brown-lighter);
                    font-size: 10px;
                }
            }
        }
    }

    &__item-place {
        margin-top: 9px;
        padding-top: 5px;
        border-top: 1px dashed var(--brown-light);
    }

    &__item-collection {
        margin-bottom: 10px;
        width: calc((100% - 10px) / 2);
        font-family: $font-serif;

        &:nth-child(odd) {
            margin-right: 10px;
        }
    }

    &__link {
        position: relative;
        display: block;
        height: 125px;

        &::after {
            content: '';
            position: absolute;
            top: 7px;
            right: 7px;
            left: 7px;
            bottom: 7px;
            border: 1px solid var(--bone);
        }
    }

    &__img {
        height: 100%;
        object-fit: cover;
        border: 5px solid var(--brown-lighter);
    }

    &__audio {
        width: 100%;
    }
}

@include rmin($desktop) {
    .gallery {
        &__item {
            margin-right: 15px;
            margin-bottom: 25px;
            width: 245px;
        }

        &__item-title {
          font-size: 16px;
        }

        &__link {
            height: 200px;
        }

        &__item-collection {
            margin-right: 15px;
            margin-bottom: 25px;
            width: 336px;
        }
    }
}

@include rmin($desktop-sm) {
    .gallery {
        &__list {
            max-width: $max-width-inner-md;
        }
    }
}

.pswp img {
    max-width: none;
    object-fit: contain;
}