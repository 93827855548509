.art {
    padding-bottom: 25px;

    &__hero {
        background-image: var(--hero-img-art);
        overflow-x: hidden;
        width: 100%;
    }

    &__header {
        width: 100%;
        overflow-x: hidden;
        background-repeat: no-repeat;
        background-position: center;

        &--painters {
            background-image: url(../img/art-painters1-max.jpg);
        }

        &--photographers {
            background-image: url(../img/artist-works/zarema-yaliboylu/bakhchisarai/6.jpg);
        }

        &--ceramic {
            background-image: url(../img/artist-works/mamut-churlu/ceramics/1.jpg);
        }

        &--textile {
            background-image: url(../img/artist-works/mamut-churlu/textile/6.jpg);
        }

        &--embro {
            background-image: url(../img/artist-works/alime-gusenova/2.jpg);
        }

        &--wood {
            background-image: url(../img/artist-works/emin-taymazov/3.jpg);
        }

        &--potter {
            background-image: url(../img/art-potters.jpg);
        }

        &--sculpture {
            background-image: url(../img/art-sculpture.jpg);
        }

        &--dancers {
            background-image: url(../img/art-dancers.jpg);
        }
        
        &--singers {
            background-image: url(../img/art-singers.jpg);
        }

        &--design {
            background-image: url(../img/art-designers.jpg);
        }

        &--jewelers {
            background-image: url(../img/art-jewelers.jpg);
        }

        &--musician {
            background-image: url(../img/artist-works/103-rustem-kurtmemetov/video/video-2.jpg);
        }
    }

    &__svg-text {
        left: 20px !important;
        width: 175px !important;
        height: 80px !important;
        bottom: 20px !important;
    }

    &__img--1 {
        background-image: var(--img-art-1);
    }

    &__img--2 {
        background-image: var(--img-art-2);
    }

    &__card-list-wrapper {
        margin: 25px -25px;
        padding-left: 15px;
        padding-top: 25px;
        padding-bottom: 25px;

        background-color: var(--bone);
    }

    &__card-item:last-child &__card:last-child {
        display: none;
    }

    &__card {
        display: block;
        width: 145px;
        height: 145px;
        padding: 5px;

        background-color: var(--white);
        text-decoration: none;

        &:first-child {
            margin-bottom: 15px;
        }

        h3 {
            font-weight: 400;
            color: var(--brown-light);
            margin-top: 5px;
        }
    }

    &__card-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;

        border: 1px solid var(--brown-light);
    }

    &__svg {
        width: 35px;
        height: 35px;
        fill: var(--brown-light);
    }

    // category
    &__list {
        // height: 380px;
    }

    &__category-card-list {}

    &__category-card {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;

        padding: 15px;
        width: 250px;
        height: 125px;

        .main-artists__btn-text {
            font-weight: 600;
        }
    }

    &__category-card:hover .main-artists__btn-text {
        display: block;
    }

    &__category-card:hover .main-artists__btn::before {
        right: 0;
    }

    &__category-card-title {
        h2 {
            margin-bottom: 5px;
            color: var(--bone);
            font-family: $font-serif;
            font-size: 20px;
            text-transform: uppercase;
        }
    }
}

@include rmin($desktop) {
    .art {
        padding-left: 50px;

        &__content--img {
            display: flex;
        }

        &__img-wrapper {
            min-width: 350px;
            height: 100%;
            margin-top: 25px;

            &--1 {
                margin-left: 50px;
                order: 1;
            }

            &--2 {
                margin-right: 50px;
            }
        }

        &__img--1 {
            height: 450px;
        }

        &__img--2 {
            height: 350px;
        }

        &__svg-text {
            left: 35px !important;
            bottom: 35px !important;

            width: 185px !important;
            height: 75px !important;
        }

        &__card-list-wrapper {
            margin-left: -25px;
            margin-right: -50px;
            padding: 25px 10px 25px 25px;

            width: calc(100% + 170px);
        }

        &__card {
            opacity: 0.75;

            &:hover {
                opacity: 1;
            }

            h3 {
                font-size: 16px;
            }
        }

        // category
        &__category-card {
            padding: 25px;
            width: 335px;
            height: 175px;
        }

        &__category-card-title {
            h2 {
                font-size: 26px;
            }
        }

        &__hero-category-card {
            width: 300px !important;
        }
    }
}

@include rmin($desktop-sm) {
    .art {

        // category
        &__list {
            padding-right: 150px;
        }

        // &__category-card {
        //     width: 465px;
        //     height: 225px;
        // }
    }
}

@include rmin($desktop-md) {
    .art {
        &__card {
            width: 200px;
            height: 200px;

            h3 {
                font-size: 20px;
            }
        }

        &__img-wrapper {
            min-width: 500px
        }

        &__img--1 {
            height: 550px;
        }

        &__img--2 {
            height: 450px;
        }


        &__card-list-wrapper {
            margin-top: 50px;
            margin-bottom: 50px;

            width: calc(100% + 273px);
        }

        &__card-item {
            :not(:last-child) {
                margin-right: 10px;
            }
        }

        &__svg {
            width: 50px;
            height: 50px;
        }
    }
}
