.about-project {
    &__img {
        display: block;
        background-image: url('../img/about-project.jpg');
        background-size: cover;
        border-radius: 5px;
        width: 100%;
        height: 112px;
        margin-bottom: 25px;
    }
}

@include rmin($sm-phone-2) {
    .about-project {
        &__img {
            height: 160px;
        }
    }
}

@include rmin($tablet) {
    .about-project {
        &__img {
            height: 250px;
        }
    }
}

@include rmin($desktop) {
    .about-project {
        &__img {
            max-width: 1000px;
            height: 316px;
        }
    }
}