.main-artists {
    &__wrapper {
        position: relative;
        min-height: 750px;
        margin-top: 150px;
        margin-bottom: 400px;
    }

    &__img-wrapper {
        position: absolute;
        bottom: 0;
        z-index: -1;
        height: 40%;
        width: 100%;
    }

    &__img {
        background-image: url(../img/main-artists.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
    }

    &__cards-wrapper {
        position: absolute;
        left: 25px;
        bottom: -425px;
        width: calc(100% - 25px);
    }

    &__card-list {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;

        scroll-snap-type: x mandatory;

    }

    &__card-list-item {
        margin-bottom: 10px;

        scroll-snap-align: start;

        // &:not(:last-child) {
        //     margin-right: 10px;
        // }

        &:first-child,
        &:last-child {
            display: flex;
        }

        &:last-child {
            &::after {
                content: '';
                display: block;
                width: 20px;
            }
        }
    }

    &__card {
        width: 250px;
        height: 370px;

        text-decoration: none;
    }

    &__card-title {
        text-align: center;
    }

    &__card-text {
        -webkit-line-clamp: 4;
        text-align: center;
        font-size: 14px;
        line-height: 1.5;
    }

    &__btn {
        margin-right: auto;
        min-height: 32px;

        &::before {
            right: unset;
            top: 8px;
        }
    }

    &__btn-text {
        display: none;
    }

    &__card-list-item:hover &__btn-text,
    .artists__list-card-item &__btn-text {
        display: block;
    }

    &__card-list-item:hover &__btn::before {
        right: 0;
    }

    .artists__list-card-item &__btn::before {
        transform: translateX(-50%);
    }

    .artists__list-card-item:hover &__btn::before {
        right: 25px;
    }
}

@include rmin($desktop) {
    .main-artists {
        max-height: $max-height;
        height: calc(var(--vh, 1vh) * 100);

        &__img-wrapper {
            width: 50%;
            height: 62%;
            right: 0;
            top: 0;
        }

        &__wrapper {
            display: flex;
            align-items: center;
            overflow: hidden;

            height: 100%;
            margin-top: 125px;
            margin-bottom: unset;
        }

        &__card-list {
            flex-wrap: wrap;
            margin-bottom: -15px;

            scroll-snap-type: unset;
            overflow-x: hidden;
        }

        &__cards-wrapper {
            right: 0;
            left: 25px;
            bottom: 25px;

            margin-left: unset;
        }

        &__card-list-top-item {
            scroll-snap-align: unset;
            margin-bottom: 15px;

            &:not(:last-child) {
                margin-right: 15px !important;
            }

            &:first-child {
                margin-left: unset !important;
            }
        }

        &__card-list-item {
            scroll-snap-align: unset;
            margin-bottom: 15px;

            &:first-child,
            &:last-child {
                display: unset;
            }

            &:last-child {
                &::after {
                    content: none;
                }
            }

            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        &__card {
            width: 155px;
            height: 250px;

            text-decoration: none;
        }

        &__card-title {
            font-size: 20px;
        }

        &__card-text {
            font-size: 14px;
            line-height: unset;
        }

        &__info-text {
            position: absolute;
            top: 0;
            left: 25px;
            width: 50%;
            margin-top: 10%;

            h2::before {
                top: -150px;
                height: 220px;
                width: 120px;
            }
        }

        // &__svg-text {
        //     z-index: 1;
        //     left: 0;
        //     bottom: 0;
        //     width: 350px !important;
        // }
    }
}

@include rmin($desktop-xs) {
    .main-artists {
        &__img-wrapper {
            height: 52%;
        }

        &__cards-wrapper {
            left: 50px;
        }

        &__info-text {
            left: 50px;
            margin-top: 5%;
        }

        &__card {
            width: 185px;
            height: 300px;
        }
    }
}

@include rmin($desktop-s) {
    .main-artists {
        &__wrapper {
            margin-top: 50px;
        }

        &__img-wrapper {
            height: 55%;
        }

        &__card {
            width: 205px;
        }
    }
}

@include rmin($desktop-sm) {
    .main-artists {
        &__cards-wrapper {
            bottom: 10px;
        }

        &__card {
            width: 250px;
            height: 365px;
        }

        &__card-title {
            font-size: 30px;
        }

        &__card-text {
            font-size: 16px;
        }
    }
}

@include rmin($desktop-md) {
    .main-artists {
        &__info-text {
            h2::before {
                height: 240px;
            }
        }

        &__cards-wrapper {
            bottom: 25px;
        }

        &__card {
            width: 300px;
            height: 435px;
        }

        &__card-title {
            font-size: 30px;
        }

        &__card-text {
            font-size: 20px;
        }
    }
}
