@charset "UTF-8";
:root { --blue-title: #413C54; --green: #0D8F8C; --red: #dc3545; --white: #FFFFFF; --gray-light: #E4E4E4; --gray: #545454; --gray-dark: #2B2B2A; --bone: #FFF4ED; --brown-lighter: #D09A82; --brown-light: #BC7966; --brown: #9F6F62; --brown-dark: #43211A; --border-brown: 3px solid var(--brown-light); --tablet: 680; --hero-img-history: url(../img/hero-history.jpg); --hero-img-culture: url(../img/hero-culture.jpg); --hero-img-collections: url(../img/hero-collections.jpg); --hero-img-artists: url(../img/hero-artists.jpg); --hero-img-art: url(../img/hero-art.jpg); --hero-img-library: url(../img/hero-library.jpg); --img-culture-1: url(../img/culture-page-img-1.jpg); --img-culture-2: url(../img/culture-page-img-2.jpg); --img-culture-3: url(../img/culture-page-img-3.jpg); --img-artists-1: url(../img/artists-page-1.jpg); --img-artists-2: url(../img/artists-page-2.jpg); --img-art-1: url(../img/art-page-1.jpg); --img-art-2: url(../img/art-page-2.jpg); --img-library-1: url(../img/library-page-1.jpg); --img-library-2: url(../img/library-page-2.jpg); }

.hero__img-wrapper, .hero__text-container h2, .hero__text-container .hero__text, .hero__text-container a, .hero__tour-wrapper { opacity: 0; }

.hero__img-wrapper { transform: translate3d(0px, 100px, 0px); }

.hero__text-container h2, .hero__text-container .hero__text, .hero__text-container a { transform: translate3d(-25px, 0px, 0px); }

.hero__tour-wrapper { transform: translate3d(-50px, 0px, 0px); }

@media only screen and (min-width: 1000px) { .hero__img-wrapper, .hero__pattern, .hero__tour-wrapper { opacity: 0; }
  .hero__img-wrapper { transform: translate3d(0px, 100px, 0px); }
  .hero__pattern { transform: translate3d(-200px, 0px, 0px); }
  .hero__text-container h2, .hero__text-container .hero__text, .hero__text-container a { opacity: 0; transform: translate3d(0px, 200px, 0px); }
  .hero__tour-wrapper { transform: translate3d(0px, 0px, 0px); }
  .main-history__text-wrapper h2, .main-history__text-wrapper p, .main-history__text-wrapper a { opacity: 0; transform: translate3d(0px, 200px, 0px); }
  .main-history__img-wrapper { opacity: 0; transform: translate3d(100px, -50px, 0px); }
  .main-history__slider-wrapper .glide__arrows, .main-history__slider-wrapper .glide__slide { opacity: 0; transform: translate3d(0px, 50px, 0px); }
  .main-history__svg-text { opacity: 0; }
  .main-culture__text-wrapper h2, .main-culture__text-wrapper p, .main-culture__text-wrapper a { opacity: 0; transform: translate3d(0px, 200px, 0px); }
  .main-culture__img-wrapper, .main-culture__svg-text { opacity: 0; }
  .main-culture__card-list-wrapper .card__slider-list li { opacity: 0; transform: translate3d(0px, 50px, 0px); }
  .main-culture__img-wrapper { transform: translate3d(0px, 75px, 0px); }
  .main-collections__img-wrapper { opacity: 0; transform: translate3d(0px, 200px, 0px); }
  .main-collections__info-text { opacity: 0; }
  .main-collections__info-text h2, .main-collections__info-text p, .main-collections__info-text a { opacity: 0; transform: translate3d(50px, 0px, 0px); }
  .main-collections__img-item--first .main-collections__card-wrapper, .main-collections__link--first, .main-collections__img-item--second .main-collections__card-wrapper, .main-collections__link--second { opacity: 0; transform: translate3d(0px, 50px, 0px); }
  .main-artists__wrapper .main-artists__img-wrapper, .main-artists__wrapper .main-artists__card-list-item { opacity: 0; }
  .main-artists__wrapper .main-artists__info-text p, .main-artists__wrapper .main-artists__info-text a { opacity: 0; transform: translate3d(0px, 50px, 0px); }
  .main-artists__wrapper .main-artists__info-text h2 { opacity: 0; transform: translate3d(0px, 100px, 0px); }
  .main-artists__wrapper .main-artists__card-list-item { transform: translate3d(50px, 0px, 0px); }
  .main-art__info-wrapper h2, .main-art__info-wrapper p, .main-art__info-wrapper a { opacity: 0; }
  .main-art__info-wrapper h2 { transform: translate3d(0px, 100px, 0px); }
  .main-art__info-wrapper p, .main-art__info-wrapper a { transform: translate3d(0px, 50px, 0px); }
  .main-art__img, .main-art__svg-text { opacity: 0; }
  .main-art__img { transform: translate3d(-50px, 0px, 0px); }
  .main-art__svg-text { transform: translate3d(0px, 50px, 0px); }
  .main-library__wrapper .main-library__img-wrapper, .main-library__wrapper .main-library__cards-wrapper, .main-library__wrapper .main-library__card-list-item { opacity: 0; }
  .main-library__wrapper .main-library__img-wrapper { transform: translate3d(-50px, 0px, 0px); }
  .main-library__wrapper .main-library__info-wrapper h2, .main-library__wrapper .main-library__info-wrapper p, .main-library__wrapper .main-library__info-wrapper a { opacity: 0; }
  .main-library__wrapper .main-library__info-wrapper h2 { transform: translate3d(100px, 0px, 0px); }
  .main-library__wrapper .main-library__info-wrapper p, .main-library__wrapper .main-library__info-wrapper a { transform: translate3d(50px, 0px, 0px); }
  .main-library__wrapper .main-library__card-list-item { transform: translate3d(0px, 50px, 0px); } }

.img__hero-inner { opacity: 0; animation: slide-in-top 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.svg-text-inner { opacity: 0; animation: slide-in-bottom 1.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.anim-section { opacity: 0; animation: slide-in-bottom 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; animation-delay: 1.5s; }

.anim-section-2 { opacity: 0; animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; animation-delay: 1.5s; }

.anim-section-3 { opacity: 0; animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; animation-delay: 1.25s; }

.anim-section-4 { opacity: 0; animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; animation-delay: 2s; }

@keyframes slide-in-top { 0% { transform: translateY(-50px); }
  100% { transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-bottom { 0% { transform: translateY(50px); }
  100% { transform: translateY(0);
    opacity: 1; } }

.icon-arrow-left { width: 1.39em; height: 1em; fill: initial; }

.icon-arrow-right { width: 1.39em; height: 1em; fill: initial; }

.icon-art-1 { width: 1em; height: 1em; fill: initial; }

.icon-art-10 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-11 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-12 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-13 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-2 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-3 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-4 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-5 { width: 1em; height: 1em; fill: initial; }

.icon-art-6 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-7 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-8 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-9 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-hero { width: 2.14em; height: 1em; fill: initial; }

.icon-artists-hero { width: 4.12em; height: 1em; fill: initial; }

.icon-collections-hero { width: 14.22em; height: 1em; fill: initial; }

.icon-culture-card-ico-1 { width: 1em; height: 1em; fill: initial; }

.icon-culture-card-ico-2 { width: 1em; height: 1em; fill: initial; }

.icon-culture-card-ico-3 { width: 0.96em; height: 1em; fill: initial; }

.icon-culture-card-ico-4 { width: 1em; height: 1em; fill: initial; }

.icon-culture-card-ico-5 { width: 1em; height: 1em; fill: initial; }

.icon-culture-card-ico-6 { width: 1em; height: 1em; fill: initial; }

.icon-culture-card-ico-7 { width: 1em; height: 1em; fill: initial; }

.icon-culture-hero { width: 4.65em; height: 1em; fill: initial; }

.icon-facebook { width: 0.53em; height: 1em; fill: initial; }

.icon-genre-ico { width: 1em; height: 1em; fill: initial; }

.icon-go-arrow { width: 1.33em; height: 1em; fill: initial; }

.icon-hero-pattern { width: 1em; height: 1em; fill: initial; }

.icon-history-hero { width: 3.71em; height: 1em; fill: initial; }

.icon-history-tour { width: 1em; height: 1em; fill: initial; }

.icon-library-card-ico-1 { width: 1em; height: 1em; fill: initial; }

.icon-library-card-ico-2 { width: 1.31em; height: 1em; fill: initial; }

.icon-library-card-ico-3 { width: 1em; height: 1em; fill: initial; }

.icon-library-card-ico-4 { width: 1em; height: 1em; fill: initial; }

.icon-library-card-ico-5 { width: 1em; height: 1em; fill: initial; }

.icon-library-hero { width: 3.75em; height: 1em; fill: initial; }

.icon-mail-us { width: 1em; height: 1em; fill: initial; }

.icon-mail { width: 1.32em; height: 1em; fill: initial; }

.icon-main-art-pattern { width: 1em; height: 1em; fill: initial; }

.icon-main-art { width: 2.14em; height: 1em; fill: initial; }

.icon-main-artists { width: 3.84em; height: 1em; fill: initial; }

.icon-main-culture { width: 5.46em; height: 1em; fill: initial; }

.icon-main-exhibition { width: 6.06em; height: 1em; fill: initial; }

.icon-main-history { width: 0.28em; height: 1em; fill: initial; }

.icon-main-library { width: 3.75em; height: 1em; fill: initial; }

.icon-pattern { width: 1em; height: 1em; fill: initial; }

.icon-phone-alt { width: 1em; height: 1em; fill: initial; }

.icon-romb { width: 0.97em; height: 1em; fill: initial; }

.icon-search { width: 1em; height: 1em; fill: initial; }

.icon-arrow-left { width: 1.39em; height: 1em; fill: initial; }

.icon-arrow-right { width: 1.39em; height: 1em; fill: initial; }

.icon-art-1 { width: 1em; height: 1em; fill: initial; }

.icon-art-10 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-11 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-12 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-13 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-2 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-3 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-4 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-5 { width: 1em; height: 1em; fill: initial; }

.icon-art-6 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-7 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-8 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-9 { width: 1.03em; height: 1em; fill: initial; }

.icon-art-hero { width: 2.14em; height: 1em; fill: initial; }

.icon-artists-hero { width: 4.12em; height: 1em; fill: initial; }

.icon-collections-hero { width: 14.22em; height: 1em; fill: initial; }

.icon-culture-card-ico-1 { width: 1em; height: 1em; fill: initial; }

.icon-culture-card-ico-2 { width: 1em; height: 1em; fill: initial; }

.icon-culture-card-ico-3 { width: 0.96em; height: 1em; fill: initial; }

.icon-culture-card-ico-4 { width: 1em; height: 1em; fill: initial; }

.icon-culture-card-ico-5 { width: 1em; height: 1em; fill: initial; }

.icon-culture-card-ico-6 { width: 1em; height: 1em; fill: initial; }

.icon-culture-card-ico-7 { width: 1em; height: 1em; fill: initial; }

.icon-culture-hero { width: 4.65em; height: 1em; fill: initial; }

.icon-facebook { width: 0.53em; height: 1em; fill: initial; }

.icon-genre-ico { width: 1em; height: 1em; fill: initial; }

.icon-go-arrow { width: 1.33em; height: 1em; fill: initial; }

.icon-hero-pattern { width: 1em; height: 1em; fill: initial; }

.icon-history-hero { width: 3.71em; height: 1em; fill: initial; }

.icon-history-tour { width: 1em; height: 1em; fill: initial; }

.icon-library-card-ico-1 { width: 1em; height: 1em; fill: initial; }

.icon-library-card-ico-2 { width: 1.31em; height: 1em; fill: initial; }

.icon-library-card-ico-3 { width: 1em; height: 1em; fill: initial; }

.icon-library-card-ico-4 { width: 1em; height: 1em; fill: initial; }

.icon-library-card-ico-5 { width: 1em; height: 1em; fill: initial; }

.icon-library-hero { width: 3.75em; height: 1em; fill: initial; }

.icon-mail-us { width: 1em; height: 1em; fill: initial; }

.icon-mail { width: 1.32em; height: 1em; fill: initial; }

.icon-main-art-pattern { width: 1em; height: 1em; fill: initial; }

.icon-main-art { width: 2.14em; height: 1em; fill: initial; }

.icon-main-artists { width: 3.84em; height: 1em; fill: initial; }

.icon-main-culture { width: 5.46em; height: 1em; fill: initial; }

.icon-main-exhibition { width: 6.06em; height: 1em; fill: initial; }

.icon-main-history { width: 0.28em; height: 1em; fill: initial; }

.icon-main-library { width: 3.75em; height: 1em; fill: initial; }

.icon-pattern { width: 1em; height: 1em; fill: initial; }

.icon-phone-alt { width: 1em; height: 1em; fill: initial; }

.icon-romb { width: 0.97em; height: 1em; fill: initial; }

.icon-search { width: 1em; height: 1em; fill: initial; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

@media (prefers-reduced-motion: reduce) { * { transition: none !important; } }

@font-face { font-family: FrankRuhlLibre; src: url("../fonts/FrankRuhlLibre-Light.woff2"); font-weight: 300; font-style: normal; font-display: swap; }

@font-face { font-family: FrankRuhlLibre; src: url("../fonts/FrankRuhlLibre-Regular.woff2"); font-weight: 400; font-style: normal; font-display: swap; }

@font-face { font-family: FrankRuhlLibre; src: url("../fonts/FrankRuhlLibre-Medium.woff2"); font-weight: 500; font-style: normal; font-display: swap; }

@font-face { font-family: FrankRuhlLibre; src: url("../fonts/FrankRuhlLibre-Bold.woff2"); font-weight: 700; font-style: normal; font-display: swap; }

@font-face { font-family: GillSans; src: url("../fonts/GillSans-Light.woff2"); font-weight: 300; font-style: normal; font-display: swap; }

@font-face { font-family: GillSans; src: url("../fonts/GillSans-Regular.woff2"); font-weight: 400; font-style: normal; font-display: swap; }

@font-face { font-family: GillSans; src: url("../fonts/GillSans-Medium.woff2"); font-weight: 500; font-style: normal; font-display: swap; }

@font-face { font-family: GillSans; src: url("../fonts/GillSans-SemiBold.woff2"); font-weight: 600; font-style: normal; font-display: swap; }

@font-face { font-family: GillSans; src: url("../fonts/GillSans-Bold.woff2"); font-weight: 700; font-style: normal; font-display: swap; }

body { background-color: var(--white); font-size: 16px; line-height: 1.4; font-family: "GillSans", sans-serif; }

.site-container { display: flex; margin-left: auto; margin-right: auto; max-width: 1919px; }

.serif-text { font-family: "FrankRuhlLibre", sans-serif; color: var(--blue-title); }

.site-content { width: 100%; }

.text-section { padding-left: 25px; padding-right: 25px; }

.text-info__wrapper { margin-bottom: 50px; }

.text-info { font-size: 16px; line-height: 22px; margin-bottom: 15px; color: var(--gray-dark); }

section h2 { font-size: 35px; }

section .main-text { font-size: 14px; line-height: 24px; color: var(--gray); }

section .main-text-inner { margin-top: 15px; }

section .svg-text { display: none; }

section .svg-text-inner { position: absolute; stroke: var(--bone); fill: transparent; left: 39px; width: 275px; height: 100px; stroke-width: 2; }

.t-crop { display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; }

.t-crop--one-l { -webkit-line-clamp: 1; }

.t-crop--two-l { -webkit-line-clamp: 2; }

.t-crop--three-l { -webkit-line-clamp: 3; }

img { max-width: 100%; height: auto; width: 100%; }

.img__full-page { background-repeat: no-repeat; background-size: cover; }

.img__hero-inner { position: relative; min-height: 350px; background-size: cover; background-repeat: no-repeat; background-position: center; }

.img__hero-inner::after { content: ''; position: absolute; left: 0; right: 0; top: 0; height: 130px; background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%); }

.img__hero-inner::before { content: ''; position: absolute; left: 0; right: 0; bottom: 0; height: 130px; background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); }

.img__covered { display: block; min-height: 240px; background-size: cover; background-repeat: no-repeat; background-position: center; }

.img__bordered { position: relative; left: -10px; right: -10px; margin-top: 15px; margin-bottom: 35px; padding: 5px; width: calc(100% + 20px); }

.img__bordered::after { content: ''; position: absolute; z-index: -1; bottom: -20px; left: -25px; right: -25px; width: calc(100% + 50px); height: 125px; background-color: var(--bone); }

.img__bordered::before { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: 1px solid var(--brown-light); }

.vector-icon { fill: var(--gray); width: 35px; height: 35px; }

.visually-hidden, .visually-hidden::after, .visually-hidden::before { position: absolute !important; z-index: -1 !important; width: 1px !important; height: 1px !important; margin: -1px !important; opacity: 0 !important; font-size: 1px !important; clip: rect(0 0 0 0) !important; }

.content-wrapper { padding-left: 25px; padding-right: 25px; }

.actions-blocker { position: fixed; top: 0; left: 0; z-index: 5; width: 100vw; height: 100vh; background-color: transparent; pointer-events: none; }

.actions-blocker.blocked { pointer-events: all; }

.content__section { margin-top: 50px; }

.section__text-content { margin-top: 15px; padding-top: 25px; }

.decorated__list li { margin-top: 5px; }

.decorated__list li::before { content: ""; display: inline-block; margin-right: 15px; width: 7px; height: 7px; background-color: var(--brown-light); transform: rotate(-45deg); }

.link { text-decoration: none; color: var(--brown-light); }

.link:hover { color: var(--brown-lighter); }

.m-t-35 { margin-top: 35px; }

@media only screen and (min-width: 680px) { section .main-text { font-size: 18px; line-height: 30px; }
  section h2 { font-size: 55px; line-height: 1.1; }
  .section__text-content h3 { font-size: 26px; } }

@media only screen and (min-width: 1000px) { .site-content { margin-left: 200px; width: calc(100% - 200px); }
  .content-wrapper { padding-left: unset; padding-right: unset; }
  .content__section { margin-top: 75px; }
  .lift-up { transition: transform 0.5s ease 0s; will-change: transform; }
  .lift-up:hover, .lift-up:focus { transform: translateY(-5px); }
  .text-section { padding-left: unset; padding-right: unset; }
  .text-info__wrapper { max-width: 916px; margin-bottom: 75px; }
  .text-info { font-size: 22px; line-height: 32px; }
  .text-ellipsis { display: -webkit-box; -webkit-line-clamp: 7; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; }
  .img__hero-inner { height: 400px; background-position-y: bottom; }
  .img__bordered { width: 350px; left: unset; right: unset; }
  section .main-text { font-size: 16px; line-height: 24px; }
  section .main-text-inner { margin-top: 25px; }
  section .svg-text { display: block; position: absolute; stroke: var(--bone); fill: transparent; width: 150px; stroke-width: 2; }
  section .svg-text-inner { width: 414px; height: 155px; bottom: 0px; }
  .title-background { position: relative; }
  .title-background::before { width: 120px; height: 75px; }
  .content__max-width { max-width: 1000px; }
  .pswp-collections .pswp__zoom-wrap { transform: unset !important; }
  .pswp-collections .pswp__img { top: 50%; left: 50%; transform: translate(-50%, -50%); }
  .pswp-collections img { width: 90% !important; height: 90% !important; } }

@media only screen and (min-width: 1365px) { section .main-text { font-size: 18px; line-height: 26px; } }

@media only screen and (min-width: 1600px) { .img__hero-inner { height: 155px; }
  section .svg-text-inner { width: 414px; }
  section .main-text { font-size: 20px; line-height: 30px; }
  .content__max-width { max-width: 1200px; } }

@media only screen and (min-width: 1919px) { .site-content { margin-left: 255px; width: calc(100% - 255px); }
  section h2 { font-size: 75px; }
  section .main-text { font-size: 24px; line-height: 35px; }
  .title-background::before { height: 100px; }
  .content__max-width { max-width: 1400px; } }

.site-header { position: fixed; top: 0; left: 0; right: 0; z-index: 3; display: flex; align-items: flex-end; }

.site-header__content-wrapper { width: 100%; transform: translate3d(0, 0, 0); transition: transform 0.5s ease-in-out; will-change: transform; }

.site-header__content-wrapper--hided { transform: translate3d(0, -100px, 0); }

.site-header__content-wrapper--shrink { background-color: var(--white); }

.site-header__nav-toggler { position: absolute; top: 25px; right: 10px; z-index: 2; display: flex; align-items: center; width: 60px; height: 60px; font-size: 24px; line-height: 24px; text-transform: uppercase; background-color: transparent; touch-action: manipulation; }

.site-header__nav-toggler .shape { position: absolute; left: 10px; top: 50%; width: 40px; height: 20px; color: var(--bone); transform: translateY(-50%); }

.site-header__nav-toggler .line { display: block; width: 65%; height: 3px; margin: 0 0 8px; margin-left: auto; margin-right: auto; background-color: currentColor; transform-origin: right center; transform: scaleX(0); will-change: transform; }

.site-header__nav-toggler .line:nth-child(2) { width: 100%; }

.site-header__nav-toggler .close { position: absolute; left: 50%; top: 50%; width: 35px; height: 35px; color: #fff; transform: translate(-50%, -50%) rotate(0) scale(0); }

.site-header__nav-toggler .close::before, .site-header__nav-toggler .close::after { content: ''; position: absolute; left: 50%; top: 50%; background: currentColor; }

.site-header__nav-toggler .close::before { width: 3px; height: 25px; margin: -12px 0 0 -1px; }

.site-header__nav-toggler .close::after { width: 25px; height: 3px; margin: -1px 0 0 -12px; }

.site-header__logo { position: absolute; z-index: 2; top: 25px; left: 25px; display: flex; align-items: center; width: 65px; height: 65px; text-decoration: none; }

.site-header__logo-text { color: var(--white); font-size: 14px; line-height: 1.4; margin-left: 10px; opacity: 0; visibility: hidden; }

.site-header__nav-wrapper { height: 100px; opacity: 0; pointer-events: none; }

.site-header__nav-toggler--toggled ~ .site-header__nav-wrapper { height: unset; opacity: 1; pointer-events: auto; }

.site-header__overlay { position: absolute; top: 0; left: 0; width: 100vw; height: 100vh; background-color: var(--brown-dark); transform: scaleX(0); transform-origin: left center; }

.site-header__overlay::after { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: url(../img/nav-bg.png) no-repeat; background-size: cover; }

.site-header__nav { display: flex; flex-wrap: wrap; height: 100vh; }

.site-header__content-wrapper--shrink .site-header__nav-wrapper { height: 75px; }

.site-header__content-wrapper--shrink .site-header__logo { top: 5px; left: 10px; }

.site-header__content-wrapper--shrink .site-header__nav-toggler { top: 5px; }

.site-header__content-wrapper--shrink .site-header__nav-toggler .line { background-color: var(--brown-light); }

@media only screen and (min-width: 680px) { .site-header__logo { width: 100px; height: 100px; }
  .site-header__nav-toggler { width: 80px; height: 80px; }
  .site-header__nav-toggler .shape { width: 60px; }
  .site-header__nav-toggler .line { margin-bottom: 12px; height: 4px; }
  .site-header__nav-toggler .close { width: 60px; height: 60px; }
  .site-header__nav-toggler .close::after { width: 45px; height: 4px; margin-left: -22px; }
  .site-header__nav-toggler .close::before { margin-top: -22px; width: 4px; height: 45px; }
  .site-header__content-wrapper--shrink .site-header__nav-wrapper { height: 110px; }
  .site-header__content-wrapper--hided { transform: translate3d(0, -110px, 0); }
  .site-header__logo-text { margin-left: 20px; font-size: 20px; } }

@media only screen and (min-width: 1000px) { .site-header { position: fixed; z-index: unset; top: 0; left: unset; right: unset; }
  .site-header__content-wrapper { display: block; height: 100vh; max-width: 200px; padding: 0 20px; transform: unset; }
  .site-header__logo { position: unset; margin-top: 50px; margin-left: 15px; margin-right: 15px; width: 125px; height: 125px; }
  .site-header__logo:hover { opacity: 0.8 !important; }
  .site-header__logo-text { display: none; }
  .site-header__container { min-height: unset; }
  .site-header__nav-toggler { display: none; }
  .site-header__nav-wrapper { display: block; height: unset; opacity: unset; pointer-events: unset; } }

@media only screen and (min-width: 1365px) { .site-header__logo { margin-left: unset; margin-right: unset; width: auto; height: auto; } }

@media only screen and (min-width: 1919px) { .site-header__content-wrapper { max-width: 255px; padding: 0 30px; } }

.nav { position: relative; z-index: 1; }

.nav__list { margin: auto auto auto 60px; padding-top: 50px; width: 100%; }

.nav__list-item { position: relative; color: var(--bone); font-family: "FrankRuhlLibre", sans-serif; font-weight: 700; font-size: 22px; text-align: left; overflow: hidden; }

.nav__list-item:not(:last-child) { margin-bottom: 20px; }

.nav__list-item:hover, .nav__list-item:focus { color: var(--brown-light); }

.nav__list-item--active { color: var(--brown-light); }

.nav__list-link { display: block; color: currentColor; text-decoration: none; transition: color 0.5s; transform: translate3d(0, 100%, 0); opacity: 0; visibility: hidden; }

.nav__list-link:last-child { display: flex; align-items: center; padding-right: unset; }

.nav__list-item:hover .nav__list-link::before { transition: transform 0.3s; transform: scaleX(1); transform-origin: right center; }

.nav__list-item--active .nav__list-link::before { transform: scaleX(1); }

.nav__svg-mail { margin-left: 5px; width: 25px; height: 25px; fill: currentColor; }

@media only screen and (min-width: 680px) { .nav__list { margin-left: 75px; }
  .nav__list-item { font-size: 35px; }
  .nav__list-item:not(:last-child) { margin-bottom: 35px; } }

@media only screen and (min-width: 1000px) { .nav__list { display: block; margin: unset; margin-top: 25px; padding-top: unset; }
  .nav__list-item { margin-bottom: 15px; font-size: 20px; text-align: right; color: var(--brown-dark); }
  .nav__list-item:not(:last-child) { margin-bottom: 7px; }
  .nav__list-item--active { color: var(--brown); }
  .nav__list-link { padding-top: 7px; padding-bottom: 7px; }
  .nav__list-link:last-child { display: block; padding-right: 30px; }
  .nav__list-link::before { content: ""; position: absolute; top: 0; bottom: 0; right: 18px; z-index: -1; width: 150px; transform: scaleX(0); border-radius: 5px; background-color: var(--bone); }
  .nav__list-item--privacy { font-weight: 600; font-size: 16px; line-height: 19px; color: var(--gray); margin-top: 10vh; } }

@media only screen and (min-width: 1365px) { .nav__list { margin-top: 60px; } }

@media only screen and (min-width: 1919px) { .nav__list-item { font-size: 24px; }
  .nav__list-link:last-child { padding-right: 40px; }
  .nav__list-link::before { right: 25px; }
  .nav__list-item--privacy { font-size: 16px; } }

.hero { position: relative; max-height: 1080px; height: calc(var(--vh, 1vh) * 100); }

.hero__content-wrapper { width: 100%; height: 100%; max-height: 1080px; }

.hero__content-wrapper h2 { margin-bottom: 25px; font-size: 45px; line-height: 50px; color: var(--blue-title); text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25); }

.hero__img-wrapper { position: relative; width: 100%; height: 100%; background-image: url(../img/hero-slide-1.jpg); background-position: center; }

.hero__text-wrapper { position: absolute; top: 0; height: 100%; }

.hero__pattern { display: none; }

.hero__text-container { margin-top: 185px; height: calc(100% - 185px); }

.hero__text { margin-top: 5px; margin-bottom: 25px; padding-right: 25%; color: var(--white); font-size: 18px; text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25); }

.hero__tour-links-wrap { position: absolute; bottom: 35px; left: 25px; right: 25px; }

.hero__tour-wrapper { display: block; padding: 15px; width: 100%; background: var(--white); border: 1px solid var(--brown-lighter); box-sizing: border-box; border-radius: 10px; text-decoration: none; }

.hero__tour-wrapper h3 { font-size: 18px; }

.hero__tour-wrapper:hover { opacity: 1; }

.hero__tour-text, .hero__library-text { color: var(--blue-title); }

.hero__library-wrapper { margin-top: 5px; border: 1px solid var(--brown-lighter); }

.hero__tour, .hero__library { position: relative; display: flex; justify-content: space-between; }

.hero__tour-icon { width: 35px; fill: var(--brown-light); }

.hero__tour-arrow { stroke: var(--blue-title); stroke-width: 2; fill: none; right: 10px; bottom: 10px; width: 25px; height: 15px; opacity: 0.5; transition: opacity 0.5s; }

.hero__tour-wrapper:hover .hero__tour-arrow { opacity: 1; }

.hero__tour-text, .hero__library-text { font-size: 18px; }

.hero__tour-text, .hero__library-text { font-family: "GillSans", sans-serif; font-style: normal; font-weight: bold; }

.hero__tour-text, .hero__library-text { margin-top: -5px; }

.hero__library-text { color: var(--blue-title); }

.hero__tour-link { position: absolute; display: flex; align-items: center; margin-top: -10px; font-weight: 600; font-size: 14px; line-height: 17px; color: #9E9E9E; }

.hero__tour-link p { margin-right: 5px; }

.hero__library-link { color: var(--blue-title); }

.hero__library-icon { margin-top: -8px; margin-bottom: -8px; width: 50px; height: 50px; }

@media only screen and (min-width: 414px) { .hero__content-wrapper h2 { font-size: 55px; line-height: 65px; } }

@media only screen and (min-width: 680px) { .hero__text-wrapper h2 { font-size: 100px; }
  .hero__text { font-size: 30px; }
  .hero__text-container { margin-top: 350px; width: 650px; }
  .hero__tour-wrapper { padding: 40px 80px 40px 40px; max-width: 400px; }
  .hero__tour-wrapper h3 { font-size: 28px; }
  .hero__library-wrapper { padding: 40px 80px 40px 40px; max-width: 400px; }
  .hero__library-wrapper h3 { font-size: 28px; }
  .hero__tour-text { font-size: 18px; }
  .hero__library-text { font-size: 18px; }
  .hero__text-container { position: relative; margin-top: unset; width: unset; height: 100%; }
  .hero__tour-links-wrap { position: absolute; bottom: 50px; left: unset; right: unset; display: flex; }
  .hero__tour-links-wrap > *:not(:first-child) { margin-left: 20px; } }

@media only screen and (min-width: 1000px) { .hero__content-wrapper { display: flex; }
  .hero__content-wrapper h2 { padding-top: 45%; margin-bottom: 15px; font-size: 65px; line-height: 75px; color: var(--blue-title); text-shadow: unset; }
  .hero__img-wrapper { width: 50%; }
  .hero__text-wrapper { position: relative; max-width: 50%; padding-left: 30px; }
  .hero__pattern { display: block; position: absolute; z-index: -1; top: 20px; left: -120px; width: 325px; height: 325px; }
  .hero__text { margin-bottom: 25px; padding-right: unset; width: 300px; color: var(--gray); font-size: 24px; text-shadow: unset; }
  .hero__text-container { position: relative; margin-top: unset; width: unset; height: 100%; }
  .hero__tour-links-wrap { display: flex; }
  .hero__tour-links-wrap > *:not(:first-child) { margin-left: 20px; }
  .hero__tour-wrapper { padding: 15px; width: 45%; }
  .hero__tour-wrapper h3 { font-size: 30px; }
  .hero__library-wrapper { margin-top: unset; background: unset; background-color: var(--white); }
  .hero__tour-icon { width: 50px; height: 50px; fill: var(--brown-lighter); }
  .hero__library-icon { margin-top: unset; margin-bottom: unset; margin-right: -10px; width: 55px; height: 55px; }
  .hero__tour-link, .hero__library-link { bottom: 10px; }
  .hero__library-link { color: #9e9e9e; }
  .hero__tour-text, .hero__library-text { font-size: 16px; }
  .hero__tour-arrow { width: 18px; height: 13px; } }

@media only screen and (min-width: 1150px) { .hero__content-wrapper { display: flex; }
  .hero__tour-icon { width: 70px; height: 70px; }
  .hero__tour-text, .hero__library-text { margin-top: unset; }
  .hero__library-icon { width: 75px; height: 75px; } }

@media only screen and (min-width: 1365px) { .hero__content-wrapper h2 { padding-top: 50%; margin-bottom: 35px; font-size: 75px; line-height: 85px; }
  .hero__img-wrapper { width: 55%; }
  .hero__text-wrapper { max-width: 45%; }
  .hero__tour-links-wrap { position: absolute; bottom: 50px; }
  .hero__tour-text { margin-top: -5px; }
  .hero__tour-link { bottom: 15px; }
  .hero__tour-text, .hero__library-text { font-size: 18px; } }

@media only screen and (min-width: 1600px) { .hero__content-wrapper h2 { font-size: 100px; }
  .hero__text { width: 350px; font-size: 30px; }
  .hero__pattern { width: 478px; height: 478px; }
  .hero__tour-text, .hero__library-text { font-size: 18px; }
  .hero__tour-wrapper { right: 0; bottom: 35px; padding: 25px; }
  .hero__tour-wrapper h3 { font-size: 24px; }
  .hero__library-wrapper { right: 0; bottom: 35px; }
  .hero__library-wrapper h3 { font-size: 24px; } }

@media only screen and (min-width: 1919px) { .hero__content-wrapper h2 { font-size: 110px; line-height: 125px; }
  .hero__text { width: 350px; font-size: 30px; }
  .hero__text-wrapper { padding-left: 50px; }
  .hero__tour-text, .hero__library-text { font-size: 22px; }
  .hero__tour-wrapper { bottom: 50px; right: 150px; transform: translate(-50%); }
  .hero__library-wrapper { bottom: 50px; right: -70px; transform: translate(-50%); }
  .hero__tour-text { font-size: 24px; }
  .hero__library-text { font-size: 24px; } }

.glide { position: relative; width: 100%; box-sizing: border-box; }

.glide * { box-sizing: inherit; }

.glide__track { overflow: hidden; }

.glide__slides { position: relative; width: 100%; list-style: none; backface-visibility: hidden; transform-style: preserve-3d; touch-action: pan-Y; overflow: hidden; padding: 0; white-space: nowrap; display: flex; flex-wrap: nowrap; will-change: transform; }

.glide__slides--dragging { user-select: none; }

.glide__slide { width: 100%; height: 100%; flex-shrink: 0; white-space: normal; user-select: none; -webkit-touch-callout: none; -webkit-tap-highlight-color: transparent; }

.glide__slide a { user-select: none; -webkit-user-drag: none; -moz-user-select: none; -ms-user-select: none; }

.glide__arrows { -webkit-touch-callout: none; user-select: none; }

.glide__bullets { -webkit-touch-callout: none; user-select: none; }

.glide--rtl { direction: rtl; }

.glide__arrow { position: absolute; display: block; top: 50%; z-index: 2; color: white; text-transform: uppercase; padding: 9px 12px; background-color: transparent; border: 2px solid rgba(255, 255, 255, 0.5); border-radius: 4px; box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1); text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1); opacity: 1; cursor: pointer; transition: opacity 150ms ease, border 300ms ease-in-out; transform: translateY(-50%); line-height: 1; }

.glide__arrow:focus { outline: none; }

.glide__arrow:hover { border-color: white; }

.glide__arrow--left { left: 2em; }

.glide__arrow--right { right: 2em; }

.glide__arrow--disabled { opacity: 0.33; }

.glide__bullets { position: absolute; z-index: 2; bottom: 2em; left: 50%; display: inline-flex; list-style: none; transform: translateX(-50%); }

.glide__bullet { background-color: rgba(255, 255, 255, 0.5); width: 9px; height: 9px; padding: 0; border-radius: 50%; border: 2px solid transparent; transition: all 300ms ease-in-out; cursor: pointer; line-height: 0; box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1); margin: 0 0.25em; }

.glide__bullet:focus { outline: none; }

.glide__bullet:hover, .glide__bullet:focus { border: 2px solid white; background-color: rgba(255, 255, 255, 0.5); }

.glide__bullet--active { background-color: white; }

.glide--swipeable { cursor: grab; cursor: -moz-grab; cursor: -webkit-grab; }

.glide--dragging { cursor: grabbing; cursor: -moz-grabbing; cursor: -webkit-grabbing; }

.main-history__wrapper { position: relative; min-height: 350px; padding-bottom: 350px; }

.main-history__text-wrapper { position: relative; z-index: 1; }

.main-history__text-wrapper h2 { margin-top: 100px; }

.main-history__img-wrapper { position: absolute; bottom: 0; z-index: -1; width: 100%; height: 40%; background-position: center; background-image: url(../img/main-history.png); }

.main-history__img-wrapper::before { content: ''; position: absolute; top: 0; right: 0; left: 0; height: 200px; background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); }

.main-history__img-wrapper::after { content: ''; position: absolute; right: 0; bottom: 0; width: 100%; height: 250px; background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); }

.main-history__text { margin-top: 20px; margin-bottom: 20px; }

.main-history__slider-wrapper { margin-top: 100px; margin-left: auto; width: calc(100% - 25px); }

.main-history__slides-wrapper { padding-top: 10px; }

.main-history__slider-title { font-family: font-serif; font-size: 20px; color: var(--blue-title); }

.main-history__slider-arrows { position: absolute; top: -40px; }

.main-history__slider-arrow { display: none; }

.main-history__card { height: 300px; background-size: cover; }

@media only screen and (min-width: 414px) { .main-history__slider-title { font-size: 36px; }
  .main-history__card { height: 350px; } }

@media only screen and (min-width: 680px) { .main-history__card { height: 375px; }
  .main-history__slider-wrapper { position: absolute; right: 0; margin-top: unset; margin-left: unset; width: calc(100% - 25px); } }

@media only screen and (min-width: 1000px) { .main-history { overflow: hidden; max-height: 1080px; height: calc(var(--vh, 1vh) * 100); }
  .main-history__wrapper { display: flex; height: 100%; padding-bottom: unset; }
  .main-history__slider-wrapper { width: 60%; bottom: 5px; }
  .main-history__slider-title { font-size: 20px; }
  .main-history__text-wrapper { width: 45%; padding-left: 25px; z-index: unset; }
  .main-history__text-wrapper h2 { margin-top: 20%; }
  .main-history__text-wrapper h2::before { left: -25px; top: -10px; }
  .main-history__img-wrapper { width: 55%; position: unset; z-index: unset; height: 60%; }
  .main-history__img-wrapper::before { content: none; }
  .main-history__text { padding-right: 100px; }
  .main-history__slider-arrows { top: unset; bottom: 10px; left: -350px; }
  .main-history__slider-arrow { display: block; width: 55px; height: 55px; background-color: var(--brown-lighter); border-radius: 50%; border: unset; background-position: left center; background-repeat: no-repeat; background-size: 55px 20px; transition: background-color 0.5s; }
  .main-history__slider-arrow--left { left: 170px; background-image: url(../img/arrow-left.svg); }
  .main-history__slider-arrow--right { right: -135px; background-image: url(../img/arrow-right.svg); }
  .main-history__slider-arrow:hover { background-color: var(--brown-light); }
  .main-history__slider-arrow::before { content: ''; position: absolute; top: 50%; left: 49%; transform: translate(-49%, -49%); width: 52px; height: 52px; border: 1px solid #fff; border-radius: 50%; }
  .main-history__card { height: 300px; }
  .main-history__svg-text { z-index: -1; height: 350px; top: 0; right: -65px; } }

@media only screen and (min-width: 1150px) { .main-history__card { height: 300px; }
  .main-history__img-wrapper { height: 50%; }
  .main-history__slider-wrapper { width: 70%; }
  .main-history__slider-arrows { left: -300px; } }

@media only screen and (min-width: 1279px) { .main-history__slider-wrapper { width: 62%; } }

@media only screen and (min-width: 1365px) { .main-history__slider-wrapper { width: 62%; }
  .main-history__card { height: 365px; } }

@media only screen and (min-width: 1600px) { .main-history__text { padding-right: 125px; }
  .main-history__slider-wrapper { width: 70%; }
  .main-history__card { height: 415px; }
  .main-history__svg-text { height: 650px; top: -45px; right: -50px; }
  .main-history__slider-arrows { left: -350px; } }

@media only screen and (min-width: 1919px) { .main-history__card { height: 510px; }
  .main-history__slider-wrapper { width: 77%; }
  .main-history__text-wrapper h2 { margin-top: 110px; } }

.btn { font-size: 16px; text-decoration: none; }

.btn__read-more { position: relative; display: flex; margin-left: 50px; padding-top: 8px; padding-bottom: 8.5px; padding-left: 10px; width: 150px; max-width: 200px; font-family: "FrankRuhlLibre", sans-serif, sans-serif; font-weight: 700; color: var(--brown-light); border-top: var(--border-brown); border-bottom: var(--border-brown); transition: background-color 0.5s; }

.btn__read-more::before { content: ''; position: absolute; left: -50px; top: -3px; background-image: url(../img/btn-left-pattern.svg); background-repeat: no-repeat; width: 56px; height: 45px; background-size: 56px 45px; }

.btn__read-more::after { content: ''; position: absolute; top: -3px; right: -10px; background-image: url(../img/btn-right-pattern.svg); background-repeat: no-repeat; width: 11px; height: 45px; background-size: 11px 45px; }

.btn__read-more--hero { color: var(--white); border-color: var(--white); }

.btn__read-more--hero::before { background-image: url(../img/btn-left-pattern-white.svg); }

.btn__read-more--hero::after { background-image: url(../img/btn-right-pattern-white.svg); }

.btn__bg { display: block; background-color: white; width: 216px; position: absolute; top: -6px; left: -53px; height: 51px; z-index: -1; opacity: .9; }

.btn__anchor { display: block; margin-top: 15px; color: var(--brown-light); text-decoration: none; transition: color .35s; }

.btn__anchor span { border-bottom: 1px dashed currentColor; }

.btn__anchor:hover { color: var(--brown); }

@media only screen and (min-width: 1000px) { .btn__read-more--hero { color: var(--brown-light); border-color: var(--brown-light); }
  .btn__read-more--hero::before { background-image: url(../img/btn-left-pattern.svg); }
  .btn__read-more--hero::after { background-image: url(../img/btn-right-pattern.svg); }
  .btn__bg { display: none; }
  .btn:hover .btn__history-bg { display: block; } }

.card { position: relative; display: flex; flex-direction: column; justify-content: flex-end; padding: 25px; height: 100%; text-decoration: none; background: linear-gradient(180deg, rgba(0, 0, 0, 0.13) 0%, rgba(0, 0, 0, 0.52) 100%); }

.card:hover { background: linear-gradient(180deg, rgba(0, 0, 0, 0.13) 0%, rgba(0, 0, 0, 0.8) 100%); }

.card::after { content: ''; position: absolute; top: 50%; left: 50%; width: 96%; height: 96%; transform: translate(-50%, -50%); border: 1px solid var(--white); }

.card__wrapper { border: 3px solid var(--white); background-size: cover; }

.card__title { font-family: "FrankRuhlLibre", sans-serif; font-size: 24px; line-height: 1.2; color: var(--white); }

.card__text { margin-top: 10px; margin-bottom: 20px; color: var(--white); display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; }

.card__btn { position: relative; z-index: 1; margin-left: auto; text-decoration: none; }

.card__btn::before { content: ''; position: absolute; top: 4px; right: 0; background-image: url(../img/arrow-small.svg); background-repeat: no-repeat; width: 23px; height: 16px; background-size: 23px 16px; }

.card__btn-text { font-family: "FrankRuhlLibre", sans-serif; padding-top: 5px; padding-bottom: 5px; padding-right: 30px; color: var(--white); }

.card__btn { transition: opacity 0.5s; opacity: 0.5; }

.card__btn-text { transition: opacity 0.5s; opacity: 0; }

.card:hover .card__btn-text { opacity: 0.75; }

.card__btn:hover, .card__btn:hover .card__btn-text { opacity: 1; }

.card__slider-list { display: flex; flex-wrap: nowrap; overflow-x: auto; scroll-snap-type: x mandatory; }

.card__slider-item { scroll-snap-align: start; }

.card__slider-item:not(:last-child) { margin-right: 8px; }

.card__slider-item:last-child { display: flex; }

.card__slider-item:last-child::after { content: ''; display: block; width: 20px; }

@media only screen and (min-width: 1000px) { .card { padding: 20px; }
  .card__title { font-size: 20px; }
  .card__text { margin-bottom: 10px; }
  .card__slider-list { flex-wrap: wrap; overflow-x: unset; }
  .card__slider-item { margin-bottom: 5px; } }

@media only screen and (min-width: 1365px) { .card__title { font-size: 24px; } }

@media only screen and (min-width: 1600px) { .card { padding: 25px; }
  .card__title { font-size: 32px; }
  .card__text { margin-bottom: 20px; font-size: 22px; } }

.main-culture__wrapper { position: relative; height: 100%; min-height: 550px; margin-top: 70px; padding-bottom: 400px; }

.main-culture__text-wrapper { width: 100%; padding-left: 25px; }

.main-culture__img-wrapper { position: absolute; bottom: 0; z-index: -1; width: 100%; height: 50%; }

.main-culture__img { position: relative; background-image: url(../img/main-culture.jpg); background-size: cover; height: 100%; }

.main-culture__img::before { content: ''; position: absolute; top: 0; right: 0; left: 0; height: 340px; background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); }

.main-culture__text { padding-right: 45px; }

.main-culture__card-list-wrapper { margin-left: auto; margin-top: 35px; width: calc(100% - 20px); }

.main-culture__card-list-wrapper .card { padding: 15px; }

.main-culture__card-list-wrapper .card__btn::before { top: -19px; }

.main-culture__card-list-wrapper .card::after { height: 90% !important; }

.main-culture__card-list-item { scroll-snap-align: start; }

.main-culture__card-list-item:not(:last-child) { margin-right: 8px; }

.main-culture__card-list-item:first-child, .main-culture__card-list-item:last-child { display: flex; }

.main-culture__card-list-item:last-child::after { content: ''; display: block; width: 20px; }

.main-culture__card { width: 260px; height: 110px; align-items: flex-end; flex-direction: unset; justify-content: space-between; text-decoration: none; }

.main-culture__card-list { display: flex; flex-wrap: nowrap; overflow-x: auto; scroll-snap-type: x mandatory; }

.main-culture__card-title { font-size: 18px; }

.main-culture__info { display: flex; align-items: center; }

.main-culture__icon { width: 25px; height: 25px; fill: var(--white); margin-right: 10px; }

.main-culture__btn-icon { width: 27px; height: 20px; fill: none; stroke: var(--white); stroke-width: 2; transition: opacity 0.5s; opacity: 0; }

.main-culture__card:hover .main-culture__btn-icon { opacity: 1; }

@media only screen and (min-width: 1000px) { .main-culture { max-height: 1080px; /* Use vh as a fallback for browsers that do not support Custom Properties */ height: calc(var(--vh, 1vh) * 100); }
  .main-culture__wrapper { display: flex; position: relative; margin-top: unset; padding-bottom: unset; }
  .main-culture__text-wrapper { width: 45%; margin-top: 15%; padding-left: 25px; }
  .main-culture__text-wrapper h2::before { left: -15px; top: -65px; width: 75px; height: 130px; }
  .main-culture__img-wrapper { position: unset; z-index: unset; bottom: unset; height: unset; margin-left: auto; width: 55%; }
  .main-culture__img { margin-top: 35px; height: 65%; }
  .main-culture__img::before { content: none; }
  .main-culture__card-list-wrapper { position: absolute; left: 0; bottom: 0; width: 100%; margin-left: unset; margin-top: unset; }
  .main-culture__card-list-item { margin-bottom: 10px; }
  .main-culture__card-list-item:not(:last-child) { margin-right: 10px; }
  .main-culture__card-list-item:first-child, .main-culture__card-list-item:last-child { display: unset; }
  .main-culture__card-list-item:last-child::after { content: none; }
  .main-culture__card-list { flex-wrap: wrap; overflow-x: unset; }
  .main-culture__card { width: 185px; height: 90px; }
  .main-culture__icon { width: 20px; height: 20px; }
  .main-culture__svg-text { z-index: -1; width: 350px !important; bottom: 0; right: 0; } }

@media only screen and (min-width: 1150px) { .main-culture__text-wrapper { margin-top: 5%; }
  .main-culture__img { height: 55%; }
  .main-culture__card { width: 230px; height: 100px; } }

@media only screen and (min-width: 1365px) { .main-culture__text-wrapper { margin-top: 10%; width: 35%; }
  .main-culture__img-wrapper { width: 65%; margin-left: unset; }
  .main-culture__img { margin-top: 50px; height: 65%; }
  .main-culture__card-list-wrapper { bottom: -15px; } }

@media only screen and (min-width: 1600px) { .main-culture__text-wrapper { width: 30%; }
  .main-culture__text-wrapper h2::before { width: 120px; height: 150px; }
  .main-culture__img-wrapper { width: 70%; }
  .main-culture__card { width: 260px; height: 110px; }
  .main-culture__card-list-wrapper { bottom: -25px; width: 80%; }
  .main-culture__icon { width: 25px; height: 25px; }
  .main-culture__svg-text { bottom: -15px; right: 25px; width: 650px !important; } }

.main-collections__wrapper { position: relative; min-height: 635px; margin-top: 75px; padding-bottom: 600px; }

.main-collections__img-wrapper { position: absolute; bottom: 250px; z-index: -1; height: 40%; width: 100%; }

.main-collections__img { background-image: url(../img/main-collections.jpg); background-repeat: no-repeat; background-position: bottom center; background-size: contain; height: 100%; }

.main-collections__info-text { padding-bottom: 100px; }

.main-collections__info-img { position: absolute; bottom: 125px; width: calc(100% - 50px); }

.main-collections__img-list { position: absolute; top: 70px; left: -25px; width: calc(100% + 50px); overflow: hidden; }

.main-collections__img-item { display: flex; justify-content: center; }

.main-collections__img-item:first-child { margin-bottom: 5px; }

.main-collections__img-item :nth-child(3n+3) { display: none; }

.main-collections__card-wrapper { margin-right: 5px; background-size: cover; }

.main-collections__card { width: 150px; height: 100px; }

.main-collections__link { position: relative; display: flex; flex-direction: column; align-items: flex-end; margin-left: auto; width: 125px; text-decoration: none; }

.main-collections__link::after { content: ''; position: absolute; top: 13px; right: -5px; z-index: -1; width: 145px; height: 40px; background-color: var(--bone); }

.main-collections__link--second { bottom: -240px; margin-left: unset; margin-right: auto; }

.main-collections__link-title { display: block; font-size: 24px; }

.main-collections__link-btn { display: flex; align-items: center; margin-top: -3px; font-size: 14px; color: var(--blue-title); }

.main-collections__arrow-ico { margin-left: 3px; width: 25px; height: 13px; stroke: var(--blue-title); stroke-width: 2; fill: none; }

@media only screen and (min-width: 680px) { .main-collections__wrapper { min-height: 735px; }
  .main-collections__img-list { position: unset; min-height: 200px; margin-top: 15px; width: 100%; }
  .main-collections__img-item { justify-content: unset; }
  .main-collections__img-item:first-child { position: unset; justify-content: center; }
  .main-collections__img-item :nth-child(3n+3) { display: unset; }
  .main-collections__img-item:last-child { left: unset; justify-content: center; }
  .main-collections__img { background-size: cover; }
  .main-collections__img-wrapper { height: 100%; }
  .main-collections__card { width: 200px; height: 130px; }
  .main-collections__link--second { bottom: unset; } }

@media only screen and (min-width: 1000px) { .main-collections { height: calc(var(--vh, 1vh) * 100); }
  .main-collections__wrapper { height: 100%; margin-top: unset; padding-bottom: unset; overflow: hidden; }
  .main-collections__img-wrapper { bottom: 0; width: 55%; height: 100%; }
  .main-collections__info-img { position: relative; bottom: -15px; width: 100%; }
  .main-collections__info-wrapper { z-index: 1; position: relative; top: 35px; width: 45%; height: 100%; margin-left: auto; }
  .main-collections__info-wrapper h2::before { top: -15px; left: -15px; }
  .main-collections__info-text { position: relative; z-index: -1; padding: 35px 0 35px 35px; background: rgba(255, 255, 255, 0.95); }
  .main-collections__info-text h2 { font-size: 42px; }
  .main-collections__img::before { content: none; }
  .main-collections__link { position: relative; margin-right: 45px; }
  .main-collections__link--second { margin-left: 50px; }
  .main-collections__link::after { width: 145px; height: 40px; }
  .main-collections__img-list { min-height: 200px; }
  .main-collections__card { width: 105px; height: 85px; }
  .main-collections__svg-text { z-index: -1; bottom: 0; right: 0; width: 280px !important; }
  .main-collections .text-ellipsis { -webkit-line-clamp: 5; } }

@media only screen and (min-width: 1150px) { .main-collections__card { width: 130px; } }

@media only screen and (min-width: 1365px) { .main-collections__info-wrapper { top: 10px; }
  .main-collections__info-text { padding: 35px 0 35px 35px; }
  .main-collections__info-text h2 { font-size: 60px; }
  .main-collections__info-img { margin-top: -20px; }
  .main-collections__card { width: 145px; height: 100px; } }

@media only screen and (min-width: 1600px) { .main-collections__info-wrapper { top: 25px; }
  .main-collections__info-text { padding: 40px 80px 40px 40px; }
  .main-collections__img-list { min-height: 285px; }
  .main-collections__info-img { margin-top: -35px; }
  .main-collections__card { width: 185px; height: 125px; }
  .main-collections__link::after { width: 155px; height: 45px; }
  .main-collections__link-title { font-size: 28px; } }

@media only screen and (min-width: 1919px) { .main-collections__info-wrapper { top: 50px; }
  .main-collections__info-text { padding: 50px 0 50px 50px; }
  .main-collections__info-text h2 { font-size: 75px; }
  .main-collections__info-img { margin-top: 0; }
  .main-collections__card { width: 210px; height: 145px; } }

.main-artists__wrapper { position: relative; min-height: 750px; margin-top: 150px; margin-bottom: 400px; }

.main-artists__img-wrapper { position: absolute; bottom: 0; z-index: -1; height: 40%; width: 100%; }

.main-artists__img { background-image: url(../img/main-artists.jpg); background-repeat: no-repeat; background-size: cover; height: 100%; }

.main-artists__cards-wrapper { position: absolute; left: 25px; bottom: -425px; width: calc(100% - 25px); }

.main-artists__card-list { display: flex; flex-wrap: nowrap; overflow-x: auto; scroll-snap-type: x mandatory; }

.main-artists__card-list-item { margin-bottom: 10px; scroll-snap-align: start; }

.main-artists__card-list-item:first-child, .main-artists__card-list-item:last-child { display: flex; }

.main-artists__card-list-item:last-child::after { content: ''; display: block; width: 20px; }

.main-artists__card { width: 250px; height: 370px; text-decoration: none; }

.main-artists__card-title { text-align: center; }

.main-artists__card-text { -webkit-line-clamp: 4; text-align: center; font-size: 14px; line-height: 1.5; }

.main-artists__btn { margin-right: auto; min-height: 32px; }

.main-artists__btn::before { right: unset; top: 8px; }

.main-artists__btn-text { display: none; }

.main-artists__card-list-item:hover .main-artists__btn-text, .artists__list-card-item .main-artists__btn-text { display: block; }

.main-artists__card-list-item:hover .main-artists__btn::before { right: 0; }

.artists__list-card-item .main-artists__btn::before { transform: translateX(-50%); }

.artists__list-card-item:hover .main-artists__btn::before { right: 25px; }

@media only screen and (min-width: 1000px) { .main-artists { max-height: 1080px; height: calc(var(--vh, 1vh) * 100); }
  .main-artists__img-wrapper { width: 50%; height: 62%; right: 0; top: 0; }
  .main-artists__wrapper { display: flex; align-items: center; overflow: hidden; height: 100%; margin-top: 125px; margin-bottom: unset; }
  .main-artists__card-list { flex-wrap: wrap; margin-bottom: -15px; scroll-snap-type: unset; overflow-x: hidden; }
  .main-artists__cards-wrapper { right: 0; left: 25px; bottom: 25px; margin-left: unset; }
  .main-artists__card-list-top-item { scroll-snap-align: unset; margin-bottom: 15px; }
  .main-artists__card-list-top-item:not(:last-child) { margin-right: 15px !important; }
  .main-artists__card-list-top-item:first-child { margin-left: unset !important; }
  .main-artists__card-list-item { scroll-snap-align: unset; margin-bottom: 15px; }
  .main-artists__card-list-item:first-child, .main-artists__card-list-item:last-child { display: unset; }
  .main-artists__card-list-item:last-child::after { content: none; }
  .main-artists__card-list-item:not(:last-child) { margin-right: 10px; }
  .main-artists__card { width: 155px; height: 250px; text-decoration: none; }
  .main-artists__card-title { font-size: 20px; }
  .main-artists__card-text { font-size: 14px; line-height: unset; }
  .main-artists__info-text { position: absolute; top: 0; left: 25px; width: 50%; margin-top: 10%; }
  .main-artists__info-text h2::before { top: -150px; height: 220px; width: 120px; } }

@media only screen and (min-width: 1150px) { .main-artists__img-wrapper { height: 52%; }
  .main-artists__cards-wrapper { left: 50px; }
  .main-artists__info-text { left: 50px; margin-top: 5%; }
  .main-artists__card { width: 185px; height: 300px; } }

@media only screen and (min-width: 1365px) { .main-artists__wrapper { margin-top: 50px; }
  .main-artists__img-wrapper { height: 55%; }
  .main-artists__card { width: 205px; } }

@media only screen and (min-width: 1600px) { .main-artists__cards-wrapper { bottom: 10px; }
  .main-artists__card { width: 250px; height: 365px; }
  .main-artists__card-title { font-size: 30px; }
  .main-artists__card-text { font-size: 16px; } }

@media only screen and (min-width: 1919px) { .main-artists__info-text h2::before { height: 240px; }
  .main-artists__cards-wrapper { bottom: 25px; }
  .main-artists__card { width: 300px; height: 435px; }
  .main-artists__card-title { font-size: 30px; }
  .main-artists__card-text { font-size: 20px; } }

.main-art { overflow: hidden; }

.main-art__wrapper { position: relative; margin-top: 75px; padding-bottom: 235px; }

.main-art__info { position: relative; }

.main-art__info h2::before { left: 55px; width: calc(100% - 55px); }

.main-art__cards-wrapper { margin-top: 35px; margin-left: 20px; }

.main-art__img-wrapper { position: absolute; bottom: 0; margin-top: 25px; width: 100%; }

.main-art__svg-pattern { display: none; }

.main-art__img { background-image: url(../img/main-art.jpg); background-repeat: no-repeat; background-size: cover; height: 200px; }

@media only screen and (min-width: 680px) { .main-art__wrapper { margin-top: 150px; }
  .main-art__img { height: 400px; } }

@media only screen and (min-width: 1000px) { .main-art__wrapper { position: relative; margin-top: 125px; padding-bottom: unset; }
  .main-art__info-wrapper { position: relative; top: 15%; width: 55%; }
  .main-art__info { margin-left: 35px; }
  .main-art__info h2::before { left: -20px; top: -87px; width: 75px; height: 155px; }
  .main-art__cards-wrapper { position: absolute; bottom: 50px; left: 25px; display: block; margin-top: unset; margin-left: unset; width: 100%; opacity: 0; }
  .main-art__img-wrapper { position: relative; bottom: 380px; right: -90%; }
  .main-art__img { height: 350px; }
  .main-art__svg-text { z-index: 1; left: 20px; bottom: 0; width: 200px !important; }
  .main-art__svg-pattern { position: absolute; right: -35px; bottom: -45px; fill: var(--bone); width: 125px; }
  .main-art__category-card { width: 225px !important; height: 120px !important; }
  .main-art__category-card h2 { font-size: 20px !important; text-align: center; } }

@media only screen and (min-width: 1150px) { .main-art__wrapper { margin-top: 250px; } }

@media only screen and (min-width: 1365px) { .main-art__wrapper { max-height: 1080px; height: calc(var(--vh, 1vh) * 100); margin-top: unset; } }

@media only screen and (min-width: 1600px) { .main-art h2::before { top: -250px; width: 100px; height: 315px; }
  .main-art__img { height: 450px; }
  .main-art__svg-text { left: 0; bottom: 28px; width: 385px !important; }
  .main-art__cards-wrapper { bottom: 15px; }
  .main-art__category-card { width: 285px !important; height: 150px !important; }
  .main-art__category-card h2 { font-size: 24px !important; } }

@media only screen and (min-width: 1919px) { .main-art h2::before { height: 335px; }
  .main-art__img { height: 500px; }
  .main-art__cards-wrapper { bottom: 35px; width: 75%; } }

.main-library__wrapper { position: relative; margin-top: 50px; padding-bottom: 450px; height: 100%; min-height: 750px; overflow: hidden; }

.main-library__info h2::before { left: 10px; top: -150px; width: 60px; height: 205px; }

.main-library__img-wrapper { position: absolute; bottom: 0; z-index: -1; width: 100%; height: 50%; }

.main-library__img { background-image: url(../img/main-library.jpg); background-repeat: no-repeat; background-size: cover; height: 400px; }

.main-library__cards-wrapper { margin-left: auto; margin-top: 35px; width: calc(100% - 22px); }

.main-library__new-card-wrapper { height: 100%; }

.main-library__new-card-list-item { margin-right: 10px !important; margin-bottom: 10px !important; }

.main-library__new-card { display: flex; justify-content: center; overflow: hidden; position: relative; padding: 10px; min-width: 150px; width: 150px; background-color: var(--bone) !important; background: unset; border-radius: 5px; }

.main-library__new-card h3 { font-size: 16px; color: var(--brown-light); }

.main-library__new-card:hover { background: unset; }

.main-library__new-card::after { content: unset; }

.main-library__new-card-ico { position: absolute; top: 50%; right: -10px; width: 50px; height: 50px; transform: translateY(-50%); fill: var(--brown-lighter); opacity: .5; }

.main-library__card-list-item { scroll-snap-align: start; }

.main-library__card-list-item:not(:last-child) { margin-right: 10px; }

.main-library__card-list-item:first-child, .main-library__card-list-item:last-child { display: flex; }

.main-library__card-list-item:last-child::after { content: ''; display: block; width: 20px; }

.main-library__card-list { display: flex; flex-wrap: nowrap; overflow-x: auto; scroll-snap-type: x mandatory; }

.main-library__card { align-items: flex-end; flex-direction: unset; justify-content: space-between; padding: 20px; width: 215px; height: 285px; text-decoration: none; }

.main-library__card-info { display: flex; flex-direction: column; height: 100%; }

.main-library__card-title { margin-top: auto; font-size: 22px; }

@media only screen and (min-width: 680px) { .main-library__wrapper { margin-top: 150px; }
  .main-library__cards-wrapper { bottom: 25px; } }

@media only screen and (min-width: 1000px) { .main-library__wrapper { margin-top: 50px; padding-bottom: unset; }
  .main-library__info-wrapper { position: relative; display: flex; flex-direction: column; padding-left: 25px; width: 100%; height: 100%; }
  .main-library__info { position: absolute; margin-top: 5%; padding-left: 25px; max-width: 425px; }
  .main-library__info h2::before { top: -85px; left: -10px; width: 95px; height: 155px; }
  .main-library__img-wrapper { position: absolute; order: -1; right: 25px; bottom: unset; padding: 10px; margin-top: 10px; height: unset; width: 425px; }
  .main-library__img-wrapper::before { content: none; }
  .main-library__img-wrapper::after { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: 1px solid var(--brown-dark); }
  .main-library__img { height: 425px; background-position: bottom; }
  .main-library__cards-wrapper { position: absolute; left: 50px; margin-bottom: 0; }
  .main-library__card { width: 145px; height: 200px; }
  .main-library__card:hover { background: linear-gradient(180deg, rgba(0, 0, 0, 0.13) 0%, rgba(0, 0, 0, 0.7) 100%); }
  .main-library__card-list { flex-wrap: wrap; justify-content: flex-start; overflow: unset; }
  .main-library__card-list-item { margin-bottom: 10px; }
  .main-library__card-list-item:not(:last-child) { margin-right: 10px; }
  .main-library__card-list-item:nth-child(2) { margin-right: unset; }
  .main-library__card-list-item:first-child, .main-library__card-list-item:last-child { display: unset; }
  .main-library__card-list-item:last-child::after { content: none; }
  .main-library__card-title { font-size: 18px; }
  .main-library__svg-text { z-index: -1; top: 50px; right: 250px; width: 350px !important; } }

@media only screen and (min-width: 1150px) { .main-library__info { max-width: 600px; } }

@media only screen and (min-width: 1279px) { .main-library__cards-wrapper { bottom: 15%; } }

@media only screen and (min-width: 1365px) { .main-library { max-height: 1080px; height: calc(var(--vh, 1vh) * 100); margin-top: unset; }
  .main-library__img { height: 475px; background-position: center; }
  .main-library__info { margin-top: 12%; }
  .main-library__cards-wrapper { bottom: 10%; } }

@media only screen and (min-width: 1600px) { .main-library__info { margin-top: 210px; max-width: 750px; }
  .main-library__img-wrapper { right: 75px; margin-top: 100px; width: 475px; }
  .main-library__img { height: 700px; }
  .main-library__cards-wrapper { bottom: 150px; max-width: 850px; }
  .main-library__cards-wrapper::before { width: 875px; height: 160px; }
  .main-library__card-list-item { margin-bottom: 25px; }
  .main-library__card-list-item { margin-right: 25px; }
  .main-library__card-list-item:nth-child(2) { margin-right: 10px; }
  .main-library__card-title { font-size: 22px; }
  .main-library__svg-text { top: 175px; right: -480px; width: 500px !important; } }

@media only screen and (min-width: 1919px) { .main-library__img { height: 850px; }
  .main-library__img-wrapper { width: 650px; }
  .main-library__info h2::before { height: 170px; } }

.history { padding-bottom: 15px; }

.history h2 { padding-left: 25px; }

.history__hero { overflow: hidden; background-image: var(--hero-img-history); }

.history__timeline { padding-top: 25px; padding-left: 10px; padding-right: 10px; }

.history__timeline h2 { padding-left: 5px; font-size: 26px; }

.history__date-wrapper { position: relative; padding-top: 20px; }

.history__date { position: absolute; top: 60px; left: 25px; width: 250px; color: var(--brown-light); transform-origin: 0 0; transform: rotate(90deg); }

.history__info-wrapper { position: relative; margin-left: 10px; padding: 15px; background-color: var(--bone); border-radius: 5px; }

.history__text-wrapper { position: relative; z-index: 1; }

.history__text { margin-top: 10px; margin-bottom: 15px; }

.history__list { position: relative; margin-bottom: 15px; }

.history__list:before { z-index: -1; content: ''; position: absolute; top: 55px; left: 25px; height: calc(100% - 140px); border-left: 2px dashed var(--brown-lighter); }

.history__list:after { content: ''; position: absolute; left: 10px; bottom: 0; height: 6%; width: 25px; background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 60%); }

.history__item { display: flex; padding-top: 30px; }

.history__item:first-child { padding-top: 15px; }

.history__timeline-svg { width: 35px; height: 35px; stroke: #fff; fill: var(--brown-light); background-color: var(--white); }

.history__card-img-wrapper { position: absolute; left: 0; right: 0; bottom: 0; height: 241px; background-repeat: no-repeat; background-size: cover; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; }

.history__card-img-wrapper::before { content: ''; position: absolute; bottom: 141px; left: 0; right: 0; height: 100px; background: linear-gradient(-180deg, #FFF4ED 0%, rgba(255, 247, 242, 0) 100%); }

.history__svg-text { right: -10px; bottom: 10px; }

.history__periods-wrapper { display: none; }

.history__parts-content { margin-bottom: 25px; padding-left: 15px; padding-right: 15px; }

.history__parts-content:last-child { margin-bottom: 50px; }

.history__author { font-style: italic; text-align: right; }

@media only screen and (min-width: 1000px) { .history { margin-top: 25px; padding-bottom: unset; }
  .history__wrapper { display: flex; justify-content: space-between; padding: 35px; }
  .history__timeline-wrapper { width: 70%; }
  .history__timeline-wrapper h2 { padding-left: unset; }
  .history__svg-text { right: unset; left: 25px; bottom: -60px; width: 300px !important; height: 115px !important; }
  .history__timeline { padding-top: 50px; padding-left: unset; }
  .history__timeline h2::before { content: none; }
  .history__date-wrapper { position: unset; display: flex; flex-direction: column; align-items: center; }
  .history__date { position: unset; width: 90px; color: var(--brown-light); background-color: var(--white); font-size: 18px; text-align: center; transform-origin: unset; transform: unset; }
  .history__timeline-svg { width: 45px; height: 45px; }
  .history__info-wrapper { margin-left: 25px; padding: 25px; }
  .history__text-wrapper h3 { font-size: 22px; padding-right: 50px; }
  .history__list::before { left: 45px; }
  .history__list:after { width: 75px; }
  .history__item:first-child { padding-top: 30px; }
  .history__card-img-wrapper { left: unset; width: 350px; height: 100%; border-bottom-left-radius: unset; border-top-right-radius: 5px; }
  .history__card-img-wrapper::before { top: 0; bottom: 0; right: unset; width: 200px; height: unset; background: linear-gradient(90deg, #FFF4ED 0%, rgba(255, 247, 242, 0) 100%); }
  .history__periods-wrapper { display: block; width: 25%; }
  .history__periods { position: sticky; top: 35px; padding: 25px 0 25px 25px; background-color: #FFF7F2; border-radius: 5px; }
  .history__periods h3 { font-size: 30px; text-align: center; }
  .history__periods-list { margin-top: 15px; }
  .history__periods-item { position: relative; text-align: right; transition: opacity .35s; }
  .history__periods-item::after { content: ''; position: absolute; top: 5px; right: 2px; width: 0; height: 0; border-top: 13px solid transparent; border-bottom: 13px solid transparent; border-right: 5px solid var(--brown-light); opacity: 0; }
  .history__periods-item::before { content: ''; position: absolute; right: 0; top: 5px; width: 2px; height: 26px; background-color: var(--brown-light); opacity: 0; }
  .history__periods-link { display: flex; justify-content: flex-end; padding-top: 5px; padding-bottom: 5px; padding-right: 25px; font-size: 18px; font-weight: 500; color: var(--brown-lighter); text-decoration: none; border-top-left-radius: 5px; border-bottom-left-radius: 5px; transition: background-color .35s; }
  .history__periods-link:hover { color: var(--brown-light); background-color: rgba(208, 153, 130, 0.1); }
  .history__periods-item:hover::after, .history__periods-item:hover::before { opacity: 1; }
  .history__parts-content { padding-left: 50px; } }

@media only screen and (min-width: 1600px) { .history__wrapper { padding: 50px; }
  .history__info-wrapper { padding: 35px; }
  .history__timeline h2 { font-size: 32px; }
  .history__timeline-svg { width: 55px; height: 55px; }
  .history__timeline-wrapper { width: 65%; }
  .history__date { width: 115px; font-size: 22px; }
  .history__list::before { top: 75px; left: 56px; height: calc(100% - 80px); }
  .history__item { padding-top: 50px; }
  .history__item:first-child { padding-top: 50px; }
  .history__card-img-wrapper { width: 500px; }
  .history__text-wrapper h3 { font-size: 28px; }
  .history__periods { top: 50px; padding: 35px 0 35px 35px; }
  .history__periods h3 { font-size: 40px; }
  .history__periods-item::after { top: 7px; border-top: 19px solid transparent; border-bottom: 19px solid transparent; border-right: 5px solid var(--brown-light); }
  .history__periods-item::before { top: 7px; height: 38px; }
  .history__periods-link { padding-top: 10px; padding-bottom: 10px; font-size: 24px; } }

.culture__hero { background-image: var(--hero-img-culture); }

.culture__info { position: relative; margin-top: 25px; margin-bottom: 25px; }

.culture__cards { margin-bottom: 50px; width: calc(100% - 25px); }

.culture__cards .card { padding: 15px; }

.culture__cards .card::after { width: 97%; height: 95%; }

.culture__cards-list { margin-top: 25px; margin-left: 15px; }

.culture__svg-text { bottom: 37px; right: 0; left: 10px !important; width: 225px !important; height: 50px !important; }

.culture__text-block:last-child .img__bordered { margin-bottom: unset; }

.culture__img--1 { background-image: var(--img-culture-1); }

.culture__img--2 { background-image: var(--img-culture-2); }

.culture__img--3 { background-image: var(--img-culture-3); }

@media only screen and (min-width: 1000px) { .culture__info { margin-top: 50px; margin-bottom: 50px; padding-left: 50px; }
  .culture__cards { margin-bottom: 50px; width: 100%; }
  .culture__cards-list { margin-top: 50px; margin-left: 35px; margin-bottom: 100px; }
  .culture__svg-text { right: 0; left: 30px !important; bottom: -60px; width: 300px !important; height: 115px !important; }
  .culture__img-wrapper { min-width: 350px; height: 100%; margin-top: 25px; }
  .culture__img-wrapper--1 { margin-right: 30px; }
  .culture__img-wrapper--1::after { left: unset; left: -20px; width: 90%; height: 100%; }
  .culture__img-wrapper--2 { min-width: 450px; margin-left: 30px; }
  .culture__img-wrapper--2::after { left: unset; right: -20px; width: 90%; height: 100%; }
  .culture__img-wrapper--3 { width: 100%; }
  .culture__img-wrapper--3::after { width: calc(100% + 50px); }
  .culture__img--1 { height: 450px; }
  .culture__img--2 { height: 300px; }
  .culture__img--3 { height: 300px; }
  .culture__text-block--flex { display: flex; } }

@media only screen and (min-width: 1365px) { .culture__cards-list { bottom: -250px; } }

@media only screen and (min-width: 1600px) { .culture__cards { max-width: 1200px; }
  .culture__cards-list { width: 100%; bottom: -266px; }
  .culture__svg-text { bottom: 25px; } }

.collections__hero { overflow: hidden; background-image: var(--hero-img-collections); }

.collections__collections { position: relative; }

.collections__exhibitions { position: relative; margin-top: 65px; }

.collections__gallery { position: relative; margin-top: 65px; padding-left: 25px; padding-bottom: 25px; }

.collections__svg-text { left: 10px !important; bottom: 5px; width: 300px; height: 40px !important; stroke-width: 2 !important; }

.collections__slider-wrapper { position: relative; left: -25px; right: -25px; padding-left: 25px; margin-top: 50px; margin-bottom: 25px; width: calc(100% + 50px); background-color: var(--bone); }

.collections__slider { position: relative; top: -25px; }

.collections__slider-list { margin-bottom: 15px; }

.collections__arrows { display: none; }

.collections__card-item:not(:last-child) { margin-right: 5px; }

.collections__card-item:last-child { display: flex; }

.collections__card-item:last-child::after { content: ''; display: block; width: 20px; }

.collections__card-item-link { display: block; text-decoration: none; }

.collections__card-wrapper { background-size: cover; background-position: center; border-color: var(--brown-lighter); }

.collections__card { padding: 15px; min-width: 220px; height: 140px; }

.collections__card-title { padding-bottom: 2px; margin-bottom: 2px; font-family: "FrankRuhlLibre", sans-serif; font-size: 16px; color: #fff; border-bottom: .5px solid #fff; }

.collections__card-text { font-family: "FrankRuhlLibre", sans-serif; color: #fff; font-size: 14px; }

.collections__card-slider-item { flex-direction: column; }

.collections__card-slider-item .card { padding: 15px; }

.collections__gallery-list { margin-top: 25px; }

.collections__gallery-list li:not(:last-child) { margin-right: 2px; }

.collections__gallery-card-wrapper { background-position: center; }

.collections__gallery-card-title { font-size: 16px; }

.collections__svg-pattern { display: none; }

.collections__list { background-color: var(--bone); }

.collections__info-title { color: var(--brown-light); }

.collections__card-text { font-size: 14px; font-weight: normal; line-height: 22px; color: var(--bone); text-align: center; }

@media only screen and (min-width: 1000px) { .collections__collections { position: unset; padding-left: 50px; }
  .collections__exhibitions { padding-left: 50px; }
  .collections__gallery { margin-left: 25px; margin-bottom: 65px; padding-top: 50px; padding-left: 50px; background-color: var(--bone); overflow: hidden; }
  .collections__slider-wrapper { width: calc(100% + 25px); }
  .collections__slider-list { margin-bottom: 20px; }
  .collections__svg-text { left: 40px !important; bottom: 25px !important; width: 900px !important; height: 80px !important; }
  .collections__card { min-width: 350px; height: 250px; padding-left: 25px; padding-right: 25px; }
  .collections__card-title { font-size: 22px; }
  .collections__arrows { position: absolute; bottom: -45px; right: 150px; display: block; }
  .collections__arrow--left { left: -120px; background-image: url(../img/arrow-left.svg); }
  .collections__arrow--right { right: 0; background-image: url(../img/arrow-right.svg); }
  .collections__gallery-slider-wrapper { position: relative; }
  .collections__gallery-list li:not(:last-child) { margin-right: 10px; }
  .collections__gallery-card-wrapper { margin-bottom: 10px; }
  .collections__gallery-card { width: 265px; height: 125px; }
  .collections__gallery-card-title { font-size: 22px; }
  .collections__card-text { font-size: 18px; }
  .collections__card-slider-item .card { padding: 20px; }
  .collections__info-title { font-weight: 600; font-size: 26px; line-height: 33px; margin-bottom: 5px; }
  .collections__card-text { font-size: 16px; margin: 5px 0; } }

@media only screen and (min-width: 1600px) { .collections__card { min-width: 400px; height: 350px; }
  .collections__gallery-card { width: 350px; height: 170px; }
  .collections__svg-text { width: 800px !important; }
  .collections__svg-pattern { position: absolute; bottom: 25px; right: -100px; display: block; width: 300px !important; height: 300px !important; fill: transparent; stroke: var(--white) !important; stroke-width: 15 !important; } }

.artists__hero { background-image: var(--hero-img-artists); overflow-x: hidden; }

.artists__svg-text { bottom: 5px; }

.artists__img--1 { background-image: var(--img-artists-1); }

.artists__img--2 { background-image: var(--img-artists-2); }

.artists__list { padding-top: 20px; padding-bottom: 20px; background-color: var(--bone); }

.artists__list-wrapper { position: relative; z-index: 1; margin-top: 26px; margin-left: 15px; margin-right: 10px; }

.artists__list-card { display: flex; flex-wrap: wrap; padding-top: 20px; }

.artists__list-card-item { width: calc(50% - 5px); margin-right: 5px; margin-bottom: 5px; }

.artists__list-card-item .main-artists__card { padding: 15px; width: 100%; height: 235px; }

.artists__list-card-item .main-artists__card-text { margin-bottom: 5px; }

.artists__list-card-item .card__wrapper { background-position: center; }

.artists__list-card-item h3 { font-size: 18px !important; text-align: center; }

.artists__list-card-item h4 { font-size: 12px; }

.artists__list-filters { display: flex; flex-wrap: wrap; }

.artists__list-filters input { position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0; }

.artists__list-filters label { display: block; padding: 10px 15px; margin-right: 7px; margin-bottom: 7px; font-size: 14px; color: var(--brown-light); background-color: var(--white); border-radius: 25px; text-transform: capitalize; text-decoration: none; appearance: none; cursor: pointer; }

.artists__list-filters label:hover { background-color: var(--brown-light); color: var(--white); }

.artists__list-filters input[type=radio]:checked + label { background-color: var(--brown-light); color: var(--white); }

@media only screen and (min-width: 1000px) { .artists__svg-text { bottom: 10px !important; left: 40px !important; width: 300px !important; height: 115px !important; }
  .artists__info { margin-top: 50px; padding-right: 25px; padding-bottom: 50px; padding-left: 25px; }
  .artists__list { padding-bottom: 40px; margin-bottom: 100px; }
  .artists__list-wrapper { margin-left: 40px; margin-right: unset; }
  .artists__list-card-item { width: unset; margin-right: 10px; margin-bottom: 10px; }
  .artists__list-card-item .main-artists__card { padding: 25px; width: 235px; height: 340px; }
  .artists__list-card-item .main-artists__card-text { margin-bottom: 15px; }
  .artists__list-card-item h3 { font-size: 24px !important; }
  .artists__list-card-item h4 { font-size: 18px; }
  .artists__list-card-item .main-artists__btn-text { font-size: 18px; font-weight: 400; }
  .artists__content { display: flex; }
  .artists__content .main-artists__card-list-item:first-child { margin-left: 0; }
  .artists__slider-wrapper { left: unset; right: unset; order: 1; margin-top: -50px; margin-left: 50px; margin-right: -25px; width: 550px !important; height: 100%; }
  .artists__text-wrapper--first { width: calc(100% - 575px); }
  .artists__img--1 { height: 325px; }
  .artists__img--2 { height: 400px; }
  .artists__img-wrapper { min-width: 400px; height: 100%; margin-top: 25px; }
  .artists__img-wrapper--1 { margin-right: 35px; }
  .artists__img-wrapper--2 { margin-left: 35px; }
  .artists__list-filters a { padding: 11.5px 25px; font-size: 16px; } }

@media only screen and (min-width: 1600px) { .artists__svg-text { bottom: 40px; }
  .artists__slider-wrapper { width: 650px !important; }
  .artists__text-wrapper--first { width: calc(100% - 615px); }
  .artists__img-wrapper { min-width: 500px; } }

@media only screen and (min-width: 1919px) { .artists__slider-wrapper { margin-top: -131px; width: 800px !important; } }

.art { padding-bottom: 25px; }

.art__hero { background-image: var(--hero-img-art); overflow-x: hidden; width: 100%; }

.art__header { width: 100%; overflow-x: hidden; background-repeat: no-repeat; background-position: center; }

.art__header--painters { background-image: url(../img/art-painters1-max.jpg); }

.art__header--photographers { background-image: url(../img/artist-works/zarema-yaliboylu/bakhchisarai/6.jpg); }

.art__header--ceramic { background-image: url(../img/artist-works/mamut-churlu/ceramics/1.jpg); }

.art__header--textile { background-image: url(../img/artist-works/mamut-churlu/textile/6.jpg); }

.art__header--embro { background-image: url(../img/artist-works/alime-gusenova/2.jpg); }

.art__header--wood { background-image: url(../img/artist-works/emin-taymazov/3.jpg); }

.art__header--potter { background-image: url(../img/art-potters.jpg); }

.art__header--sculpture { background-image: url(../img/art-sculpture.jpg); }

.art__header--dancers { background-image: url(../img/art-dancers.jpg); }

.art__header--singers { background-image: url(../img/art-singers.jpg); }

.art__header--design { background-image: url(../img/art-designers.jpg); }

.art__header--jewelers { background-image: url(../img/art-jewelers.jpg); }

.art__header--musician { background-image: url(../img/artist-works/103-rustem-kurtmemetov/video/video-2.jpg); }

.art__svg-text { left: 20px !important; width: 175px !important; height: 80px !important; bottom: 20px !important; }

.art__img--1 { background-image: var(--img-art-1); }

.art__img--2 { background-image: var(--img-art-2); }

.art__card-list-wrapper { margin: 25px -25px; padding-left: 15px; padding-top: 25px; padding-bottom: 25px; background-color: var(--bone); }

.art__card-item:last-child .art__card:last-child { display: none; }

.art__card { display: block; width: 145px; height: 145px; padding: 5px; background-color: var(--white); text-decoration: none; }

.art__card:first-child { margin-bottom: 15px; }

.art__card h3 { font-weight: 400; color: var(--brown-light); margin-top: 5px; }

.art__card-info { display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%; height: 100%; border: 1px solid var(--brown-light); }

.art__svg { width: 35px; height: 35px; fill: var(--brown-light); }

.art__category-card { display: flex; align-items: center; flex-direction: column; justify-content: flex-end; padding: 15px; width: 250px; height: 125px; }

.art__category-card .main-artists__btn-text { font-weight: 600; }

.art__category-card:hover .main-artists__btn-text { display: block; }

.art__category-card:hover .main-artists__btn::before { right: 0; }

.art__category-card-title h2 { margin-bottom: 5px; color: var(--bone); font-family: "FrankRuhlLibre", sans-serif; font-size: 20px; text-transform: uppercase; }

@media only screen and (min-width: 1000px) { .art { padding-left: 50px; }
  .art__content--img { display: flex; }
  .art__img-wrapper { min-width: 350px; height: 100%; margin-top: 25px; }
  .art__img-wrapper--1 { margin-left: 50px; order: 1; }
  .art__img-wrapper--2 { margin-right: 50px; }
  .art__img--1 { height: 450px; }
  .art__img--2 { height: 350px; }
  .art__svg-text { left: 35px !important; bottom: 35px !important; width: 185px !important; height: 75px !important; }
  .art__card-list-wrapper { margin-left: -25px; margin-right: -50px; padding: 25px 10px 25px 25px; width: calc(100% + 170px); }
  .art__card { opacity: 0.75; }
  .art__card:hover { opacity: 1; }
  .art__card h3 { font-size: 16px; }
  .art__category-card { padding: 25px; width: 335px; height: 175px; }
  .art__category-card-title h2 { font-size: 26px; }
  .art__hero-category-card { width: 300px !important; } }

@media only screen and (min-width: 1600px) { .art__list { padding-right: 150px; } }

@media only screen and (min-width: 1919px) { .art__card { width: 200px; height: 200px; }
  .art__card h3 { font-size: 20px; }
  .art__img-wrapper { min-width: 500px; }
  .art__img--1 { height: 550px; }
  .art__img--2 { height: 450px; }
  .art__card-list-wrapper { margin-top: 50px; margin-bottom: 50px; width: calc(100% + 273px); }
  .art__card-item :not(:last-child) { margin-right: 10px; }
  .art__svg { width: 50px; height: 50px; } }

.contacts__section { margin-top: 25px; }

.contacts__hero { background-color: var(--bone); min-height: 225px; }

.contacts__hero::before { content: ""; z-index: -1; position: absolute; bottom: -50px; left: auto; width: 120px; height: 150px; background-size: cover; background-image: url(../img/bio-pattern.png); }

.contacts__hero::after { background: linear-gradient(180deg, #D09A82 0%, rgba(208, 154, 130, 0) 100%); }

.contacts__title { position: absolute; bottom: 1rem; left: 1.5rem; font-family: "FrankRuhlLibre", sans-serif; font-weight: bold; font-size: 35px; line-height: 45px; text-transform: uppercase; color: var(--blue-title); }

.contacts-inner__title { font-family: "GillSans", sans-serif; font-style: normal; font-weight: 600; font-size: 20px; line-height: 24px; color: #000; margin-bottom: 15px; }

.contacts__address a { font-style: normal; font-weight: 600; font-size: 18px; line-height: 21px; text-decoration: none; color: var(--brown-light); }

.contacts__icon-bg { display: inline-flex; justify-content: center; align-items: center; width: 35px; height: 35px; background: var(--bone); border-radius: 5px; margin-right: 10px; margin-bottom: 15px; }

.contacts__icon { width: 15px; height: 15px; fill: var(--brown-light); }

.contacts__form { margin-top: 50px; margin-bottom: 25px; }

.contacts__form input { background: var(--white); border: 2px solid #E4E4E4; box-sizing: border-box; border-radius: 5px; padding: 15px; max-width: 450px; width: 100%; margin-bottom: 10px; margin-top: 5px; font-size: 14px; }

.contacts__form input[type='submit'] { background: var(--brown-light); border-radius: 5px; font-weight: 600; font-size: 18px; line-height: 21px; color: var(--white); font-family: "GillSans", sans-serif; border: none; margin-top: 0; }

.contacts__form textarea { background: var(--white); border: 2px solid #E4E4E4; box-sizing: border-box; border-radius: 5px; max-width: 900px; width: 100%; resize: none; padding: 15px; margin-bottom: 15px; margin-top: 5px; font-family: "GillSans", sans-serif; font-size: 16px; line-height: 18px; height: 150px; }

.form__input { border: 1px solid transparent; }

.form__input:focus { border-color: var(--green); }

.form__input._error { border-color: var(--red); }

@media only screen and (min-width: 1000px) { .contacts__section { padding: 0 28px; margin-top: 55px; }
  .contacts__hero { height: 285px; }
  .contacts__hero::after { background: none; }
  .contacts__hero::before { left: 35%; width: 345px; height: 345px; bottom: -35px; }
  .contacts__title { font-size: 75px; line-height: 97px; }
  .contacts-inner__title { font-size: 28px; line-height: 33px; }
  .contacts__form { margin-bottom: 75px; }
  .contacts__form input[type="submit"] { max-width: 250px; font-size: 20px; }
  .contacts__form textarea { height: 250px; }
  .contacts__address a { font-size: 22px; line-height: 26px; }
  .contacts__icon-bg { width: 45px; height: 45px; }
  .contacts__icon { width: 25px; height: 25px; } }

.art-paint h2 { margin-left: 15px; }

.art-paint__list { margin-bottom: 25px; }

.art-paint__list-item { margin-top: 25px; }

.art-paint__list-item-wrap { padding: 25px 0; background-color: var(--bone); }

.art-paint__photo { margin-left: auto; margin-right: auto; width: 175px; height: 250px !important; }

.art-paint__works { z-index: 1; position: relative; display: flex; flex-direction: column; justify-content: flex-end; margin-top: 15px; margin-left: 10px; margin-right: 10px; }

.art-paint__works-list { display: flex; flex-wrap: wrap; margin-top: 5px; }

.art-paint__works-item { margin-bottom: unset !important; }

.art-paint__works-item:last-child { margin-right: unset; }

.art-paint__works-item .gallery__link { height: 100px; }

.art-paint__photo img, .art-paint__works-item img { border-color: var(--white); }

.art-paint__works-title { display: flex; align-items: center; justify-content: space-between; }

.art-paint__works-title h3 { font-size: 18px; text-transform: uppercase; font-family: "FrankRuhlLibre", sans-serif; color: var(--blue-title); }

.art-paint__works-btn { display: block !important; cursor: pointer; opacity: 1; font-size: 14px; }

.art-paint__works-btn::before { width: 18px; background-size: 18px 16px; }

@media only screen and (min-width: 1000px) { .art-paint__list { margin-bottom: 100px; }
  .art-paint__list-item { margin-top: 150px; }
  .art-paint__list-item-wrap { position: relative; display: flex; padding: 0 0 35px 35px; height: 400px; }
  .art-paint__list-item-wrap::before { content: ''; z-index: 1; position: absolute; top: -25px; right: 15px; width: 345px; height: 345px; background-image: url(../img/bio-pattern.png); }
  .art-paint__photo { margin-left: unset; margin-right: unset; margin-top: -85px; min-width: 300px; width: 300px; height: 450px !important; }
  .art-paint__works { margin-top: unset; margin-left: 25px; margin-right: unset; }
  .art-paint__works-list { flex-wrap: unset; margin-top: unset; }
  .art-paint__works-item { width: 175px !important; }
  .art-paint__works-item .gallery__link { height: 150px !important; }
  .art-paint__works-title h3 { font-size: 35px; }
  .art-paint__works-btn { margin-left: 25px; font-size: 22px; }
  .art-paint__works-btn::before { width: 23px; background-size: 23px 16px; } }

@media only screen and (min-width: 1600px) { .art-paint__list-item-wrap::before { right: 150px; }
  .art-paint__works { margin-left: 35px; }
  .art-paint__works-item { width: 245px !important; }
  .art-paint__works-item .gallery__link { height: 200px !important; } }

.biography__header { z-index: -1; position: relative; display: flex; align-items: flex-end; justify-content: center; padding-left: 15px; padding-right: 15px; padding-bottom: 15px; height: 200px; background-color: var(--bone); overflow: hidden; }

.biography__header h2 { font-size: 25px; font-family: "FrankRuhlLibre", sans-serif; color: var(--blue-title); text-align: center; }

.biography__header::before { content: ''; z-index: -1; position: absolute; top: 50%; left: 50%; width: 345px; height: 345px; background-image: url(../img/bio-pattern.png); transform: translate(-50%, -50%); }

.biography__header::after { content: ''; position: absolute; top: 0; width: 100%; height: 150px; background: linear-gradient(0deg, rgba(188, 121, 102, 0) 0%, rgba(188, 121, 102, 0.5) 100%); }

.biography__visual { order: -1; width: 90%; margin-left: auto; margin-right: auto; }

.biography__photo { width: 100%; height: 400px; background-color: var(--white); border: 10px solid var(--white); box-shadow: 0px 10px 50px rgba(188, 121, 102, 0.2); }

.biography__photo img { height: 100%; object-fit: cover; }

.biography__works { display: block; margin-top: 30px; margin-bottom: 30px; padding: 15px; background-color: var(--bone); text-decoration: none; font-size: 14px; font-weight: 600; color: var(--gray); }

.biography__works:hover .biography__works-btn { opacity: 1; }

.biography__works-list { display: flex; height: 60px; margin-top: 10px; }

.biography__works-title { display: flex; justify-content: space-between; }

.biography__works-item { width: calc((100% - 10px) / 3); height: 100%; border: 5px solid var(--white); }

.biography__works-item:not(:last-child) { margin-right: 5px; }

.biography__works-item img { object-fit: cover; height: 100%; width: 100%; }

.biography__works-btn { display: inline-flex; align-items: center; padding-right: 25px; color: var(--brown-light); }

.biography__works-btn::before { top: 50%; background-image: url(../img/arrow-small-brown.svg); transform: translateY(-50%); }

.biography__info-wrapper { display: flex; flex-direction: column; }

.biography__info { margin-left: 15px; margin-right: 15px; }

.biography__info h3, .biography__part-title { font-size: 18px; font-weight: 600; color: var(--gray); }

.biography__part-list { margin-top: 15px; margin-bottom: 35px; padding-left: 25px; list-style-type: decimal; font-size: 16px; font-family: "GillSans", sans-serif; color: var(--brown-light); }

.biography__part-list-item:not(:first-child) { margin-top: 5px; }

.biography__part-list-link { text-decoration: none; color: var(--gray); }

.biography__part-list-link:hover { color: var(--brown-light); border-bottom: 1px solid currentColor; }

.biography__part-title { margin-top: 15px; color: var(--brown-light); border-bottom: 1px dashed currentColor; }

section .biography__text { font-size: 16px; margin-top: 15px; }

.biography__artist-works { margin-top: 25px; padding: 15px; background-color: var(--bone); }

.biography__artist-works h2 { font-size: 25px; margin-bottom: 10px; }

.biography__artist-works h2:not(:first-child) { margin-top: 75px; }

.biography__soc-link { text-decoration: none; color: var(--brown-light); }

.biography__soc-link:hover { color: var(--brown-lighter); }

@media only screen and (min-width: 360px) { .biography__header { height: 150px; }
  .biography__photo { width: 100%; height: 500px; } }

@media only screen and (min-width: 1000px) { .biography__header { justify-content: unset; padding-left: 40px; padding-bottom: 25px; padding-right: unset; height: 250px; }
  .biography__header h2 { font-size: 50px; text-align: unset; }
  .biography__header::after { content: none; }
  .biography__info-wrapper { display: flex; flex-direction: unset; }
  .biography__info { margin-top: 25px; margin-left: 40px; margin-right: unset; max-width: 700px; }
  .biography__info h3, .biography__part-title { font-size: 22px; }
  .biography__part-list { font-size: 18px; }
  .biography__part-title { margin-top: 25px; }
  .biography__visual { order: unset; margin-top: -125px; margin-left: 35px; margin-right: unset; max-width: 350px; width: 350px; }
  .biography__photo { height: 500px; }
  .biography__photo-wrapper { position: sticky; top: 25px; }
  .biography__works { margin-bottom: unset; font-size: 18px; }
  .biography__works-list { height: 75px; }
  section .biography__text { font-size: 18px; margin-top: 15px; }
  .biography__artist-works { margin-top: 50px; padding: 40px; }
  .biography__artist-works h2 { font-size: 35px; margin-bottom: 25px; } }

@media only screen and (min-width: 1600px) { .biography__info { max-width: 900px; }
  .biography__visual { max-width: 450px; width: 450px; }
  .biography__photo { height: 600px; }
  .biography__works-list { height: 100px; }
  .biography__info h3, .biography__part-title { font-size: 26px; }
  .biography__part-list, section .biography__text { font-size: 20px; } }

.library { margin-bottom: 25px; }

.library__hero { background-image: var(--hero-img-library); overflow-x: hidden; width: 100%; }

.library__img--2 { background-image: var(--img-library-2); }

.library__go-library { margin: 25px -25px; }

.library__info-wrapper { display: flex; flex-direction: column; align-items: center; margin-left: 15px; margin-right: 15px; margin-bottom: 50px; padding: 15px 17px; background-color: var(--bone); border-radius: 10px; }

.library__info-logo { width: 175px; height: 175px; }

.library__info { display: block; margin-top: 10px; margin-bottom: 10px; color: var(--gray); font-family: "FrankRuhlLibre", sans-serif; font-weight: 400; text-align: center; }

.library__btn { display: block; padding: 10px 25px; margin-left: auto; margin-right: auto; width: 255px; color: var(--white); background-color: var(--brown-light); border-radius: 25px; font-size: 18px; text-align: center; text-decoration: none; }

.library__svg-text { left: 20px !important; bottom: 20px !important; }

.library__slider-wrapper { margin-top: 15px; }

.library__slider-wrapper .collections__slider { position: unset; }

.library__slider-title { padding-top: 10px; margin-bottom: 10px; font-family: "FrankRuhlLibre", sans-serif; color: var(--blue-title); }

@media only screen and (min-width: 1000px) { .library { margin-top: unset; margin-bottom: unset; padding: 50px; }
  .library__content { display: flex; }
  .library__content .main-library__card-list-item:first-child { margin-left: unset; }
  .library__content .main-library__card-list-item:last-child { margin-right: 10px; }
  .library__content .main-library__card-list-item:nth-child(2) { margin-right: 10px; }
  .library__content--first { width: calc(100% - 425px); }
  .library__slider-title { padding-top: unset; margin-top: 10px; margin-bottom: 15px; font-size: 34px; }
  .library__slider-wrapper { left: unset; right: unset; order: 1; margin-top: -50px; margin-left: 50px; margin-right: -50px; height: 100%; width: 400px !important; background-color: unset !important; }
  .library__slider-wrapper .collections__slider { top: 0; }
  .library__img-wrapper--2 { min-width: 350px; height: 100%; margin-top: 25px; margin-right: 35px; }
  .library__img--1 { height: 280px; background-size: contain; }
  .library__go-library { margin-left: unset; margin-right: unset; }
  .library__info-wrapper { flex-direction: unset; margin: 100px 0; padding: 15px; width: 100%; }
  .library__info { margin-left: 25px; margin-right: 50px; font-size: 28px; font-weight: 500; text-align: left; }
  .library__btn { width: 400px; font-size: 25px; border-radius: 50px; transition: background-color .35s; }
  .library__btn:hover { background-color: var(--brown); }
  .library__svg-text { right: 0; left: 40px !important; bottom: -60px; width: 300px !important; height: 100px !important; } }

@media only screen and (min-width: 1600px) { .library__content--first { width: calc(100% - 775px); }
  .library__content .main-library__card-list-item:last-child { margin-right: 25px; }
  .library__content .main-library__card-list-item:nth-child(2) { margin-right: 25px; }
  .library__img-wrapper--2 { min-width: 599px; margin-right: 50px; }
  .library__img--2 { height: 425px; }
  .library__go-library { margin-top: 100px; margin-bottom: 100px; }
  .library__info-wrapper { padding: 35px; }
  .library__card { width: 200px !important; height: 290px !important; }
  .library .main-library__card-list-item { margin-bottom: 10px; }
  .library .main-library__card-list-item:not(:last-child) { margin-right: 10px !important; } }

@media only screen and (min-width: 1919px) { .library__slider-wrapper { width: 750px !important; }
  .library__slider-wrapper .main-library__new-card { padding: 20px; min-width: 225px; width: 225px; }
  .library__slider-wrapper .main-library__new-card h3 { font-size: 24px; } }

.gallery__list { display: flex; flex-wrap: wrap; }

.gallery__item { margin-bottom: 10px; width: calc((100% - 10px) / 2); font-family: "FrankRuhlLibre", sans-serif; }

.gallery__item:nth-child(odd) { margin-right: 10px; }

.gallery__item, .gallery__item-collection:hover .gallery__item-title { color: var(--brown-light); }

.gallery__item-title, .gallery__item-additional, .gallery__item-place { display: block; }

.gallery__item-title { color: var(--gray-dark); margin-top: 9px; font-weight: 500; font-size: 14px; }

.gallery__item-place, .gallery__item-descript, .gallery__item-additional span { font-size: 14px; color: var(--gray); }

.gallery__item-additional { margin-top: 2px; }

.gallery__item-additional > span:not(:first-child)::before { content: '•'; margin-left: 5px; margin-right: 5px; color: var(--brown-lighter); font-size: 10px; }

.gallery__item-place { margin-top: 9px; padding-top: 5px; border-top: 1px dashed var(--brown-light); }

.gallery__item-collection { margin-bottom: 10px; width: calc((100% - 10px) / 2); font-family: "FrankRuhlLibre", sans-serif; }

.gallery__item-collection:nth-child(odd) { margin-right: 10px; }

.gallery__link { position: relative; display: block; height: 125px; }

.gallery__link::after { content: ''; position: absolute; top: 7px; right: 7px; left: 7px; bottom: 7px; border: 1px solid var(--bone); }

.gallery__img { height: 100%; object-fit: cover; border: 5px solid var(--brown-lighter); }

.gallery__audio { width: 100%; }

@media only screen and (min-width: 1000px) { .gallery__item { margin-right: 15px; margin-bottom: 25px; width: 245px; }
  .gallery__item-title { font-size: 16px; }
  .gallery__link { height: 200px; }
  .gallery__item-collection { margin-right: 15px; margin-bottom: 25px; width: 336px; } }

@media only screen and (min-width: 1600px) { .gallery__list { max-width: 1400px; } }

.pswp img { max-width: none; object-fit: contain; }

.about-project__img { display: block; background-image: url("../img/about-project.jpg"); background-size: cover; border-radius: 5px; width: 100%; height: 112px; margin-bottom: 25px; }

@media only screen and (min-width: 414px) { .about-project__img { height: 160px; } }

@media only screen and (min-width: 680px) { .about-project__img { height: 250px; } }

@media only screen and (min-width: 1000px) { .about-project__img { max-width: 1000px; height: 316px; } }

.privacy__section { margin-left: 1rem; }

.privacy-item { font-size: 16px; line-height: 22px; color: var(--brown); }

.privacy-list__inner { padding-left: 15px; }

@media only screen and (min-width: 1000px) { .privacy-item { font-size: 22px; line-height: 32px; }
  .privacy-list__inner { padding-left: 25px; } }

.articles__wrap { margin-top: 50px; margin-bottom: 50px; }

.article__header { height: 150px; }

.article__wrap { margin-left: 15px; margin-right: 15px; }

.article__title { margin-top: 25px; font-size: 25px; line-height: 30px; color: var(--brown-light) !important; }

.article__sub-title { color: var(--gray); font-size: 22px; }

.article__text-img-wrap { width: 25%; margin-left: auto; margin-right: auto; }

.article__text-img-wrap p { text-align: center; }

.article__text-img-wrap--1 { width: 30%; }

.article__text-img-wrap--2 { width: 50%; }

.article__img-wrap { margin-left: -15px; margin-right: -15px; margin-bottom: 50px; height: 250px; }

.article__img { width: 100%; height: 100%; object-fit: cover; }

.article__author { margin-top: 25px; margin-bottom: 25px; }

.article__author-link { display: inline-flex; align-items: center; text-decoration: none; color: var(--gray); }

.article__author-link:hover .article__author-info-name { color: var(--brown-light); }

.article__author-info { margin-left: 10px; }

.article__author-info span { display: block; }

.article__author-info-title { font-size: 14px; }

.article__author-info-name { font-size: 16px; font-weight: 600; color: var(--gray-dark); }

.article__author-img-wrap { width: 50px; min-width: 50px; height: 50px; min-height: 50px; border-radius: 50%; border: 3px solid var(--white); box-shadow: 0px 10px 50px rgba(188, 121, 102, 0.2); background-color: var(--brown-light); overflow: hidden; }

.article__other-title, .article__other-item-title { font-family: "FrankRuhlLibre", sans-serif; font-weight: 600; }

.article__other-wrap { margin-top: 50px; margin-bottom: 50px; }

.article__other-list { display: flex; flex-wrap: wrap; margin-top: 15px; }

.article__other-item:not(:first-child) { margin-top: 35px; }

.article__other-link { display: block; }

.article__other-link:hover .article__other-item-title { color: currentColor; }

.article__other-title { font-size: 34px; color: var(--brown-light); }

.article__other-img-wrap { height: 175px; }

.article__other-item-title { margin-top: 10px; color: var(--gray); font-size: 18px; }

.article__other-item-author { display: inline-block; margin-top: 5px; padding: 5px 10px; font-size: 14px; color: var(--gray); border: 1px solid var(--gray-light); border-radius: 5px; }

.article__other-item-author:hover { color: currentColor; }

@media only screen and (min-width: 1000px) { .article__wrap { margin-left: 45px; margin-right: unset; width: 950px; }
  .article__header { display: none; }
  .article__img-wrap { margin-left: unset; margin-right: unset; height: 450px; }
  .article__title { margin-top: 110px; font-size: 45px; line-height: 55px; }
  .article__author-info-title { font-size: 18px; }
  .article__author-info-name { font-size: 22px; }
  .article__author-img-wrap { width: 75px; min-width: 75px; height: 75px; min-height: 75px; border: 5px solid var(--white); }
  .article__author-info { margin-left: 15px; }
  .article__author { margin-top: 35px; margin-bottom: 35px; }
  .article__other-wrap { margin-top: 100px; }
  .article__other-img-wrap { height: 250px; }
  .article__other-item { margin-bottom: 35px; width: 450px; }
  .article__other-item:nth-child(2n) { margin-left: 50px; }
  .article__other-item:not(:first-child) { margin-top: unset; }
  .article__other-item-title { margin-top: 15px; font-size: 34px; } }
