.site-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;

    display: flex;
    align-items: flex-end;

    &__content-wrapper {
        width: 100%;

        transform: translate3d(0, 0, 0);
        transition: transform 0.5s ease-in-out;
        will-change: transform;
    }

    &__content-wrapper--hided {
        transform: translate3d(0, -100px, 0);
    }

    &__content-wrapper--shrink {
        background-color: var(--white);
    }

    // toggle button
    &__nav-toggler {
        position: absolute;
        top: 25px;
        right: 10px;
        z-index: 2;

        display: flex;
        align-items: center;
        width: 60px;
        height: 60px;

        font-size: 24px;
        line-height: 24px;
        text-transform: uppercase;

        background-color: transparent;

        touch-action: manipulation;

        .shape {
            position: absolute;
            left: 10px;
            top: 50%;

            width: 40px;
            height: 20px;

            color: var(--bone);
            transform: translateY(-50%);
        }

        .line {
            display: block;
            width: 65%;
            height: 3px;
            margin: 0 0 8px;
            margin-left: auto;
            margin-right: auto;

            background-color: currentColor;
            transform-origin: right center;
            transform: scaleX(0);
            will-change: transform;
        }

        .line:nth-child(2) {
            width: 100%;
        }

        .close {
            position: absolute;
            left: 50%;
            top: 50%;

            width: 35px;
            height: 35px;

            color: #fff;

            transform: translate(-50%, -50%) rotate(0) scale(0);

            &::before,
            &::after {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;

                background: currentColor;
            }

            &::before {
                width: 3px;
                height: 25px;
                margin: -12px 0 0 -1px;
            }

            &::after {
                width: 25px;
                height: 3px;
                margin: -1px 0 0 -12px;
            }
        }
    }

    &__logo {
        position: absolute;
        z-index: 2;
        top: 25px;
        left: 25px;

        display: flex;
        align-items: center;
        width: 65px;
        height: 65px;

        text-decoration: none;
    }

    &__logo-text {
        color: var(--white);

        font-size: 14px;
        line-height: 1.4;
        margin-left: 10px;

        opacity: 0;
        visibility: hidden;
    }

    &__nav-wrapper {
        height: 100px;

        opacity: 0;
        pointer-events: none;
    }

    &__nav-toggler--toggled~&__nav-wrapper {
        height: unset;

        opacity: 1;
        pointer-events: auto;
    }

    ////////////////////
    // overlay
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;

        width: 100vw;
        height: 100vh;

        background-color: var(--brown-dark);
        transform: scaleX(0);
        transform-origin: left center;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
            background: url(../img/nav-bg.png) no-repeat;
            background-size: cover;
        }
    }

    &__nav {
        display: flex;
        flex-wrap: wrap;
        height: 100vh;
    }


    &__content-wrapper--shrink &__nav-wrapper {
        height: 75px;
    }

    &__content-wrapper--shrink &__logo {
        top: 5px;
        left: 10px;
    }

    &__content-wrapper--shrink &__nav-toggler {
        top: 5px;

        .line {
            background-color: var(--brown-light);
        }
    }
}

@include rmin($tablet) {
    .site-header {
        &__logo {
            width: 100px;
            height: 100px;
        }

        &__nav-toggler {
            width: 80px;
            height: 80px;

            .shape {
                width: 60px;
            }

            .line {
                margin-bottom: 12px;
                height: 4px;
            }

            .close {
                width: 60px;
                height: 60px;

                &::after {
                    width: 45px;
                    height: 4px;
                    margin-left: -22px;
                }

                &::before {
                    margin-top: -22px;
                    width: 4px;
                    height: 45px;
                }
            }
        }

        &__content-wrapper--shrink &__nav-wrapper {
            height: 110px;
        }

        &__content-wrapper--hided {
            transform: translate3d(0, -110px, 0);
        }

        &__logo-text {
            margin-left: 20px;
            font-size: 20px;
        }
    }
}

@include rmin($desktop) {
    .site-header {
        position: fixed;
        z-index: unset;
        top: 0;
        left: unset;
        right: unset;

        &__content-wrapper {
            display: block;

            height: 100vh;
            max-width: 200px;

            padding: 0 20px;

            transform: unset;
        }

        &__logo {
            position: unset;
            margin-top: 50px;

            margin-left: 15px;
            margin-right: 15px;
            width: 125px;
            height: 125px;

            &:hover {
                opacity: 0.8 !important;
            }
        }

        &__logo-text {
            display: none;
        }

        &__container {
            min-height: unset;
        }

        &__nav-toggler {
            display: none;
        }

        &__nav-wrapper {
            display: block;
            height: unset;
            opacity: unset;
            pointer-events: unset;
        }
    }
}

@include rmin($desktop-s) {
    .site-header {
        &__logo {
            margin-left: unset;
            margin-right: unset;
            width: auto;
            height: auto;
        }
    }
}

@include rmin($desktop-md) {
    .site-header {
        &__content-wrapper {
            max-width: 255px;
            padding: 0 30px;
        }
    }
}
