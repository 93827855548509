.main-art {
    overflow: hidden;

    &__wrapper {
        position: relative;
        margin-top: 75px;
        padding-bottom: 235px;
    }

    &__info {
        position: relative;

        h2::before {
            left: 55px;
            width: calc(100% - 55px);
        }
    }

    &__cards-wrapper {
        margin-top: 35px;
        margin-left: 20px;
    }

    &__img-wrapper {
        position: absolute;
        bottom: 0;
        margin-top: 25px;
        width: 100%;
    }

    &__svg-pattern {
        display: none;
    }

    &__img {
        background-image: url(../img/main-art.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 200px;
    }
}

@include rmin($tablet) {
    .main-art {
        &__wrapper {
            margin-top: 150px;
        }

        &__img {
            height: 400px;
        }
    }
}

@include rmin($desktop) {
    .main-art {
        &__wrapper {
            position: relative;
            margin-top: 125px;
            padding-bottom: unset;
        }

        &__info-wrapper {
            position: relative;
            top: 15%;

            width: 55%;
        }

        &__info {
            margin-left: 35px;

            h2 {
                &::before {
                    left: -20px;
                    top: -87px;

                    width: 75px;
                    height: 155px;
                }
            }
        }

        &__cards-wrapper {
            position: absolute;
            bottom: 50px;
            left: 25px;

            display: block;
            margin-top: unset;
            margin-left: unset;
            width: 100%;

            opacity: 0;
        }

        &__img-wrapper {
            position: relative;
            bottom: 380px;
            right: -90%;
        }

        &__img {
            height: 350px;
        }

        &__svg-text {
            z-index: 1;
            left: 20px;
            bottom: 0;

            width: 200px !important;
        }

        &__svg-pattern {
            position: absolute;
            right: -35px;
            bottom: -45px;
            fill: var(--bone);

            width: 125px;
        }

        &__category-card {
            width: 225px !important;
            height: 120px !important;

            h2 {
                font-size: 20px !important;
                text-align: center;
            }
        }
    }
}

@include rmin($desktop-xs) {
    .main-art {
        &__wrapper {
            margin-top: 250px;
        }
    }
}

@include rmin($desktop-s) {
    .main-art {
        &__wrapper {
            max-height: $max-height;
            height: calc(var(--vh, 1vh) * 100);

            margin-top: unset;
        }
    }
}

@include rmin($desktop-sm) {
    .main-art {
        &__info-wrapper {
            // top: 250px;
        }

        h2 {
            &::before {
                top: -250px;
                width: 100px;
                height: 315px;
            }
        }

        &__img {
            height: 450px;
        }

        &__svg-text {
            left: 0;
            bottom: 28px;
            width: 385px !important;
        }

        &__cards-wrapper {
            bottom: 15px;
        }

        &__category-card {
            width: 285px !important;
            height: 150px !important;

            h2 {
                font-size: 24px !important;
            }
        }
    }
}

@include rmin($desktop-md) {
    .main-art {
        h2 {
            &::before {
                height: 335px;
            }
        }

        &__img {
            height: 500px;
        }

        &__cards-wrapper {
            bottom: 35px;
            width: 75%;
        }
    }
}
