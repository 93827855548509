@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

.main-history {
    &__wrapper {
        position: relative;
        min-height: 350px;
        padding-bottom: 350px;
    }

    &__text-wrapper {
        position: relative;
        z-index: 1;

        h2 {
            margin-top: 100px;
        }
    }

    &__img-wrapper {
        position: absolute;
        bottom: 0;
        z-index: -1;

        width: 100%;
        height: 40%;
        // height: 50%;
        background-position: center;
        background-image: url(../img/main-history.png);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 200px;
            background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        }

        &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 250px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
        }
    }

    &__text {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &__slider-wrapper {
        // position: absolute;
        // bottom: 125px;
        // right: 0;
        margin-top: 100px;
        margin-left: auto;
        width: calc(100% - 25px);
    }

    &__slides-wrapper {
        padding-top: 10px;
    }

    &__slider-title {
        font-family: font-serif;
        font-size: 20px;
        color: var(--blue-title);
    }

    &__slider-arrows {
        position: absolute;
        top: -40px;
    }

    &__slider-arrow {
        display: none;
    }

    &__card {
        height: 300px;
        background-size: cover;
    }
}

@include rmin($sm-phone-2) {
    .main-history {
        &__slider-title {
            font-size: 36px;
        }

        &__card {
            height: 350px;
        }
    }
}

@include rmin($tablet) {
    .main-history {
        &__card {
            height: 375px;
        }

        &__slider-wrapper {
            position: absolute;
            // bottom: 125px;
            right: 0;
            margin-top: unset;
            margin-left: unset;

            width: calc(100% - 25px);
        }
    }
}

@include rmin($desktop) {
    .main-history {
        overflow: hidden;
        max-height: $max-height;
        height: calc(var(--vh, 1vh) * 100);

        &__wrapper {
            display: flex;
            height: 100%;
            padding-bottom: unset;
        }

        &__slider-wrapper {
            width: 60%;
            bottom: 5px;
        }

        &__slider-title {
            font-size: 20px;
        }

        &__text-wrapper {
            width: 45%;
            padding-left: 25px;
            z-index: unset;

            h2 {
                margin-top: 20%;

                &::before {
                    left: -25px;
                    top: -10px;
                }
            }
        }

        &__img-wrapper {
            width: 55%;
            position: unset;
            z-index: unset;
            height: 60%;

            &::before {
                content: none;
            }
        }

        &__text {
            padding-right: 100px;
        }

        &__slider-arrows {
            top: unset;
            bottom: 10px;
            left: -350px;
        }

        &__slider-arrow {
            display: block;

            width: 55px;
            height: 55px;
            background-color: var(--brown-lighter);
            border-radius: 50%;

            border: unset;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 55px 20px;

            transition: background-color 0.5s;

            &--left {
                left: 170px;
                background-image: url(../img/arrow-left.svg);
            }

            &--right {
                right: -135px;
                background-image: url(../img/arrow-right.svg);
            }

            &:hover {
                background-color: var(--brown-light);
            }

            &::before {
                content: '';

                position: absolute;
                top: 50%;
                left: 49%;
                transform: translate(-49%, -49%);

                width: 52px;
                height: 52px;

                border: 1px solid #fff;
                border-radius: 50%;
            }
        }

        &__card {
            height: 300px;
        }

        &__svg-text {
            z-index: -1;
            height: 350px;
            top: 0;
            right: -65px;
        }
    }
}

@include rmin($desktop-xs) {
    .main-history {
        &__card {
            height: 300px;
        }

        &__img-wrapper {
            height: 50%;
        }

        &__slider-wrapper {
            width: 70%;
        }

        &__slider-arrows {
            left: -300px;
        }
    }
}

@include rmin($desktop-ss) {
    .main-history {
        &__slider-wrapper {
            width: 62%;
        }

        &__card {
            // height: 350px;
        }
    }
}

@include rmin($desktop-s) {
    .main-history {
        &__slider-wrapper {
            width: 62%;
        }

        &__card {
            height: 365px;
        }
    }
}

@include rmin($desktop-sm) {
    .main-history {
        &__text-wrapper {
            h2 {
                // margin-top: 200px;
            }
        }

        &__text {
            padding-right: 125px;
        }

        &__slider-wrapper {
            width: 70%;
        }

        &__card {
            height: 415px;
        }

        &__svg-text {
            height: 650px;
            top: -45px;
            right: -50px;
        }

        &__slider-arrows {
            left: -350px;
        }
    }
}

@include rmin($desktop-md) {
    .main-history {
        &__card {
            height: 510px;
        }

        &__slider-wrapper {
            width: 77%;
        }

        &__text-wrapper {
            h2 {
                margin-top: 110px;
            }
        }
    }
}
