.art-paint {
    h2 {
        margin-left: 15px;
    }

    &__list {
        margin-bottom: 25px;
    }

    &__list-item {
        margin-top: 25px;
    }

    &__list-item-wrap {
        padding: 25px 0;
        background-color: var(--bone);
    }

    &__photo {
        margin-left: auto;
        margin-right: auto;
        width: 175px;
        height: 250px !important;
    }

    &__works {
        z-index: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: 15px;
        margin-left: 10px;
        margin-right: 10px;
    }

    &__works-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;
    }

    &__works-item {
        margin-bottom: unset !important;

        &:last-child {
            margin-right: unset;
        }

        .gallery__link {
            height: 100px;
        }
    }

    &__photo img,
    &__works-item img {
        border-color: var(--white);
    }

    &__works-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            font-size: 18px;
            text-transform: uppercase;
            font-family: $font-serif;
            color: var(--blue-title);
        }
    }

    &__works-btn {
        display: block !important;
        cursor: pointer;
        opacity: 1;

        font-size: 14px;

        &::before {
            // top: 2px !important;
            width: 18px;
            background-size: 18px 16px;
        }
    }
}

@include rmin($desktop) {
    .art-paint {
        &__list {
            margin-bottom: 100px;
        }

        &__list-item {
            margin-top: 150px;
        }

        &__list-item-wrap {
            position: relative;
            display: flex;
            padding: 0 0 35px 35px;
            height: 400px;

            &::before {
                content: '';
                z-index: 1;
                position: absolute;
                top: -25px;
                right: 15px;

                width: 345px;
                height: 345px;
                background-image: url(../img/bio-pattern.png);
            }
        }

        &__photo {
            margin-left: unset;
            margin-right: unset;
            margin-top: -85px;
            min-width: 300px;
            width: 300px;
            height: 450px !important;
        }

        &__works {
            margin-top: unset;
            margin-left: 25px;
            margin-right: unset;
        }

        &__works-list {
            flex-wrap: unset;
            margin-top: unset;
        }

        &__works-item {
            width: 175px !important;

            .gallery__link {
                height: 150px !important
            }
        }

        &__works-title {
            h3 {
                font-size: 35px;
            }
        }

        &__works-btn {
            margin-left: 25px;
            font-size: 22px;

            &::before {
                // top: 7px !important;
                width: 23px;
                background-size: 23px 16px;
            }
        }
    }
}

@include rmin($desktop-sm) {
    .art-paint {
        &__list-item-wrap {
            &::before {
                right: 150px;
            }
        }

        &__photo {}

        &__works {
            margin-left: 35px;
        }

        &__works-item {
            width: 245px !important;

            .gallery__link {
                height: 200px !important
            }
        }
    }
}
