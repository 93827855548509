.culture {
    &__hero {
        background-image: var(--hero-img-culture);
    }

    &__info {
        position: relative;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    &__cards {
        margin-bottom: 50px;
        // position: absolute;
        // bottom: -155px;
        // left: 10px;

        width: calc(100% - 25px);

        .card {
            padding: 15px;

            &::after {
                width: 97%;
                height: 95%;
            }
        }
    }

    &__cards-list {
        margin-top: 25px;
        margin-left: 15px;
    }

    &__svg-text {
        bottom: 37px;
        right: 0;
        left: 10px !important;
        width: 225px !important;
        height: 50px !important;
    }

    &__text-block:last-child {
        .img__bordered {
            margin-bottom: unset;
        }
    }

    &__img--1 {
        background-image: var(--img-culture-1);
    }

    &__img--2 {
        background-image: var(--img-culture-2);
    }

    &__img--3 {
        background-image: var(--img-culture-3);
    }
}

@include rmin($desktop) {
    .culture {
        &__info {
            // margin-top: 305px;
            margin-top: 50px;
            margin-bottom: 50px;
            padding-left: 50px;
        }

        &__cards {
            margin-bottom: 50px;
            // bottom: -250px;
            // left: unset;

            // padding-left: 25px;
            width: 100%;
        }

        &__cards-list {
            margin-top: 50px;
            margin-left: 35px;
            margin-bottom: 100px;
        }

        &__svg-text {
            right: 0;
            left: 30px !important;
            bottom: -60px;

            width: 300px !important;
            height: 115px !important;
        }

        &__img-wrapper {
            min-width: 350px;
            height: 100%;
            margin-top: 25px;

            &--1 {
                margin-right: 30px;

                &::after {
                    left: unset;
                    left: -20px;
                    width: 90%;
                    height: 100%;
                }
            }

            &--2 {
                min-width: 450px;
                margin-left: 30px;

                &::after {
                    left: unset;
                    right: -20px;
                    width: 90%;
                    height: 100%;
                }
            }

            &--3 {
                width: 100%;

                &::after {
                    width: calc(100% + 50px);
                }
            }
        }

        &__img {
            &--1 {
                height: 450px;
            }

            &--2 {
                height: 300px;
            }

            &--3 {
                height: 300px;
            }
        }

        &__text-block {
            &--flex {
                display: flex;
            }
        }
    }
}

@include rmin($desktop-s) {
    .culture {
        &__cards-list {
            bottom: -250px;
        }
    }
}

@include rmin($desktop-sm) {
    .culture {
        &__cards {
            max-width: $max-width-inner-sm;
        }

        &__cards-list {
            width: 100%;
            bottom: -266px;
        }

        &__svg-text {
            bottom: 25px;
        }
    }
}
