.collections {
    &__hero {
        overflow: hidden;
        background-image: var(--hero-img-collections);
    }

    &__collections {
        position: relative;
        // margin-top: 65px;
    }

    &__exhibitions {
        position: relative;
        margin-top: 65px;
    }

    &__gallery {
        position: relative;
        margin-top: 65px;

        padding-left: 25px;
        padding-bottom: 25px;
    }

    &__svg-text {
        // right: -40px;
        left: 10px !important;
        bottom: 5px;

        width: 300px;
        height: 40px !important;
        stroke-width: 2 !important;
    }

    &__slider-wrapper {
        position: relative;
        left: -25px;
        right: -25px;
        padding-left: 25px;

        margin-top: 50px;
        margin-bottom: 25px;

        width: calc(100% + 50px);
        background-color: var(--bone);
    }

    &__slider {
        position: relative;
        top: -25px;
    }

    &__slider-list {
        margin-bottom: 15px;
    }

    &__arrows {
        display: none;
    }

    &__card-item {
        &:not(:last-child) {
            margin-right: 5px;
        }

        &:last-child {
            display: flex;

            &::after {
                content: '';
                display: block;
                width: 20px;
            }
        }
    }

    &__card-item-link {
        display: block;
        text-decoration: none;
    }

    &__card-wrapper {
        background-size: cover;
        background-position: center;
        border-color: var(--brown-lighter);
    }

    &__card {
        padding: 15px;
        min-width: 220px;
        height: 140px;
    }

    &__card-title {
        padding-bottom: 2px;
        margin-bottom: 2px;

        font-family: $font-serif;
        font-size: 16px;
        color: #fff;
        border-bottom: .5px solid #fff;
    }

    &__card-text {
        font-family: $font-serif;
        color: #fff;
        font-size: 14px;
    }

    &__card-slider-item {
        flex-direction: column;

        .card {
            padding: 15px;
        }
    }

    &__gallery-list {
        margin-top: 25px;

        li {
            &:not(:last-child) {
                margin-right: 2px;
            }
        }
    }

    &__gallery-card-wrapper {
        background-position: center;
    }

    &__gallery-card-title {
        font-size: 16px;
    }

    &__svg-pattern {
        display: none;
    }

    &__list {
        background-color: var(--bone);
    }

    &__info-title {
        color: var(--brown-light);
    }

    &__card-text {
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        color: var(--bone);
        text-align: center;
    }
}

@include rmin($desktop) {
    .collections {
        &__collections {
            position: unset;
            padding-left: 50px;
        }

        &__exhibitions {
            padding-left: 50px;
        }

        &__gallery {
            margin-left: 25px;
            margin-bottom: 65px;

            padding-top: 50px;
            padding-left: 50px;
            background-color: var(--bone);

            overflow: hidden;
        }

        &__slider-wrapper {
            width: calc(100% + 25px);
        }

        &__slider-list {
            margin-bottom: 20px;
        }

        &__svg-text {
            left: 40px !important;
            bottom: 25px !important;

            width: 900px !important;
            height: 80px !important;
        }

        &__card {
            min-width: 350px;
            height: 250px;

            padding-left: 25px;
            padding-right: 25px;
        }

        &__card-title {
            font-size: 22px;
        }

        &__arrows {
            position: absolute;
            bottom: -45px;
            right: 150px;
            display: block;
        }

        &__arrow {
            &--left {
                left: -120px;
                background-image: url(../img/arrow-left.svg);
            }

            &--right {
                right: 0;
                background-image: url(../img/arrow-right.svg);
            }
        }

        &__gallery-slider-wrapper {
            position: relative;
        }

        &__gallery-list {
            li {
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }

        &__gallery-card-wrapper {
            margin-bottom: 10px;
        }

        &__gallery-card {
            width: 265px;
            height: 125px;
        }

        &__gallery-card-title {
            font-size: 22px;
        }

        &__card-text {
            font-size: 18px;
        }

        &__card-slider-item {
            .card {
                padding: 20px;
            }
        }

        &__info-title {
            font-weight: 600;
            font-size: 26px;
            line-height: 33px;
            margin-bottom: 5px;
        }

        &__card-text {
            font-size: 16px;
            margin: 5px 0;
        }
    }
}


@include rmin($desktop-sm) {
    .collections {
        &__card {
            min-width: 400px;
            height: 350px;
        }

        &__gallery-card {
            width: 350px;
            height: 170px;
        }

        &__svg-text {
            width: 800px !important;
        }

        &__svg-pattern {
            position: absolute;
            bottom: 25px;
            right: -100px;
            display: block;

            width: 300px !important;
            height: 300px !important;

            fill: transparent;
            stroke: var(--white) !important;
            stroke-width: 15 !important;
        }
    }
}
