.article {
    &s__wrap {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    &__header {
        height: 150px;
    }

    &__wrap {
        margin-left: 15px;
        margin-right: 15px;
    }

    &__title {
        margin-top: 25px;

        font-size: 25px;
        line-height: 30px;
        color: var(--brown-light) !important;
    }

    &__sub-title {
        color: var(--gray);
        font-size: 22px;
    }

    &__text-img-wrap {
        width: 25%;
        margin-left: auto;
        margin-right: auto;

        p {
            text-align: center;
        }

        &--1 {
            width: 30%;
        }

        &--2 {
            width: 50%;
        }
    }

    &__img-wrap {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 50px;
        height: 250px;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__author {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    &__author-link {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        color: var(--gray);
    }

    &__author-link:hover &__author-info-name {
        color: var(--brown-light);
    }

    &__author-info {
        margin-left: 10px;

        span {
            display: block;
        }
    }

    &__author-info-title {
        font-size: 14px;
    }

    &__author-info-name {
        font-size: 16px;
        font-weight: 600;
        color: var(--gray-dark);
    }

    &__author-img-wrap {
        width: 50px;
        min-width: 50px;
        height: 50px;
        min-height: 50px;

        border-radius: 50%;
        border: 3px solid var(--white);
        box-shadow: 0px 10px 50px rgba(188, 121, 102, 0.2);
        background-color: var(--brown-light);

        overflow: hidden;
    }

    // other
    &__other-title,
    &__other-item-title {
        font-family: $font-serif;
        font-weight: 600;
    }

    &__other-wrap {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    &__other-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
    }

    &__other-item {
        &:not(:first-child) {
            margin-top: 35px;
        }
    }

    &__other-link {
        display: block;
    }

    &__other-link:hover &__other-item-title {
        color: currentColor;
    }

    &__other-title {
        font-size: 34px;
        color: var(--brown-light);
    }

    &__other-img-wrap {
        height: 175px;
    }

    &__other-item-title {
        margin-top: 10px;
        color: var(--gray);
        font-size: 18px;
    }

    &__other-item-author {
        display: inline-block;
        margin-top: 5px;
        padding: 5px 10px;

        font-size: 14px;
        color: var(--gray);
        border: 1px solid var(--gray-light);
        border-radius: 5px;

        &:hover {
            color: currentColor;
        }
    }
}


@include rmin($desktop) {
    .article {
        &__wrap {
            margin-left: 45px;
            margin-right: unset;
            width: 950px;
        }

        &__header {
            display: none;
        }

        &__img-wrap {
            margin-left: unset;
            margin-right: unset;
            height: 450px;
        }

        &__title {
            margin-top: 110px;

            font-size: 45px;
            line-height: 55px;
        }

        &__author-info-title {
            font-size: 18px;
        }

        &__author-info-name {
            font-size: 22px;
        }

        &__author-img-wrap {
            width: 75px;
            min-width: 75px;
            height: 75px;
            min-height: 75px;

            border: 5px solid var(--white);
        }

        &__author-info {
            margin-left: 15px;
        }

        &__author {
            margin-top: 35px;
            margin-bottom: 35px;
        }

        &__other-wrap {
            margin-top: 100px;
        }

        &__other-img-wrap {
            height: 250px;
        }

        &__other-item {
            margin-bottom: 35px;
            width: 450px;

            &:nth-child(2n) {
                margin-left: 50px;
            }

            &:not(:first-child) {
                margin-top: unset;
            }
        }

        &__other-item-title {
            margin-top: 15px;
            font-size: 34px;
        }
    }
}
