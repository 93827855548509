.contacts {
    &__section {
        margin-top: 25px;
    }

    &__hero {
        background-color: var(--bone);
        min-height: 225px;

        &::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: -50px;
            left: auto;
            width: 120px;
            height: 150px;
            background-size: cover;
            background-image: url(../img/bio-pattern.png);
        }

        &::after {
            background: linear-gradient(180deg, #D09A82 0%, rgba(208, 154, 130, 0) 100%);
        }
    }

    &__title {
        position: absolute;
        bottom: 1rem;
        left: 1.5rem;
        font-family: $font-serif;
        font-weight: bold;
        font-size: 35px;
        line-height: 45px;
        text-transform: uppercase;
        color: var(--blue-title);
    }

    &-inner__title {
        font-family: $font-text;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #000;
        margin-bottom: 15px;
    }

    &__address a {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        text-decoration: none;
        color: var(--brown-light);
    }

    &__icon-bg {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        background: var(--bone);
        border-radius: 5px;
        margin-right: 10px;
        margin-bottom: 15px;
    }

    &__icon {
        width: 15px;
        height: 15px;
        fill: var(--brown-light);
    }

    &__form {
        margin-top: 50px;
        margin-bottom: 25px;

        input {
            background: var(--white);
            border: 2px solid #E4E4E4;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 15px;
            max-width: 450px;
            width: 100%;
            margin-bottom: 10px;
            margin-top: 5px;

            font-size: 14px;
        }

        input[type='submit'] {
            background: var(--brown-light);
            border-radius: 5px;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: var(--white);
            font-family: $font-text;
            border: none;
            margin-top: 0;
        }

        textarea {
            background: var(--white);
            border: 2px solid #E4E4E4;
            box-sizing: border-box;
            border-radius: 5px;
            max-width: 900px;
            width: 100%;
            resize: none;
            padding: 15px;
            margin-bottom: 15px;
            margin-top: 5px;
            font-family: $font-text;
            font-size: 16px;
            line-height: 18px;
            height: 150px;
        }
    }
}

.form__input {
    border: 1px solid transparent;

    &:focus {
        border-color: var(--green);
    }
}

.form__input._error {
    // box-shadow: 0 0 15px red;
    border-color: var(--red);
}

@include rmin($desktop) {
    .contacts {
        &__section {
            padding: 0 28px;
            margin-top: 55px;
        }

        &__hero {
            height: 285px;

            &::after {
                background: none;
            }

            &::before {
                left: 35%;
                width: 345px;
                height: 345px;
                bottom: -35px;
            }
        }

        &__title {
            font-size: 75px;
            line-height: 97px;
        }

        &-inner__title {
            font-size: 28px;
            line-height: 33px;
        }

        &__form {
            margin-bottom: 75px;

            input[type="submit"] {
                max-width: 250px;
                font-size: 20px;
            }

            textarea {
                height: 250px;
            }
        }

        &__address a {
            font-size: 22px;
            line-height: 26px;
        }

        &__icon-bg {
            width: 45px;
            height: 45px;
        }

        &__icon {
            width: 25px;
            height: 25px;
        }
    }
}
