.btn {
    font-size: 16px;
    text-decoration: none;

    &__read-more {
        position: relative;
        display: flex;

        margin-left: 50px;
        padding-top: 8px;
        padding-bottom: 8.5px;
        padding-left: 10px;

        width: 150px;
        max-width: 200px;

        font-family: $font-serif, sans-serif;
        font-weight: 700;
        color: var(--brown-light);

        border-top: var(--border-brown);
        border-bottom: var(--border-brown);

        transition: background-color 0.5s;

        &::before {
            content: '';
            position: absolute;
            left: -50px;
            top: -3px;

            background-image: url(../img/btn-left-pattern.svg);
            background-repeat: no-repeat;

            width: 56px;
            height: 45px;
            background-size: 56px 45px;
        }

        &::after {
            content: '';
            position: absolute;
            top: -3px;
            right: -10px;

            background-image: url(../img/btn-right-pattern.svg);
            background-repeat: no-repeat;

            width: 11px;
            height: 45px;
            background-size: 11px 45px;
        }
    }

    &__read-more--hero {
        color: var(--white);
        border-color: var(--white);

        &::before {
            background-image: url(../img/btn-left-pattern-white.svg);
        }

        &::after {
            background-image: url(../img/btn-right-pattern-white.svg);
        }
    }

    &__bg {
        display: block;
        background-color: white;
        width: 216px;
        position: absolute;
        top: -6px;
        left: -53px;
        height: 51px;
        z-index: -1;
        opacity: .9;
    }

    &__anchor {
        display: block;
        margin-top: 15px;
        
        color: var(--brown-light);
        text-decoration: none;
        transition: color .35s;

        span {
            border-bottom: 1px dashed currentColor;
        }

        &:hover {
            color: var(--brown);
        }
    }
}

@include rmin($desktop) {
    .btn {
        &__read-more--hero {
            color: var(--brown-light);
            border-color: var(--brown-light);

            &::before {
                background-image: url(../img/btn-left-pattern.svg);
            }

            &::after {
                background-image: url(../img/btn-right-pattern.svg);
            }
        }

        &__bg {
            display: none;
        }

        &:hover &__history-bg {
            display: block;
        }
    }
}
