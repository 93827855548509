.library {
    margin-bottom: 25px;

    &__hero {
        background-image: var(--hero-img-library);
        overflow-x: hidden;
        width: 100%;
    }

    &__img-wrapper {

        &--1 {}

        &--2 {}
    }

    &__img--1 {
        // background-image: var(--img-library-1);
    }

    &__img--2 {
        background-image: var(--img-library-2);
    }

    &__go-library {
        margin: 25px -25px;
    }

    &__info-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        // margin-top: -75px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 50px;
        padding: 15px 17px;

        background-color: var(--bone);
        border-radius: 10px;
    }

    &__info-logo {
        width: 175px;
        height: 175px;
    }

    &__info {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;

        color: var(--gray);
        font-family: $font-serif;
        font-weight: 400;
        text-align: center;

    }

    &__btn {
        display: block;

        padding: 10px 25px;
        margin-left: auto;
        margin-right: auto;
        width: 255px;

        color: var(--white);
        background-color: var(--brown-light);
        border-radius: 25px;

        font-size: 18px;
        text-align: center;
        text-decoration: none;
    }

    &__svg-text {
        left: 20px !important;
        bottom: 20px !important;
    }

    &__slider-wrapper {
        margin-top: 15px;

        .collections__slider {
            position: unset;
        }
    }

    &__slider-title {
        padding-top: 10px;
        margin-bottom: 10px;
        font-family: $font-serif;
        color: var(--blue-title);
    }
}


@include rmin($desktop) {
    .library {
        margin-top: unset;
        margin-bottom: unset;
        padding: 50px;

        &__content {
            display: flex;

            .main-library__card-list-item {
                &:first-child {
                    margin-left: unset;
                }

                &:last-child {
                    margin-right: 10px;
                }

                &:nth-child(2) {
                    margin-right: 10px;
                }
            }

            &--first {
                width: calc(100% - 425px);
            }
        }

        &__slider-title {
            padding-top: unset;
            margin-top: 10px;
            margin-bottom: 15px;
            font-size: 34px;
        }

        &__slider-wrapper {
            left: unset;
            right: unset;

            order: 1;

            margin-top: -50px;
            margin-left: 50px;
            margin-right: -50px;
            height: 100%;
            width: 400px !important;

            background-color: unset !important;

            .collections__slider {
                top: 0;
            }
        }

        &__img-wrapper {
            &--2 {
                min-width: 350px;
                height: 100%;
                margin-top: 25px;
                margin-right: 35px;
            }
        }

        &__img--1 {
            height: 280px;
            background-size: contain;
        }

        &__go-library {
            margin-left: unset;
            margin-right: unset;
        }

        &__info-wrapper {
            flex-direction: unset;
            margin: 100px 0;
            padding: 15px;
            width: 100%;
        }

        &__info {
            margin-left: 25px;
            margin-right: 50px;

            font-size: 28px;
            font-weight: 500;
            text-align: left;
        }

        &__btn {
            width: 400px;
            font-size: 25px;
            border-radius: 50px;

            transition: background-color .35s;

            &:hover {
                background-color: var(--brown);
            }
        }

        &__svg-text {
            right: 0;
            left: 40px !important;
            bottom: -60px;

            width: 300px !important;
            height: 100px !important;
        }
    }
}

@include rmin($desktop-sm) {
    .library {
        &__content {
            &--first {
                width: calc(100% - 775px);
            }

            .main-library__card-list-item {
                &:last-child {
                    margin-right: 25px;
                }

                &:nth-child(2) {
                    margin-right: 25px;
                }
            }
        }

        &__slider-wrapper {
            // width: 775px !important;
        }

        &__img-wrapper {
            &--2 {
                min-width: 599px;
                margin-right: 50px;
            }
        }

        &__img--1 {
            // height: 850px;
        }

        &__img--2 {
            height: 425px;
        }

        &__go-library {
            margin-top: 100px;
            margin-bottom: 100px;
        }

        &__info-wrapper {
            padding: 35px;
        }

        &__card {
            width: 200px !important;
            height: 290px !important;
        }

        .main-library__card-list-item {
            margin-bottom: 10px;

            &:not(:last-child) {
                margin-right: 10px !important;
            }
        }
    }
}

@include rmin($desktop-md) {
    .library {
        &__slider-wrapper {
            width: 750px !important;
            // margin-top: -132px;

            .main-library__new-card {
                padding: 20px;
                min-width: 225px;
                width: 225px;

                h3 {
                    font-size: 24px;
                }
            }
        }
    }
}
