body {
    background-color: var(--white);
    font-size: 16px;
    line-height: 1.4;

    font-family: $font-text;
}

.site-container {
    display: flex;

    margin-left: auto;
    margin-right: auto;
    max-width: $max-width;
}

.serif-text {
    font-family: $font-serif;
    color: var(--blue-title);
}

.site-content {
    width: 100%;
}

.text-section {
    padding-left: 25px;
    padding-right: 25px;
}

.text-info__wrapper {
    margin-bottom: 50px;
}

.text-info {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 15px;
    color: var(--gray-dark);
}

section {
    h2 {
        font-size: 35px;
    }

    .main-text {
        font-size: 14px;
        line-height: 24px;
        color: var(--gray);
    }

    .main-text-inner {
        margin-top: 15px;
    }

    .svg-text {
        display: none;
    }

    // svg text for inner pages (mob & desktop)
    .svg-text-inner {
        position: absolute;
        stroke: var(--bone);
        fill: transparent;
        left: 39px;
        width: 275px;
        height: 100px;
        stroke-width: 2;
    }
}

.t-crop {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &--one-l {
        -webkit-line-clamp: 1;
    }

    &--two-l {
        -webkit-line-clamp: 2;
    }

    &--three-l {
        -webkit-line-clamp: 3;
    }
}

// .title-background {
//     &::before {
//         content: '';
//         position: absolute;
//         z-index: -1;
//         top: -12px;
//         left: -20px;

//         background-color: var(--bone);
//         width: 100px;
//         height: 70px;
//     }
// }

img {
    max-width: 100%;
    height: auto;
    width: 100%;
}

.img__full-page {
    background-repeat: no-repeat;
    background-size: cover;
}

.img__hero-inner {
    position: relative;
    min-height: 350px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 130px;

        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 130px;

        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    }
}

.img__covered {
    display: block;
    min-height: 240px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.img__bordered {
    position: relative;
    left: -10px;
    right: -10px;

    margin-top: 15px;
    margin-bottom: 35px;
    padding: 5px;

    width: calc(100% + 20px);

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        bottom: -20px;
        left: -25px;
        right: -25px;

        width: calc(100% + 50px);
        height: 125px;

        background-color: var(--bone);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        border: 1px solid var(--brown-light);
    }
}

.vector-icon {
    fill: var(--gray);
    width: 35px;
    height: 35px;
}

.visually-hidden,
.visually-hidden::after,
.visually-hidden::before {
    position: absolute !important;
    z-index: -1 !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    opacity: 0 !important;
    font-size: 1px !important;
    clip: rect(0 0 0 0) !important;
}

.content-wrapper {
    padding-left: 25px;
    padding-right: 25px;
}

// animation actions blocker
.actions-blocker {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;

    width: 100vw;
    height: 100vh;

    background-color: transparent;
    pointer-events: none;

    &.blocked {
        pointer-events: all;
    }
}

.content__section {
    margin-top: 50px;
}

.section__text-content {
    margin-top: 15px;
    padding-top: 25px;
}

.decorated__list {
    li {
        margin-top: 5px;

        &::before {
            content: "";
            display: inline-block;
            margin-right: 15px;
            width: 7px;
            height: 7px;
            background-color: var(--brown-light);
            transform: rotate(-45deg);
        }
    }
}

.link {
    text-decoration: none;
    color: var(--brown-light);

    &:hover {
        color: var(--brown-lighter);
    }
}

.m-t-35 {
    margin-top: 35px;
}

@include rmin($tablet) {
    section {
        .main-text {
            font-size: 18px;
            line-height: 30px;
        }

        h2 {
            font-size: 55px;
            line-height: 1.1;
        }
    }

    .section__text-content {
        h3 {
            font-size: 26px;
        }
    }
}

@include rmin($desktop) {
    .site-content {
        margin-left: 200px;
        width: calc(100% - 200px);
    }

    .content-wrapper {
        padding-left: unset;
        padding-right: unset;
    }

    .content__section {
        margin-top: 75px;
    }

    .lift-up {
        transition: transform 0.5s ease 0s;
        will-change: transform;

        &:hover,
        &:focus {
            transform: translateY(-5px);
        }
    }

    .text-section {
        padding-left: unset;
        padding-right: unset;
    }

    .text-info__wrapper {
        max-width: 916px;
        margin-bottom: 75px;
    }

    .text-info {
        font-size: 22px;
        line-height: 32px;
    }

    .text-ellipsis {
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .img__hero-inner {
        height: 400px;
        background-position-y: bottom;
    }

    .img__bordered {
        width: 350px;
        left: unset;
        right: unset;
    }

    section {
        .main-text {
            font-size: 16px;
            line-height: 24px;
        }

        .main-text-inner {
            margin-top: 25px;
        }

        .svg-text {
            display: block;
            position: absolute;
            stroke: var(--bone);
            fill: transparent;

            width: 150px;
            stroke-width: 2;
        }

        .svg-text-inner {
            width: 414px;
            height: 155px;
            bottom: 0px;
        }
    }

    .title-background {
        position: relative;

        &::before {
            width: 120px;
            height: 75px;
        }
    }

    .content__max-width {
        max-width: $max-width-inner;
    }

    // photoswipe
    .pswp-collections {
        .pswp__zoom-wrap {
            transform: unset !important;
        }

        .pswp__img {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        img {
            width: 90% !important;
            height: 90% !important;
        }
    }
}

@include rmin($desktop-s) {
    section {
        .main-text {
            font-size: 18px;
            line-height: 26px;
        }
    }
}

@include rmin($desktop-sm) {
    .img__hero-inner {
        height: 155px;
    }

    section {
        .svg-text-inner {
            width: 414px;
        }

        .main-text {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .content__max-width {
        max-width: $max-width-inner-sm;
    }
}

@include rmin($desktop-md) {
    .site-content {
        margin-left: 255px;
        width: calc(100% - 255px);
    }

    section {
        h2 {
            font-size: 75px;
        }

        .main-text {
            font-size: 24px;
            line-height: 35px;
        }
    }

    .title-background {
        &::before {
            height: 100px;
        }
    }

    .content__max-width {
        max-width: $max-width-inner-md;
    }
}
