.card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    padding: 25px;
    height: 100%;

    text-decoration: none;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.13) 0%, rgba(0, 0, 0, 0.52) 100%);

    &:hover {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.13) 0%, rgba(0, 0, 0, 0.80) 100%);
    }

    &::after {
        content: '';

        position: absolute;
        top: 50%;
        left: 50%;

        width: 96%;
        height: 96%;

        transform: translate(-50%, -50%);
        border: 1px solid var(--white);
    }

    &__wrapper {
        border: 3px solid var(--white);
        background-size: cover;
    }

    &__title {
        font-family: $font-serif;
        font-size: 24px;
        line-height: 1.2;
        color: var(--white);
    }

    &__text {
        margin-top: 10px;
        margin-bottom: 20px;
        color: var(--white);

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__btn {
        position: relative;
        z-index: 1;

        margin-left: auto;
        text-decoration: none;

        &::before {
            content: '';
            position: absolute;
            top: 4px;
            right: 0;

            background-image: url(../img/arrow-small.svg);
            background-repeat: no-repeat;
            width: 23px;
            height: 16px;
            background-size: 23px 16px;
        }
    }

    &__btn-text {
        font-family: $font-serif;

        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 30px;

        color: var(--white);
    }


    &__btn {
        transition: opacity 0.5s;
        opacity: 0.5;
    }

    &__btn-text {
        transition: opacity 0.5s;
        opacity: 0;
    }

    // card hovered
    &:hover &__btn-text {
        opacity: 0.75;
    }

    // btn hovered
    &__btn:hover,
    &__btn:hover &__btn-text {
        opacity: 1;
    }

    &__slider-list {
        display: flex;
        flex-wrap: nowrap;

        overflow-x: auto;
        scroll-snap-type: x mandatory;
    }

    &__slider-item {
        scroll-snap-align: start;

        &:not(:last-child) {
            margin-right: 8px;
        }

        &:last-child {
            display: flex;

            &::after {
                content: '';
                display: block;
                width: 20px;
            }
        }
    }
}

@include rmin($desktop) {
    .card {
        padding: 20px;

        &__title {
            font-size: 20px;
        }

        &__text {
            margin-bottom: 10px;
        }

        &__slider-list {
            flex-wrap: wrap;
            overflow-x: unset;
        }

        &__slider-item {
            margin-bottom: 5px;
        }
    }
}

@include rmin($desktop-s) {
    .card {
        &__title {
            font-size: 24px;
        }
    }
}

@include rmin($desktop-sm) {
    .card {
        padding: 25px;

        &__title {
            font-size: 32px;
        }

        &__text {
            margin-bottom: 20px;
            font-size: 22px;
        }
    }
}
