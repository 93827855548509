.icon-arrow-left {
  width: 1.39em;
  height: 1em;
  fill: initial;
}
.icon-arrow-right {
  width: 1.39em;
  height: 1em;
  fill: initial;
}
.icon-art-1 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-art-10 {
  width: 1.03em;
  height: 1em;
  fill: initial;
}
.icon-art-11 {
  width: 1.03em;
  height: 1em;
  fill: initial;
}
.icon-art-12 {
  width: 1.03em;
  height: 1em;
  fill: initial;
}
.icon-art-13 {
  width: 1.03em;
  height: 1em;
  fill: initial;
}
.icon-art-2 {
  width: 1.03em;
  height: 1em;
  fill: initial;
}
.icon-art-3 {
  width: 1.03em;
  height: 1em;
  fill: initial;
}
.icon-art-4 {
  width: 1.03em;
  height: 1em;
  fill: initial;
}
.icon-art-5 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-art-6 {
  width: 1.03em;
  height: 1em;
  fill: initial;
}
.icon-art-7 {
  width: 1.03em;
  height: 1em;
  fill: initial;
}
.icon-art-8 {
  width: 1.03em;
  height: 1em;
  fill: initial;
}
.icon-art-9 {
  width: 1.03em;
  height: 1em;
  fill: initial;
}
.icon-art-hero {
  width: 2.14em;
  height: 1em;
  fill: initial;
}
.icon-artists-hero {
  width: 4.12em;
  height: 1em;
  fill: initial;
}
.icon-collections-hero {
  width: 14.22em;
  height: 1em;
  fill: initial;
}
.icon-culture-card-ico-1 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-culture-card-ico-2 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-culture-card-ico-3 {
  width: 0.96em;
  height: 1em;
  fill: initial;
}
.icon-culture-card-ico-4 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-culture-card-ico-5 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-culture-card-ico-6 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-culture-card-ico-7 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-culture-hero {
  width: 4.65em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}
.icon-genre-ico {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-go-arrow {
  width: 1.33em;
  height: 1em;
  fill: initial;
}
.icon-hero-pattern {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-history-hero {
  width: 3.71em;
  height: 1em;
  fill: initial;
}
.icon-history-tour {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-library-card-ico-1 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-library-card-ico-2 {
  width: 1.31em;
  height: 1em;
  fill: initial;
}
.icon-library-card-ico-3 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-library-card-ico-4 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-library-card-ico-5 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-library-hero {
  width: 3.75em;
  height: 1em;
  fill: initial;
}
.icon-mail-us {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-mail {
  width: 1.32em;
  height: 1em;
  fill: initial;
}
.icon-main-art-pattern {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-main-art {
  width: 2.14em;
  height: 1em;
  fill: initial;
}
.icon-main-artists {
  width: 3.84em;
  height: 1em;
  fill: initial;
}
.icon-main-culture {
  width: 5.46em;
  height: 1em;
  fill: initial;
}
.icon-main-exhibition {
  width: 6.06em;
  height: 1em;
  fill: initial;
}
.icon-main-history {
  width: 0.28em;
  height: 1em;
  fill: initial;
}
.icon-main-library {
  width: 3.75em;
  height: 1em;
  fill: initial;
}
.icon-pattern {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-phone-alt {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-romb {
  width: 0.97em;
  height: 1em;
  fill: initial;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: initial;
}
