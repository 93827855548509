// breakpoints
$sm-phone: 360;
$sm-phone-2: 414;
$tablet: 680;
// $desktop: 1365;
$desktop: 1000;
$desktop-xs: 1150;
$desktop-ss: 1279;
$desktop-s: 1365;
$desktop-sm: 1600;
$desktop-md: 1919;

$max-width: 1919px;
$max-width-inner: 1000px;
$max-width-inner-sm: 1200px;
$max-width-inner-md: 1400px;
$max-height: 1080px;

$font-serif: 'FrankRuhlLibre',
sans-serif;
$font-text: 'GillSans',
sans-serif;

// colors
:root {
    --blue-title: #413C54;
    --green: #0D8F8C;
    --red: #dc3545;

    // gray colors
    --white: #FFFFFF;
    --gray-light: #E4E4E4;
    --gray: #545454;
    --gray-dark: #2B2B2A;

    // brown colors
    --bone: #FFF4ED;
    --brown-lighter: #D09A82;
    --brown-light: #BC7966;
    --brown: #9F6F62;
    --brown-dark: #43211A;

    // border
    --border-brown: 3px solid var(--brown-light);

    // breakpoints
    --tablet: #{$tablet};

    // hero img
    --hero-img-history: url(../img/hero-history.jpg);
    --hero-img-culture: url(../img/hero-culture.jpg);
    --hero-img-collections: url(../img/hero-collections.jpg);
    --hero-img-artists: url(../img/hero-artists.jpg);
    --hero-img-art: url(../img/hero-art.jpg);
    --hero-img-library: url(../img/hero-library.jpg);

    // background images
    --img-culture-1: url(../img/culture-page-img-1.jpg);
    --img-culture-2: url(../img/culture-page-img-2.jpg);
    --img-culture-3: url(../img/culture-page-img-3.jpg);

    --img-artists-1: url(../img/artists-page-1.jpg);
    --img-artists-2: url(../img/artists-page-2.jpg);

    --img-art-1: url(../img/art-page-1.jpg);
    --img-art-2: url(../img/art-page-2.jpg);

    --img-library-1: url(../img/library-page-1.jpg);
    --img-library-2: url(../img/library-page-2.jpg);
}
