.main-culture {
    &__wrapper {
        position: relative;

        height: 100%;
        min-height: 550px;

        margin-top: 70px;
        padding-bottom: 400px;
    }

    &__text-wrapper {
        width: 100%;
        padding-left: 25px;
    }

    &__img-wrapper {
        position: absolute;
        bottom: 0;
        z-index: -1;
        width: 100%;
        height: 50%;
    }

    &__img {
        position: relative;
        // margin-top: 50px;
        background-image: url(../img/main-culture.jpg);
        background-size: cover;
        height: 100%;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 340px;
            background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        }

        // &::after {
        //     content: "";
        //     position: absolute;
        //     left: 0;
        //     right: 0;
        //     bottom: 0;
        //     height: 250px;
        //     background: linear-gradient(180deg,
        //             rgba(0, 0, 0, 0) 0%,
        //             rgba(0, 0, 0, 0.75) 100%);
        // }
    }

    &__text {
        padding-right: 45px;
    }

    &__card-list-wrapper {
        // position: absolute;
        // left: 20px;
        // bottom: -25px;
        margin-left: auto;
        margin-top: 35px;
        width: calc(100% - 20px);

        .card {
            padding: 15px;

            &__btn::before {
                top: -19px;
            }

            &::after {
                height: 90% !important;
            }
        }
    }

    &__card-list-item {
        scroll-snap-align: start;

        &:not(:last-child) {
            margin-right: 8px;
        }

        &:first-child,
        &:last-child {
            display: flex;
        }

        &:last-child {
            &::after {
                content: '';
                display: block;
                width: 20px;
            }
        }
    }

    &__card {
        width: 260px;
        height: 110px;

        align-items: flex-end;
        flex-direction: unset;
        justify-content: space-between;

        text-decoration: none;
    }

    &__card-list {
        display: flex;
        flex-wrap: nowrap;

        overflow-x: auto;
        scroll-snap-type: x mandatory;
    }

    &__card-title {
        font-size: 18px;
    }

    &__info {
        display: flex;
        align-items: center;
    }

    &__icon {
        width: 25px;
        height: 25px;

        fill: var(--white);
        margin-right: 10px;
    }

    &__btn-icon {
        width: 27px;
        height: 20px;

        fill: none;
        stroke: var(--white);
        stroke-width: 2;

        transition: opacity 0.5s;
        opacity: 0;
    }

    &__card:hover &__btn-icon {
        opacity: 1;
    }
}

@include rmin($desktop) {
    .main-culture {
        max-height: $max-height;
        /* Use vh as a fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100);

        &__wrapper {
            display: flex;
            position: relative;

            margin-top: unset;
            padding-bottom: unset;
        }

        &__text-wrapper {
            width: 45%;
            margin-top: 15%;
            padding-left: 25px;

            h2::before {
                left: -15px;
                top: -65px;
                width: 75px;
                height: 130px;
            }
        }

        &__img-wrapper {
            position: unset;
            z-index: unset;
            bottom: unset;
            height: unset;
            margin-left: auto;
            width: 55%;
        }

        &__img {
            margin-top: 35px;
            height: 65%;

            &::before {
                content: none;
            }
        }

        &__card-list-wrapper {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;

            margin-left: unset;
            margin-top: unset
        }

        &__card-list-item {
            margin-bottom: 10px;

            &:not(:last-child) {
                margin-right: 10px;
            }

            &:first-child,
            &:last-child {
                display: unset;
            }

            &:last-child {
                &::after {
                    content: none;
                }
            }
        }

        &__card-list {
            flex-wrap: wrap;
            overflow-x: unset;
        }

        &__card {
            width: 185px;
            height: 90px;
        }

        &__icon {
            width: 20px;
            height: 20px;
        }

        &__svg-text {
            z-index: -1;
            width: 350px !important;
            bottom: 0;
            right: 0;
        }
    }
}

@include rmin($desktop-xs) {
    .main-culture {
        &__text-wrapper {
            margin-top: 5%;
        }

        &__img {
            height: 55%;
        }

        &__card {
            width: 230px;
            height: 100px;
        }
    }
}

@include rmin($desktop-s) {
    .main-culture {
        &__text-wrapper {
            margin-top: 10%;
            width: 35%;
        }

        &__img-wrapper {
            width: 65%;
            margin-left: unset;
        }

        &__img {
            margin-top: 50px;
            height: 65%;
        }

        &__card-list-wrapper {
            bottom: -15px;
        }
    }
}

@include rmin($desktop-sm) {
    .main-culture {
        &__text-wrapper {
            h2::before {
                width: 120px;
                height: 150px;
            }

            width: 30%;
        }

        &__img-wrapper {
            width: 70%;
        }

        &__card {
            width: 260px;
            height: 110px;
        }

        &__card-list-wrapper {
            bottom: -25px;
            width: 80%;
        }

        &__icon {
            width: 25px;
            height: 25px;
        }

        &__svg-text {
            bottom: -15px;
            right: 25px;
            width: 650px !important;
        }
    }
}

@include rmin($desktop-md) {
    .main-culture {}
}
