.hero {
    position: relative;
    max-height: $max-height;
    height: calc(var(--vh, 1vh) * 100);

    &__content-wrapper {
        width: 100%;
        height: 100%;
        max-height: 1080px;

        h2 {
            margin-bottom: 25px;
            font-size: 45px;
            line-height: 50px;

            color: var(--blue-title);
            text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
        }
    }

    &__img-wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        background-image: url(../img/hero-slide-1.jpg);
        background-position: center;
    }

    &__text-wrapper {
        position: absolute;
        top: 0;

        height: 100%;
    }

    &__pattern {
        display: none;
    }

    &__text-container {
        margin-top: 185px;
        height: calc(100% - 185px);
    }

    &__text {
        margin-top: 5px;
        margin-bottom: 25px;
        padding-right: 25%;

        color: var(--white);
        font-size: 18px;
        text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    }

    &__tour-links-wrap {
        position: absolute;
        bottom: 35px;
        left: 25px;
        right: 25px;
    }

    &__tour-wrapper {
        h3 {
            font-size: 18px;
        }

        display: block;
        padding: 15px;
        // padding: 20px 40px 20px 20px;
        // max-width: 220px;
        width: 100%;

        background: var(--white);
        border: 1px solid var(--brown-lighter);
        box-sizing: border-box;
        border-radius: 10px;
        text-decoration: none;
        // opacity: 0.95;
        // transition: opacity 0.5s;

        &:hover {
            opacity: 1;
        }
    }

    &__tour-text,
    &__library-text {
        color: var(--blue-title);
    }

    &__library-wrapper {
        margin-top: 5px;
        border: 1px solid var(--brown-lighter);
    }

    &__tour,
    &__library {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    &__tour-icon {
        width: 35px;
        fill: var(--brown-light);
    }

    &__tour-arrow {
        stroke: var(--blue-title);
        stroke-width: 2;
        fill: none;
        right: 10px;
        bottom: 10px;
        width: 25px;
        height: 15px;

        opacity: 0.5;
        transition: opacity 0.5s;
    }

    &__tour-wrapper:hover &__tour-arrow {
        opacity: 1;
    }

    &__tour-text,
    &__library-text {
        font-size: 18px;
    }

    &__tour-text,
    &__library-text {
        font-family: $font-text;
        font-style: normal;
        font-weight: bold;
    }

    &__tour-text,
    &__library-text {
        margin-top: -5px;
    }

    &__library-text {
        color: var(--blue-title);
    }

    &__tour-link {
        position: absolute;
        display: flex;
        align-items: center;

        margin-top: -10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #9E9E9E;

        p {
            margin-right: 5px;
        }
    }

    &__library-link {
        color: var(--blue-title);
        // margin-top: -15px;
    }

    &__library-icon {
        margin-top: -8px;
        margin-bottom: -8px;
        width: 50px;
        height: 50px;
    }
}


@include rmin($sm-phone-2) {
    .hero {
        &__content-wrapper {
            h2 {
                font-size: 55px;
                line-height: 65px;
            }
        }
    }
}

@include rmin($tablet) {
    .hero {
        &__text-wrapper {
            h2 {
                font-size: 100px;
            }
        }

        &__text {
            font-size: 30px;
        }

        &__text-container {
            margin-top: 350px;
            width: 650px;
        }

        &__tour-wrapper {
            padding: 40px 80px 40px 40px;
            max-width: 400px;

            h3 {
                font-size: 28px;
            }
        }

        &__library-wrapper {
            padding: 40px 80px 40px 40px;
            max-width: 400px;

            h3 {
                font-size: 28px;
            }
        }

        &__tour-text {
            font-size: 18px;
        }

        &__library-text {
            font-size: 18px;
        }

        &__text-container {
            position: relative;
            margin-top: unset;
            width: unset;
            height: 100%;
        }

        &__tour-links-wrap {
            position: absolute;
            bottom: 50px;
            left: unset;
            right: unset;
            display: flex;

            &>* {
                &:not(:first-child) {
                    margin-left: 20px;
                }
            }
        }
    }
}

@include rmin($desktop) {
    .hero {
        &__content-wrapper {
            display: flex;

            h2 {
                padding-top: 45%;
                margin-bottom: 15px;
                font-size: 65px;
                line-height: 75px;
                color: var(--blue-title);
                text-shadow: unset;
            }
        }

        &__img-wrapper {
            width: 50%;
        }

        &__text-wrapper {
            position: relative;
            max-width: 50%;
            padding-left: 30px;
        }

        &__pattern {
            display: block;
            position: absolute;
            z-index: -1;
            top: 20px;
            left: -120px;

            width: 325px;
            height: 325px;
        }

        &__text {
            margin-bottom: 25px;
            padding-right: unset;
            width: 300px;

            color: var(--gray);
            font-size: 24px;
            text-shadow: unset;
        }

        &__text-container {
            position: relative;
            margin-top: unset;
            width: unset;
            height: 100%;
        }

        &__tour-links-wrap {
            display: flex;

            &>* {
                &:not(:first-child) {
                    margin-left: 20px;
                }
            }
        }

        &__tour-wrapper {
            h3 {
                font-size: 30px;
            }

            padding: 15px;
            width: 45%;
        }

        &__tour-wrapper {}

        &__library-wrapper {
            margin-top: unset;
            background: unset;
            background-color: var(--white);
        }

        &__tour-icon {
            width: 50px;
            height: 50px;
            fill: var(--brown-lighter);
        }

        &__library-icon {
            margin-top: unset;
            margin-bottom: unset;
            margin-right: -10px;
            width: 55px;
            height: 55px;
        }

        &__tour-link,
        &__library-link {
            bottom: 10px;
        }

        &__library-link {
            color: #9e9e9e;
        }

        &__tour-text,
        &__library-text {
            font-size: 16px;
        }

        &__tour-arrow {
            width: 18px;
            height: 13px;
        }
    }
}

@include rmin($desktop-xs) {
    .hero {
        &__content-wrapper {
            display: flex;
        }

        &__tour-icon {
            width: 70px;
            height: 70px;
        }

        &__tour-text,
        &__library-text {
            margin-top: unset;
        }

        &__library-icon {
            width: 75px;
            height: 75px;
        }
    }
}

@include rmin($desktop-s) {
    .hero {
        &__content-wrapper {
            h2 {
                padding-top: 50%;
                margin-bottom: 35px;
                font-size: 75px;
                line-height: 85px;
            }
        }

        &__img-wrapper {
            width: 55%;
        }

        &__text-wrapper {
            max-width: 45%;
        }

        &__tour-links-wrap {
            position: absolute;
            bottom: 50px;
        }

        &__tour-text {
            margin-top: -5px;
        }

        &__tour-link {
            bottom: 15px;
        }

        &__tour-text,
        &__library-text {
            font-size: 18px;
        }
    }
}

@include rmin($desktop-sm) {
    .hero {
        &__content-wrapper {
            h2 {
                font-size: 100px;
            }
        }

        &__text {
            width: 350px;
            font-size: 30px;
        }

        &__pattern {
            width: 478px;
            height: 478px;
        }

        &__tour-text,
        &__library-text {
            font-size: 18px;
        }

        &__tour-wrapper {
            right: 0;
            bottom: 35px;
            padding: 25px;

            h3 {
                font-size: 24px;
            }

        }

        &__library-wrapper {
            h3 {
                font-size: 24px;
            }

            right: 0;
            bottom: 35px;
            // padding: 15px 28px 15px 22px;
        }
    }
}

@include rmin($desktop-md) {
    .hero {
        &__content-wrapper {
            h2 {
                font-size: 110px;
                line-height: 125px;
            }
        }

        &__text {
            width: 350px;
            font-size: 30px;
        }

        &__img-wrapper {}

        &__text-wrapper {
            padding-left: 50px;
        }

        &__tour-text,
        &__library-text {
            font-size: 22px;
        }

        &__tour-wrapper {
            bottom: 50px;
            right: 150px;
            transform: translate(-50%);
        }

        &__library-wrapper {
            bottom: 50px;
            right: -70px;
            transform: translate(-50%);
        }

        &__tour-text {
            font-size: 24px;
        }

        &__library-text {
            font-size: 24px;
        }

        &__tour-arrow {}
    }
}
