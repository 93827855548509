.history {
    padding-bottom: 15px;

    h2 {
        padding-left: 25px;
    }

    &__hero {
        overflow: hidden;
        background-image: var(--hero-img-history);
    }

    &__timeline {
        padding-top: 25px;
        padding-left: 10px;
        padding-right: 10px;

        h2 {
            padding-left: 5px;
            font-size: 26px;
        }
    }

    &__date-wrapper {
        position: relative;
        padding-top: 20px;
    }

    &__date {
        position: absolute;
        top: 60px;
        left: 25px;

        width: 250px;

        color: var(--brown-light);

        transform-origin: 0 0;
        transform: rotate(90deg);
    }

    &__info-wrapper {
        position: relative;

        margin-left: 10px;
        padding: 15px;
        background-color: var(--bone);
        border-radius: 5px;
    }

    &__text-wrapper {
        position: relative;
        z-index: 1;
    }

    &__text {
        margin-top: 10px;
        margin-bottom: 15px;
    }

    &__list {
        position: relative;
        margin-bottom: 15px;

        &:before {
            z-index: -1;

            content: '';
            position: absolute;
            top: 55px;
            left: 25px;

            height: calc(100% - 140px);

            border-left: 2px dashed var(--brown-lighter);
        }

        &:after {
            content: '';
            position: absolute;

            left: 10px;
            bottom: 0;

            height: 6%;
            width: 25px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 60%);
        }
    }

    &__item {
        display: flex;

        padding-top: 30px;

        &:first-child {
            padding-top: 15px;
        }
    }

    &__timeline-svg {
        width: 35px;
        height: 35px;
        stroke: #fff;
        fill: var(--brown-light);

        background-color: var(--white);
    }

    &__card-img-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        height: 241px;
        background-repeat: no-repeat;
        background-size: cover;

        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        &::before {
            content: '';
            position: absolute;
            bottom: 141px;
            left: 0;
            right: 0;

            height: 100px;
            background: linear-gradient(-180deg, #FFF4ED 0%, rgba(255, 247, 242, 0) 100%);
        }
    }

    &__svg-text {
        right: -10px;
        bottom: 10px;
    }

    &__periods-wrapper {
        display: none;
    }

    &__parts-content {
        margin-bottom: 25px;

        padding-left: 15px;
        padding-right: 15px;

        &:last-child {
            margin-bottom: 50px;
        }
    }

    &__author {
        font-style: italic;
        text-align: right;
    }
}

@include rmin($desktop) {
    .history {
        margin-top: 25px;
        padding-bottom: unset;

        &__wrapper {
            display: flex;
            justify-content: space-between;

            padding: 35px;
        }

        &__timeline-wrapper {
            width: 70%;

            h2 {
                padding-left: unset;
            }
        }

        &__svg-text {
            right: unset;
            left: 25px;
            bottom: -60px;

            width: 300px !important;
            height: 115px !important;
        }

        // timeline
        &__timeline {
            padding-top: 50px;
            padding-left: unset;

            h2 {
                &::before {
                    content: none;
                }
            }
        }

        &__date-wrapper {
            position: unset;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__date {
            position: unset;
            width: 90px;

            color: var(--brown-light);
            background-color: var(--white);
            font-size: 18px;
            text-align: center;

            transform-origin: unset;
            transform: unset;
        }

        &__timeline-svg {
            width: 45px;
            height: 45px;
        }

        &__info-wrapper {
            margin-left: 25px;
            padding: 25px;
        }

        &__text-wrapper {
            h3 {
                font-size: 22px;
                padding-right: 50px;
            }
        }

        &__list {
            &::before {
                left: 45px;
            }

            &:after {
                width: 75px;
            }
        }

        &__item {
            &:first-child {
                padding-top: 30px;
            }
        }

        &__card-img-wrapper {
            left: unset;
            width: 350px;
            height: 100%;

            border-bottom-left-radius: unset;
            border-top-right-radius: 5px;

            &::before {
                top: 0;
                bottom: 0;
                right: unset;

                width: 200px;
                height: unset;

                background: linear-gradient(90deg, #FFF4ED 0%, rgba(255, 247, 242, 0) 100%);
            }
        }

        // history periods
        &__periods-wrapper {
            display: block;
            width: 25%;
        }

        &__periods {
            position: sticky;
            top: 35px;

            padding: 25px 0 25px 25px;
            background-color: #FFF7F2;

            border-radius: 5px;

            h3 {
                font-size: 30px;
                text-align: center;
            }
        }

        &__periods-list {
            margin-top: 15px;
        }

        &__periods-item {
            position: relative;
            text-align: right;

            transition: opacity .35s;

            &::after {
                content: '';

                position: absolute;
                top: 5px;
                right: 2px;

                width: 0;
                height: 0;

                border-top: 13px solid transparent;
                border-bottom: 13px solid transparent;
                border-right: 5px solid var(--brown-light);

                opacity: 0;
            }

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 5px;

                width: 2px;
                height: 26px;

                background-color: var(--brown-light);

                opacity: 0;
            }
        }

        &__periods-link {
            display: flex;
            justify-content: flex-end;

            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 25px;

            font-size: 18px;
            font-weight: 500;
            color: var(--brown-lighter);

            text-decoration: none;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            transition: background-color .35s;

            &:hover {
                color: var(--brown-light);
                background-color: rgba(208, 153, 130, 0.1);
            }
        }

        &__periods-item:hover {

            &::after,
            &::before {
                opacity: 1;
            }
        }

        &__parts-content {
            padding-left: 50px;
        }
    }
}

@include rmin($desktop-sm) {
    .history {
        &__wrapper {
            padding: 50px;
        }

        &__info-wrapper {
            padding: 35px;
        }

        &__timeline {
            h2 {
                font-size: 32px;
            }
        }

        &__timeline-svg {
            width: 55px;
            height: 55px;
        }

        &__timeline-wrapper {
            width: 65%;
        }

        &__date {
            width: 115px;
            font-size: 22px;
        }

        &__list {
            &::before {
                top: 75px;
                left: 56px;
                height: calc(100% - 80px);
            }
        }

        &__item {
            padding-top: 50px;

            &:first-child {
                padding-top: 50px;
            }
        }

        &__card-img-wrapper {
            width: 500px;
        }

        &__text-wrapper {
            h3 {
                font-size: 28px;
            }
        }

        // periods
        &__periods {
            top: 50px;
            padding: 35px 0 35px 35px;

            h3 {
                font-size: 40px;
            }
        }

        &__periods-item {
            &::after {
                top: 7px;
                border-top: 19px solid transparent;
                border-bottom: 19px solid transparent;
                border-right: 5px solid var(--brown-light);
            }

            &::before {
                top: 7px;
                height: 38px;
            }
        }

        &__periods-link {
            padding-top: 10px;
            padding-bottom: 10px;

            font-size: 24px;
        }
    }
}
