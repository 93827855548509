.biography {
    &__header {
        z-index: -1;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;

        height: 200px;
        background-color: var(--bone);
        overflow: hidden;

        h2 {
            font-size: 25px;
            font-family: $font-serif;
            color: var(--blue-title);
            text-align: center;
        }

        &::before {
            content: '';
            z-index: -1;
            position: absolute;
            top: 50%;
            left: 50%;

            width: 345px;
            height: 345px;
            background-image: url(../img/bio-pattern.png);

            transform: translate(-50%, -50%);
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            height: 150px;
            background: linear-gradient(0deg, rgba(188, 121, 102, 0) 0%, rgba(188, 121, 102, 0.5) 100%);
        }
    }

    &__visual {
        order: -1;

        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    &__photo {
        width: 100%;
        height: 400px;

        background-color: var(--white);
        border: 10px solid var(--white);
        box-shadow: 0px 10px 50px rgba(188, 121, 102, 0.2);

        img {
            height: 100%;
            object-fit: cover;
        }
    }

    &__works {
        display: block;
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 15px;
        background-color: var(--bone);

        text-decoration: none;
        font-size: 14px;
        font-weight: 600;
        color: var(--gray);
    }

    &__works:hover &__works-btn {
        opacity: 1;
    }

    &__works-list {
        display: flex;

        height: 60px;
        margin-top: 10px;
    }

    &__works-title {
        display: flex;
        justify-content: space-between;
    }

    &__works-item {
        width: calc((100% - 10px) / 3);
        height: 100%;

        border: 5px solid var(--white);

        &:not(:last-child) {
            margin-right: 5px;
        }

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &__works-btn {
        display: inline-flex;
        align-items: center;
        padding-right: 25px;

        color: var(--brown-light);

        &::before {
            top: 50%;
            background-image: url(../img/arrow-small-brown.svg);
            transform: translateY(-50%);
        }
    }

    &__info-wrapper {
        display: flex;
        flex-direction: column;
    }

    &__info {
        margin-left: 15px;
        margin-right: 15px;
    }

    &__info h3,
    &__part-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--gray);
    }

    &__part-list {
        margin-top: 15px;
        margin-bottom: 35px;
        padding-left: 25px;
        list-style-type: decimal;

        font-size: 16px;
        font-family: $font-text;
        color: var(--brown-light);
    }

    &__part-list-item {
        &:not(:first-child) {
            margin-top: 5px;
        }
    }

    &__part-list-link {
        text-decoration: none;
        color: var(--gray);

        &:hover {
            color: var(--brown-light);
            border-bottom: 1px solid currentColor;
        }
    }

    &__part-title {
        margin-top: 15px;
        color: var(--brown-light);
        border-bottom: 1px dashed currentColor;
    }

    section &__text {
        font-size: 16px;
        margin-top: 15px;
    }

    &__artist-works {
        margin-top: 25px;
        padding: 15px;
        background-color: var(--bone);

        h2 {
            font-size: 25px;
            margin-bottom: 10px;

            &:not(:first-child) {
                margin-top: 75px;
            }
        }
    }

    &__soc-link {
        text-decoration: none;
        color: var(--brown-light);

        &:hover {
            color: var(--brown-lighter);
        }
    }
}

@include rmin($sm-phone) {
    .biography {
        &__header {
            height: 150px;
        }
        &__photo {
            width: 100%;
            height: 500px;
        }
    }
}

@include rmin($desktop) {
    .biography {
        &__header {
            justify-content: unset;
            padding-left: 40px;
            padding-bottom: 25px;
            padding-right: unset;

            height: 250px;

            h2 {
                font-size: 50px;
                text-align: unset;
            }

            &::after {
                content: none;
            }
        }

        &__info-wrapper {
            display: flex;
            flex-direction: unset;
        }

        &__info {
            margin-top: 25px;
            margin-left: 40px;
            margin-right: unset;
            max-width: 700px;
        }

        &__info h3,
        &__part-title {
            font-size: 22px;
        }

        &__part-list {
            font-size: 18px;
        }

        &__part-title {
            margin-top: 25px;
        }

        &__visual {
            order: unset;
            margin-top: -125px;
            margin-left: 35px;
            margin-right: unset;
            max-width: 350px;
            width: 350px;
        }

        &__photo {
            height: 500px;
        }

        &__photo-wrapper {
            position: sticky;
            top: 25px;
        }

        &__works {
            margin-bottom: unset;
            font-size: 18px;
        }

        &__works-list {
            height: 75px;
        }

        section &__text {
            font-size: 18px;
            margin-top: 15px;
        }

        &__artist-works {
            margin-top: 50px;
            padding: 40px;

            h2 {
                font-size: 35px;
                margin-bottom: 25px;
            }
        }
    }
}


@include rmin($desktop-sm) {
    .biography {
        &__info {
            max-width: 900px;
        }

        &__visual {
            max-width: 450px;
            width: 450px;
        }

        &__photo {
            height: 600px;
        }

        &__works-list {
            height: 100px;
        }

        &__info h3,
        &__part-title {
            font-size: 26px;
        }

        &__part-list,
        section &__text {
            font-size: 20px;
        }
    }
}
