// MAIN PAGE

.hero {

    &__img-wrapper,
    &__text-container h2,
    &__text-container &__text,
    &__text-container a,
    &__tour-wrapper {
        opacity: 0;
    }

    &__img-wrapper {
        transform: translate3d(0px, 100px, 0px);
    }

    &__text-container h2,
    &__text-container &__text,
    &__text-container a {
        transform: translate3d(-25px, 0px, 0px);
    }

    &__tour-wrapper {
        transform: translate3d(-50px, 0px, 0px);
    }
}

@include rmin($desktop) {

    // hero animation
    .hero {

        &__img-wrapper,
        &__pattern,
        &__tour-wrapper {
            opacity: 0;
        }

        &__img-wrapper {
            // x y z
            transform: translate3d(0px, 100px, 0px);
        }

        &__pattern {
            transform: translate3d(-200px, 0px, 0px);
        }

        &__text-container {

            h2,
            .hero__text,
            a {
                opacity: 0;
                transform: translate3d(0px, 200px, 0px);
            }
        }

        &__tour-wrapper {
            transform: translate3d(0px, 0px, 0px);
        }
    }

    // main history animation
    .main-history {
        &__text-wrapper {

            h2,
            p,
            a {
                opacity: 0;
                transform: translate3d(0px, 200px, 0px);
            }
        }

        &__img-wrapper {
            opacity: 0;
            transform: translate3d(100px, -50px, 0px);
        }

        &__slider-wrapper {

            .glide__arrows,
            .glide__slide {
                opacity: 0;
                transform: translate3d(0px, 50px, 0px);
            }
        }

        &__svg-text {
            opacity: 0;
        }
    }

    // main culture animation
    .main-culture {
        &__text-wrapper {

            h2,
            p,
            a {
                opacity: 0;
                transform: translate3d(0px, 200px, 0px);
            }
        }

        &__img-wrapper,
        &__svg-text {
            opacity: 0;
        }

        &__card-list-wrapper .card__slider-list li {
            opacity: 0;
            transform: translate3d(0px, 50px, 0px);
        }

        &__img-wrapper {
            transform: translate3d(0px, 75px, 0px);
        }
    }

    // main collections animation
    .main-collections {
        &__img-wrapper {
            opacity: 0;
            transform: translate3d(0px, 200px, 0px);
        }

        &__info-text {
            opacity: 0;

            h2,
            p,
            a {
                opacity: 0;
                transform: translate3d(50px, 0px, 0px);
            }
        }

        &__img-item--first &__card-wrapper,
        &__link--first,
        &__img-item--second &__card-wrapper,
        &__link--second {
            opacity: 0;
            transform: translate3d(0px, 50px, 0px);
        }
    }

    // main artists animation
    .main-artists {

        &__wrapper &__img-wrapper,
        &__wrapper &__card-list-item {
            opacity: 0;
        }

        &__wrapper &__info-text {

            p,
            a {
                opacity: 0;
                transform: translate3d(0px, 50px, 0px);
            }

            h2 {
                opacity: 0;
                transform: translate3d(0px, 100px, 0px);
            }
        }

        &__wrapper &__card-list-item {
            transform: translate3d(50px, 0px, 0px);
        }
    }

    // main art animation
    .main-art {
        &__info-wrapper {

            h2,
            p,
            a {
                opacity: 0;

            }

            h2 {
                transform: translate3d(0px, 100px, 0px);
            }

            p,
            a {
                transform: translate3d(0px, 50px, 0px);
            }
        }

        &__img,
        &__svg-text {
            opacity: 0;
        }

        &__img {
            transform: translate3d(-50px, 0px, 0px);
        }

        &__svg-text {
            transform: translate3d(0px, 50px, 0px);
        }
    }

    // main library animations
    .main-library {

        &__wrapper &__img-wrapper,
        &__wrapper &__cards-wrapper,
        &__wrapper &__card-list-item {
            opacity: 0;
        }

        &__wrapper &__img-wrapper {
            transform: translate3d(-50px, 0px, 0px);
        }

        &__wrapper &__info-wrapper {

            h2,
            p,
            a {
                opacity: 0;

            }

            h2 {
                transform: translate3d(100px, 0px, 0px);
            }

            p,
            a {
                transform: translate3d(50px, 0px, 0px);
            }
        }

        &__wrapper &__card-list-item {
            transform: translate3d(0px, 50px, 0px);
        }
    }
}

.img__hero-inner {
    opacity: 0;
    animation: slide-in-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.svg-text-inner {
    opacity: 0;
    animation: slide-in-bottom 1.75s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

// .culture__cards-list {
//     opacity: 0;
//     animation: slide-in-bottom 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
//     animation-delay: 1.25s;
// }

.anim-section {
    opacity: 0;
    animation: slide-in-bottom 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-delay: 1.5s;
}

.anim-section-2 {
    opacity: 0;
    animation: slide-in-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-delay: 1.5s;
}

.anim-section-3 {
    opacity: 0;
    animation: slide-in-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-delay: 1.25s;
}

.anim-section-4 {
    opacity: 0;
    animation: slide-in-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-delay: 2s;
}

@keyframes slide-in-top {
    0% {
        transform: translateY(-50px);
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-bottom {
    0% {
        transform: translateY(50px);
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}